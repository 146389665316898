import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-upload-item-step-divider',
    templateUrl: './upload-item-step-divider.component.html',
    styleUrls: ['./upload-item-step-divider.component.scss']
})
export class UploadItemStepDividerComponent implements OnInit
{
    @Input() public done = false;

    constructor() { }
    public ngOnInit() { }
}
