import { Component, Input, OnInit } from '@angular/core';
import { PersonModel } from '@ortsarchiv-gemeinlebarn/data';
import { QuickViewService } from '../../services/quick-view/quick-view.service';

@Component({
    selector: 'oag-quick-view-person',
    templateUrl: './quick-view-person.component.html',
    styleUrls: ['./quick-view-person.component.scss']
})
export class QuickViewPersonComponent implements OnInit
{
    @Input() public person: PersonModel = null;

    constructor(public quickView: QuickViewService) { }
    public ngOnInit() { } // NOSONAR
}
