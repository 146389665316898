import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ItemDigitalViewerComponent } from './components/item-digital-viewer/item-digital-viewer.component';
import { SeadragonComponent } from './components/seadragon/seadragon.component';

@NgModule({
    declarations: [
        ItemDigitalViewerComponent,
        SeadragonComponent
    ],
    exports: [
        ItemDigitalViewerComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule
    ]
})
export class MediaViewModule { }
