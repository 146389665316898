import { Component, OnInit } from '@angular/core';
import { SchaufensterApiService } from 'src/app/shared/services/api-endpoints/schaufenster-api/schaufenster-api.service';
import { SchaufensterService } from '../../services/schaufenster/schaufenster.service';

@Component({
    selector: 'oag-schaufenster-objekte-page',
    templateUrl: './schaufenster-objekte-page.component.html',
    styleUrls: ['./schaufenster-objekte-page.component.scss']
})
export class SchaufensterObjektePageComponent implements OnInit
{
    public search: string = "";

    constructor(public schaufenster: SchaufensterService) { }
    public ngOnInit() { }

    public searchForNew()
    {
        if (this.search == "")
            return;

        this.schaufenster.searchAndLoadEintrag(this.search);
        this.search = "";
    }
}
