import { Injectable } from '@angular/core';
import { EintragApiService } from 'src/app/shared/services/api-endpoints/eintrag-api/eintrag-api.service';
import { SchaufensterApiService } from 'src/app/shared/services/api-endpoints/schaufenster-api/schaufenster-api.service';
import { FrontendService } from 'src/app/shared/services/frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class SchaufensterService
{
    public objekte = null;

    public newVisible = false;
    public new: any = {
        eintrag: null,
        availableItems: [],
        availablePages: [],
        beginn: null,
        ende: null,
        anmerkung: null
    };

    constructor(public schaufensterAPI: SchaufensterApiService, public eintragAPI: EintragApiService, public frontend: FrontendService)
    {
        this.load();
    }

    public searchAndLoadEintrag(search)
    {
        this.eintragAPI
            .jumpSearch(search)
            .subscribe((result) =>
            {
                this.new.eintrag = result.eintrag;
                this.new.availableItems = result.eintrag.items.digital;
                this.new.availableItems.forEach((item, index) =>
                {
                    this.new.availablePages[index] = Array.apply(null, { length: item.seitenAnzahl }).map((v) => v = false);
                });
            });
    }

    public togglePage(itemIndex, pageIndex)
    {
        this.new.availablePages[itemIndex][pageIndex] = !this.new.availablePages[itemIndex][pageIndex];
    }

    private load()
    {
        this.schaufensterAPI.readAll().subscribe((objekte) => this.objekte = objekte);
    }

    public create()
    {
        let payload = {
            eintrag: this.new.eintrag,
            beginn: this.new.beginn,
            ende: this.new.ende,
            anmerkung: this.new.anmerkung,
            bestandteile: []
        };

        this.new.availablePages.forEach((pages, itemIndex) =>
        {
            pages.forEach((bool, index) => 
            {
                if (bool)
                {
                    payload.bestandteile.push({
                        "items_digital_id": this.new.availableItems[itemIndex].id,
                        "seite": index + 1
                    });
                }
            });
        });

        if (payload.bestandteile.length == 0)
        {
            this.frontend.showModal("Fehler", "Es wurde keine Seite eines digitalen Items ausgewählt. Ein Schaufenster-Objekt muss mindestens einen Bestandteil besitzen!");
            return;
        }

        this.schaufensterAPI.create(payload).subscribe((data) =>
        {
            this.load();
            this.newVisible = false;
        });
    }

    public delete(id: number)
    {
        this.schaufensterAPI.delete(id).subscribe(() => this.load());
    }
}
