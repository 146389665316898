<h4>{{ item?.idFormattedPrefix }}</h4>
<oag-info-item-row>
    <oag-info-item-wrapper width="3" titel="Originalität">{{ item?.originalitaet.titel }}</oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Farbraum">{{ item?.farbraum.titel }}</oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Größe">{{ item?.groesse }}</oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Beschreibung">{{ item?.beschreibung }}</oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row *ngIf="!item?.aufstellungsFach">
    <oag-info-item-wrapper width="2" titel="In einem Behältnis">Ja</oag-info-item-wrapper>
    <oag-info-item-wrapper width="5" titel="Kasten und Fach">{{ item?.behaeltnis.fach.kasten.name }} (Fach: {{ item?.behaeltnis.fach.spalte }} / {{ item?.behaeltnis.fach.reihe }})</oag-info-item-wrapper>
    <oag-info-item-wrapper width="5" titel="Behältnis">{{ item?.behaeltnis.bestand.signatur }}/BEH{{ item?.behaeltnis.idFormatted }} ({{ item?.behaeltnis.name}})</oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row *ngIf="item?.aufstellungsFach">
    <oag-info-item-wrapper width="2" titel="In einem Behältnis">Nein</oag-info-item-wrapper>
    <oag-info-item-wrapper width="10" titel="Kasten und Fach">
        {{ item?.aufstellungsFach.kasten.name }}&nbsp;
        <span [hidden]="item?.aufstellungsFach.spalte">({{ item?.aufstellungsFach.name }})</span>
        <span [hidden]="!item?.aufstellungsFach.spalte">(Fach: {{ item?.aufstellungsFach.spalte }} / {{ item?.aufstellungsFach.reihe }})</span>
    </oag-info-item-wrapper>
</oag-info-item-row>
