import { Injectable } from '@angular/core';
import { FindbuchApiService } from '../api-endpoints/findbuch-api/findbuch-api.service';

@Injectable({
    providedIn: 'root'
})
export class ApplikationService
{
    public selects = null;

    constructor(private findbuchAPI: FindbuchApiService)
    {
        this.findbuchAPI.selects().subscribe((selects) => this.selects = selects);
    }

    public compareIds(a: any, b: any)
    {
        return a.id === b.id;
    }

    public compareBestandIds(a: any, b: any)
    {
        return a.id === b.id && a.bestand.id === b.bestand.id;
    }
}
