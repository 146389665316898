import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ThemaBereichModel } from '@ortsarchiv-gemeinlebarn/data';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import { FrontendService } from '../../frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class ThemaBereichApiService
{
    constructor(public http: HttpClient, public api: ApiService, public frontend: FrontendService) { }

    public create(themaBereich: ThemaBereichModel)
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .post(`${this.api.url}/themen/bereiche`, themaBereich, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public readAll(): Observable<Array<ThemaBereichModel>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/themen/bereiche`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public update(themaBereich: ThemaBereichModel)
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .put(`${this.api.url}/themen/bereiche/${themaBereich.id}`, themaBereich, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public delete(id: number)
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .delete(`${this.api.url}/themen/bereiche/${id}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
