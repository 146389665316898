import { Component, Input, OnInit } from '@angular/core';
import { EintragListModel, EintragModel } from '@ortsarchiv-gemeinlebarn/data';
import { QuickViewService } from '../../services/quick-view/quick-view.service';

@Component({
    selector: 'oag-quick-view-eintrag',
    templateUrl: './quick-view-eintrag.component.html',
    styleUrls: ['./quick-view-eintrag.component.scss']
})
export class QuickViewEintragComponent implements OnInit
{
    @Input() public eintrag: EintragListModel | EintragModel = new EintragListModel();

    constructor(public quickView: QuickViewService) { }
    public ngOnInit() { } // NOSONAR
}
