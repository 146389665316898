import { Component, OnInit } from '@angular/core';
import { ThemaHauptkategorieModel } from '@ortsarchiv-gemeinlebarn/data';
import { ThemaUnterkategorieModel } from '@ortsarchiv-gemeinlebarn/data';
import { ThemaBereichModel } from '@ortsarchiv-gemeinlebarn/data';
import { BestandApiService } from 'src/app/shared/services/api-endpoints/bestand-api/bestand-api.service';
import { ThemaBereichApiService } from 'src/app/shared/services/api-endpoints/thema-bereich-api/thema-bereich-api.service';
import { ThemaHauptkategorieApiService } from 'src/app/shared/services/api-endpoints/thema-hauptkategorie-api/thema-hauptkategorie-api.service';
import { ThemaUnterkategorieApiService } from 'src/app/shared/services/api-endpoints/thema-unterkategorie-api/thema-unterkategorie-api.service';

@Component({
    selector: 'oag-themen-page',
    templateUrl: './themen-page.component.html',
    styleUrls: ['./themen-page.component.scss']
})
export class ThemenPageComponent implements OnInit
{
    public themaBereiche: Array<ThemaBereichModel> = [];
    public themaBereichEdit: ThemaBereichModel = null;
    public themaHauptkategorien: Array<ThemaHauptkategorieModel> = [];
    public themaHauptkategorieEdit: ThemaHauptkategorieModel = null;
    public themaUnterkategorien: Array<ThemaUnterkategorieModel> = [];
    public themaUnterkategorieEdit: ThemaUnterkategorieModel = null;

    constructor(public bestandAPI: BestandApiService, public themaBereicheAPI: ThemaBereichApiService, public themaHauptkategorieAPI: ThemaHauptkategorieApiService, public themaUnterkategorieAPI: ThemaUnterkategorieApiService)
    {
        this.loadThemaBereiche();
        this.loadThemaHauptkategorien();
        this.loadThemaUnterkategorien();
    }

    public ngOnInit() { }

    public resetEdit()
    {
        this.themaBereichEdit = null;
        this.themaHauptkategorieEdit = null;
        this.themaUnterkategorieEdit = null;
    }

    private loadThemaBereiche()
    {
        this.resetEdit();
        this.themaBereicheAPI.readAll().subscribe((bereiche) => this.themaBereiche = bereiche);
    }

    private loadThemaHauptkategorien()
    {
        this.resetEdit();
        this.themaHauptkategorieAPI.readAll().subscribe((hauptkategorien) => this.themaHauptkategorien = hauptkategorien);
    }

    private loadThemaUnterkategorien()
    {
        this.resetEdit();
        this.themaUnterkategorieAPI.readAll().subscribe((unterkategorien) => this.themaUnterkategorien = unterkategorien);
    }

    public editBereich(bereich: ThemaBereichModel = null)
    {
        if (bereich == null) bereich = new ThemaBereichModel();
        this.resetEdit();
        this.themaBereichEdit = bereich;
    }

    public editHauptkategorie(hauptkategorie: ThemaHauptkategorieModel = null)
    {
        if (hauptkategorie === null)
        {
            hauptkategorie = new ThemaHauptkategorieModel();
            hauptkategorie.bereich = this.themaBereiche[0];
        }

        this.resetEdit();
        this.themaHauptkategorieEdit = hauptkategorie;
    }

    public editUnterkategorie(unterkategorie: ThemaUnterkategorieModel = null)
    {
        if (unterkategorie === null)
        {
            unterkategorie = new ThemaUnterkategorieModel();
            unterkategorie.hauptkategorie = this.themaHauptkategorien[0];
        }

        this.resetEdit();
        this.themaUnterkategorieEdit = unterkategorie;
    }

    public updateEditThemaBereich = () => this.themaBereicheAPI.update(this.themaBereichEdit).subscribe(() => this.loadThemaBereiche());
    public createEditThemaBereich = () => this.themaBereicheAPI.create(this.themaBereichEdit).subscribe(() => this.loadThemaBereiche());
    public deleteEditThemaBereich = () => this.themaBereicheAPI.delete(this.themaBereichEdit.id).subscribe(() => this.loadThemaBereiche());

    public updateEditThemaHauptkategorie = () => this.themaHauptkategorieAPI.update(this.themaHauptkategorieEdit).subscribe(() => this.loadThemaHauptkategorien());
    public createEditThemaHauptkategorie = () => this.themaHauptkategorieAPI.create(this.themaHauptkategorieEdit).subscribe(() => this.loadThemaHauptkategorien());
    public deleteEditThemaHauptkategorie = () => this.themaHauptkategorieAPI.delete(this.themaHauptkategorieEdit.id).subscribe(() => this.loadThemaHauptkategorien());

    public updateEditThemaUnterkategorie = () => this.themaUnterkategorieAPI.update(this.themaUnterkategorieEdit).subscribe(() => this.loadThemaUnterkategorien());
    public createEditThemaUnterkategorie = () => this.themaUnterkategorieAPI.create(this.themaUnterkategorieEdit).subscribe(() => this.loadThemaUnterkategorien());
    public deleteEditThemaUnterkategorie = () => this.themaUnterkategorieAPI.delete(this.themaUnterkategorieEdit.id).subscribe(() => this.loadThemaUnterkategorien());

    public compareIds = (a, b) => (!a || !b) ? false : a.id === b.id;
}
