import { Component, OnInit } from '@angular/core';
import { PersonModel, SelectsModel } from '@ortsarchiv-gemeinlebarn/data';
import { EintragApiService } from 'src/app/shared/services/api-endpoints/eintrag-api/eintrag-api.service';
import { EreignisApiService } from 'src/app/shared/services/api-endpoints/ereignis-api/ereignis-api.service';
import { FindbuchApiService } from 'src/app/shared/services/api-endpoints/findbuch-api/findbuch-api.service';
import { PersonApiService } from 'src/app/shared/services/api-endpoints/person-api/person-api.service';
import { ApplikationService } from 'src/app/shared/services/applikation/applikation.service';
import { QuickViewService } from 'src/app/shared/services/quick-view/quick-view.service';
import { TimelineService } from 'src/app/zeittafel/services/timeline/timeline.service';

@Component({
    selector: 'oag-ereignis-edit',
    templateUrl: './ereignis-edit.component.html',
    styleUrls: ['./ereignis-edit.component.scss']
})
export class EreignisEditComponent implements OnInit
{
    public selects: SelectsModel = null;
    public personen: Array<PersonModel> = [];
    public eintraege: Array<any> = [];

    constructor(
        public quickView: QuickViewService,
        public ereignisAPI: EreignisApiService,
        public timeline: TimelineService,
        public findbuchAPI: FindbuchApiService,
        public personAPI: PersonApiService,
        public eintragAPI: EintragApiService,
        public applikation: ApplikationService
    )
    {
        this.eintragAPI.readAllMinimal().subscribe((eintraege) => this.eintraege = eintraege);
        this.findbuchAPI.selects().subscribe((selects) => this.selects = selects);
        this.personAPI.readAll().subscribe((personen) => this.personen = personen);
    }
    public ngOnInit() { } // NOSONAR 

    public themaBereichChanged()
    {
        this.timeline.ereignis.themaHauptkategorie = this.selects.themaHauptkategorien.find((kategorie) => kategorie.bereich.id === this.timeline.ereignis.themaBereich.id);
        this.themaHauptkategorieChanged();
    }

    public themaHauptkategorieChanged()
    {
        this.timeline.ereignis.themaUnterkategorie = this.selects.themaUnterkategorien.find((kategorie) => kategorie.hauptkategorie.id === this.timeline.ereignis.themaHauptkategorie.id);
    }

    public compareIds = (a, b) => (!a || !b) ? false : a.id === b.id;
    public compareIdsAndBestandIds = (a, b) => (!a || !b) ? false : a.id === b.id && a.bestand.id === b.bestand.id;

    public save()
    {
        if (this.timeline.ereignis.id)
        {
            this.ereignisAPI.update(this.timeline.ereignis).subscribe((ereignis) =>
            {
                this.timeline.loadTimeline();
                this.timeline.clearEreignisEditing();
            });
        }
        else
        {
            this.ereignisAPI.create(this.timeline.ereignis).subscribe((ereignis) =>
            {
                this.timeline.loadTimeline();
                this.timeline.clearEreignisEditing();
            });
        }
    }
}
