<oag-content-wrapper *ngIf="auth.userProfile$ | async as benutzerprofil">

    <h1>Benutzerprofil</h1>

    <oag-info-item-row>
        <oag-info-item-wrapper width="12" titel="Name">{{ benutzerprofil.name }}</oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper width="8" titel="E-Mail Adresse">{{ benutzerprofil.email }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Verifiziert">{{ benutzerprofil.email_verified | booleanAsText }}</oag-info-item-wrapper>
    </oag-info-item-row>

    <h2>Passwort ändern (zurücksetzen)</h2>
    <button class="button" (click)="passwordResetLink(benutzerprofil.email)">Passwort ändern</button>

    <h2>Detailinformationen</h2>
    <code>{{ benutzerprofil | json }}</code>

</oag-content-wrapper>
