import { Component, OnDestroy, OnInit } from '@angular/core';
import { UploadModel } from '@ortsarchiv-gemeinlebarn/data';
import { EintragApiService } from 'src/app/shared/services/api-endpoints/eintrag-api/eintrag-api.service';
import { UploadApiService } from 'src/app/shared/services/api-endpoints/upload-api/upload-api.service';
import { FrontendService } from 'src/app/shared/services/frontend/frontend.service';

@Component({
    selector: 'oag-uploads-page',
    templateUrl: './uploads-page.component.html',
    styleUrls: ['./uploads-page.component.scss']
})
export class UploadsPageComponent implements OnInit, OnDestroy
{
    public files: Array<string> = [];
    public activeUploads: Array<UploadModel> = [];
    public finishedUploads: Array<UploadModel> = [];
    private interval = null;
    public newUpload = null;
    public search = '';
    public foundEintrag = null;

    constructor(public uploadAPI: UploadApiService, public eintragAPI: EintragApiService, public frontend: FrontendService) { }

    public ngOnInit()
    {
        // this.resetNewUpload();
        // this.loadFinishedUploads();
        // this.loadAvailableFiles();
        // this.loadCurrentUploads(true);
        // this.interval = setInterval(() => this.loadCurrentUploads(false), 1000);
    }

    public ngOnDestroy()
    {
        clearInterval(this.interval);
    }

    public loadCurrentUploads(showLoadingIndicator: boolean)
    {
        this.uploadAPI.readActiveUploads(showLoadingIndicator).subscribe((uploads) =>
        {
            if (this.activeUploads.length !== uploads.length)
            {
                this.loadFinishedUploads();
            }

            this.activeUploads = uploads;
        });
    }

    public loadFinishedUploads()
    {
        this.uploadAPI.readFinishedUploads().subscribe((uploads) => this.finishedUploads = uploads);
    }

    public loadAvailableFiles()
    {
        this.uploadAPI.listFilesInUploadDirectoryWithoutUnfinishedUpload().subscribe((files) => this.files = files);
    }

    public searchForItemsDigital()
    {
        this.eintragAPI
            .jumpSearch(this.search)
            .subscribe((result) =>
            {
                this.foundEintrag = result.eintrag;

                if (this.foundEintrag.items.digital.length === 0)
                    this.newUpload.itemDigitalId = null;

                this.newUpload.itemDigitalId = this.foundEintrag.items.digital[0].idFormatted;
            });
    }

    public createAndStartProcessing()
    {
        if (this.newUpload.itemDigitalId === null)
        {
            this.frontend.showModal('Upload hinzufügen', 'Es wurde kein digitales Item (ID) ausgewählt!');
            return;
        }

        if (this.newUpload.path === null || this.newUpload.path === '')
        {
            this.frontend.showModal('Upload hinzufügen', 'Es wurde kein File ausgewählt!');
            return;
        }

        if (this.isItemDigitalIdInActiveUploads(this.newUpload.itemDigitalId))
        {
            this.frontend.showModal('Upload hinzufügen', 'Für das gewählte digitale Item existiert bereits ein aktiver Upload!');
            return;
        }

        this.uploadAPI.createAndStartProcessing(
            this.newUpload.path,
            this.newUpload.itemDigitalId,
            this.newUpload.pdfResolution
        ).subscribe(() =>
        {
            this.loadAvailableFiles();
            this.resetNewUpload();
        });
    }

    private resetNewUpload()
    {
        this.newUpload = {
            path: '',
            itemDigitalId: null,
            pdfResolution: 600
        };
    }

    private isItemDigitalIdInActiveUploads(id)
    {
        const tmpUploads = this.activeUploads;
        let flag = false;

        tmpUploads.forEach((upload: UploadModel) =>
        {
            if (upload.itemDigital.idFormatted === id)
            {
                flag = true;
            }
        });

        return flag;
    }
}
