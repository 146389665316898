import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'booleanAsText'
})
export class BooleanAsTextPipe implements PipeTransform
{
    public transform(value: boolean): string
    {
        return value ? 'Ja' : 'Nein';
    }
}
