import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SchaufensterService } from '../../services/schaufenster/schaufenster.service';

@Component({
    selector: 'oag-schaufenster-objekt',
    templateUrl: './schaufenster-objekt.component.html',
    styleUrls: ['./schaufenster-objekt.component.scss']
})
export class SchaufensterObjektComponent implements OnInit
{
    public storageLinkBase = environment.storage.thumbnail;
    @Input() public objekt = null;

    public rePublishVisible = false;
    public rePublishObjekt = null;

    public prepareRePublish()
    {
        this.rePublishVisible = true;
        this.rePublishObjekt = this.objekt;
    }

    constructor(public schaufenster: SchaufensterService) { }
    ngOnInit(): void { }
}
