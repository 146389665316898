<div class="container" *ngIf="manuelleArbeitslisten.length">
    <header>
        <div class="left">
            <div class="arbeitslisten-chooser">
                <select [(ngModel)]="arbeitsliste" (change)="chooseArbeitsliste(arbeitsliste)">
                    <option *ngFor="let arbeitsliste of manuelleArbeitslisten" [ngValue]="arbeitsliste">{{ arbeitsliste.titel }}</option>
                </select>
            </div>
            <div class="count">{{ getItemsFinished().length }} / {{ arbeitslisteItems.length }}</div>
            <div class="progress">
                <div class="progress__finished" [ngStyle]="{width: ((getItemsFinished().length / arbeitslisteItems.length) * 100.00) + '%'}"></div>
            </div>
        </div>
        <div class="right">
            <button class="button" (click)="erledigtUndNaechstesItem()">Erledigt & nächstes Item</button>
        </div>
    </header>
    <main *ngIf="arbeitslisteItems.length">
        <aside class="arbeitsliste-items">
            <div class="arbeitsliste-item" [ngClass]="{'arbeitsliste-item--active': listItem == arbeitslisteItem}" *ngFor="let listItem of arbeitslisteItems" (click)="chooseItem(listItem)">
                <div class="arbeitsliste-item__label">{{ listItem?.eintrag?.idFormattedPrefix }}</div>
                <div class="arbeitsliste-item__icon" [ngClass]="{'arbeitsliste-item__icon--finished': listItem?.finished}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM364.25,186.29l-134.4,160a16,16,0,0,1-12,5.71h-.27a16,16,0,0,1-11.89-5.3l-57.6-64a16,16,0,1,1,23.78-21.4l45.29,50.32L339.75,165.71a16,16,0,0,1,24.5,20.58Z"/></svg>
                </div>
            </div>
        </aside>
        <div class="content">
            <oag-fastlane-eintrag-beschlagwortung *ngIf="arbeitslisteItem" [image]="image"></oag-fastlane-eintrag-beschlagwortung>
            <h1 class="content__no-choosen-item" *ngIf="!arbeitslisteItem">Kein Item gewählt!</h1>
        </div>
    </main>

</div>
