import { Component, OnInit } from '@angular/core';
import { EintragPolicyModel } from '@ortsarchiv-gemeinlebarn/data';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';

@Component({
    selector: 'oag-eintrag-detail-edit-policies',
    templateUrl: './eintrag-detail-edit-policies.component.html',
    styleUrls: ['./eintrag-detail-edit-policies.component.scss']
})
export class EintragDetailEditPoliciesComponent implements OnInit
{
    constructor(public eintragEdit: EintragEditService) { }
    public ngOnInit() { } // NOSONAR 

    public addNewPolicy()
    {
        const eintragPolicy = new EintragPolicyModel();
        eintragPolicy.policy = this.eintragEdit.policies[0];
        this.eintragEdit.eintrag.policies.push(eintragPolicy);
    }
}
