import { Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MediaListService } from 'src/app/shared/services/media-list/media-list.service';

@Component({
    selector: 'oag-item-digital-viewer',
    templateUrl: './item-digital-viewer.component.html',
    styleUrls: ['./item-digital-viewer.component.scss']
})
export class ItemDigitalViewerComponent implements OnInit
{
    constructor(public mediaList: MediaListService) { }
    ngOnInit(): void { }
}
