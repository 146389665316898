import { Component, OnInit } from '@angular/core';
import { TagModel } from '@ortsarchiv-gemeinlebarn/data';
import { TagApiService } from 'src/app/shared/services/api-endpoints/tag-api/tag-api.service';

@Component({
    selector: 'oag-tags-page',
    templateUrl: './tags-page.component.html',
    styleUrls: ['./tags-page.component.scss']
})
export class TagsPageComponent implements OnInit
{
    public tags: Array<TagModel> = [];
    public tagEdit: TagModel = null;

    constructor(public tagAPI: TagApiService)
    {
        this.loadTags();
    }

    public ngOnInit() { }

    public resetEdit()
    {
        this.tagEdit = null;
    }

    private loadTags()
    {
        this.resetEdit();
        this.tagAPI.readAll().subscribe((tags) => this.tags = tags);
    }

    public edit(tag: TagModel = null)
    {
        if (tag === null)
        {
            tag = new TagModel();
        }

        this.resetEdit();
        this.tagEdit = tag;
    }

    public update = () => this.tagAPI.update(this.tagEdit).subscribe(() => this.loadTags());
    public create = () => this.tagAPI.create(this.tagEdit).subscribe(() => this.loadTags());
    public delete = () => this.tagAPI.delete(this.tagEdit.id).subscribe(() => this.loadTags());

    public compareIds = (a, b) => (!a || !b) ? false : a.id === b.id;
}
