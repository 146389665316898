import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
    selector: 'oag-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit
{
    constructor(public auth: AuthService) { }
    public ngOnInit() { } // NOSONAR
}
