import { Component, OnInit } from '@angular/core';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';
import { ItemDigitalModel } from '@ortsarchiv-gemeinlebarn/data';
import { ItemExternModel } from '@ortsarchiv-gemeinlebarn/data';
import { ItemInternModel } from '@ortsarchiv-gemeinlebarn/data';

@Component({
    selector: 'oag-eintrag-detail-edit-items',
    templateUrl: './eintrag-detail-edit-items.component.html',
    styleUrls: ['./eintrag-detail-edit-items.component.scss']
})
export class EintragDetailEditItemsComponent implements OnInit
{
    constructor(public eintragEdit: EintragEditService) { }

    public ngOnInit() { } // NOSONAR 

    public addItemIntern()
    {
        const item = new ItemInternModel();
        item.originalitaet = this.eintragEdit.selects.originalitaeten[0];
        item.farbraum = this.eintragEdit.selects.farbraeume[0];
        item.behaeltnis = this.eintragEdit.selects.behaeltnisse[0];
        item.aufstellungsFach = null;
        this.eintragEdit.eintrag.items.intern.push(item);
    }

    public addItemExtern()
    {
        const item = new ItemExternModel();
        item.originalitaet = this.eintragEdit.selects.originalitaeten[0];
        item.farbraum = this.eintragEdit.selects.farbraeume[0];
        item.externerBesitzer = this.eintragEdit.selects.externeBesitzer[0];
        this.eintragEdit.eintrag.items.extern.push(item);
    }

    public addItemDigital()
    {
        const item = new ItemDigitalModel();
        item.nachbearbeitet = false;
        this.eintragEdit.eintrag.items.digital.push(item);
    }

    public removeItemIntern(index)
    {
        this.eintragEdit.eintrag.items.intern.splice(index, 1);
    }

    public removeItemExtern(index)
    {
        this.eintragEdit.eintrag.items.extern.splice(index, 1);
    }

    public removeItemDigital(index)
    {
        this.eintragEdit.eintrag.items.digital.splice(index, 1);
    }
}
