export const environment = {
    name: 'Produktion',
    production: true,
    api: {
        url: 'https://api.ortsarchiv-gemeinlebarn.org/v3'
    },
    auth: {
        domain: 'ortsarchiv-gemeinlebarn.eu.auth0.com',
        clientId: 't8JED2WyJ6NarEtFaMLrJgwLmIO2R17K',
        audience: 'api.ortsarchiv-gemeinlebarn.org'
    },
    storage: {
        large: 'https://oag-store-large.s3.eu-central-1.amazonaws.com',
        thumbnail: 'https://oag-store-thumbnails.s3.eu-central-1.amazonaws.com'
    }
};
