import { Component, Input, OnInit } from '@angular/core';
import { EintragPolicyModel, PolicyModel } from '@ortsarchiv-gemeinlebarn/data';

@Component({
    selector: 'oag-eintrag-policy-view',
    templateUrl: './eintrag-policy-view.component.html',
    styleUrls: ['./eintrag-policy-view.component.scss']
})
export class EintragPolicyViewComponent implements OnInit
{
    @Input() public item: EintragPolicyModel = null;

    get applied(): boolean
    {
        if (this.item?.start == null && this.item?.end == null)
            return true;

        const now = new Date();

        if (this.item?.start != null && this.item?.end == null)
            return now >= new Date(this.item.start);

        if (this.item?.start == null && this.item?.end != null)
            return now <= new Date(this.item.end);

        return now >= new Date(this.item?.start) && now <= new Date(this.item?.end);
    }

    constructor() { }
    ngOnInit() { }
}
