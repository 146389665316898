import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-sub-menu-wrapper',
    templateUrl: './sub-menu-wrapper.component.html',
    styleUrls: ['./sub-menu-wrapper.component.scss']
})
export class SubMenuWrapperComponent implements OnInit
{
    @Input() public items = [];

    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
