import { Component, OnInit } from '@angular/core';
import { PersonModel } from '@ortsarchiv-gemeinlebarn/data';
import { PersonApiService } from 'src/app/shared/services/api-endpoints/person-api/person-api.service';

@Component({
    selector: 'oag-personen-page',
    templateUrl: './personen-page.component.html',
    styleUrls: ['./personen-page.component.scss']
})
export class PersonenPageComponent implements OnInit
{
    public personen: Array<PersonModel> = [];
    public personEdit: PersonModel = null;

    constructor(public personAPI: PersonApiService)
    {
        this.loadPersonen();
    }

    public ngOnInit() { }

    public resetEdit()
    {
        this.personEdit = null;
    }

    private loadPersonen()
    {
        this.resetEdit();
        this.personAPI.readAll().subscribe((personen) => this.personen = personen);
    }

    public edit(person: PersonModel = null)
    {
        if (person === null)
        {
            person = new PersonModel();
        }

        this.resetEdit();
        this.personEdit = person;
    }

    public update = () => this.personAPI.update(this.personEdit).subscribe(() => this.loadPersonen());
    public create = () => this.personAPI.create(this.personEdit).subscribe(() => this.loadPersonen());
    public delete = () => this.personAPI.delete(this.personEdit.id).subscribe(() => this.loadPersonen());

    public compareIds = (a, b) => (!a || !b) ? false : a.id === b.id;
}
