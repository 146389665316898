import { Injectable } from '@angular/core';
import { EreignisModel } from '@ortsarchiv-gemeinlebarn/data';
import { SelectsModel } from '@ortsarchiv-gemeinlebarn/data';
import { FindbuchApiService } from 'src/app/shared/services/api-endpoints/findbuch-api/findbuch-api.service';
import { TimelineApiService } from 'src/app/shared/services/api-endpoints/timeline-api/timeline-api.service';

@Injectable({
    providedIn: 'root'
})
export class TimelineService
{
    private selects: SelectsModel = null;
    public timeline: any = null;
    public ereignis: EreignisModel = null;

    constructor(public timelineAPI: TimelineApiService, public findbuchAPI: FindbuchApiService)
    {
        this.findbuchAPI.selects().subscribe((selects) => this.selects = selects);
        this.loadTimeline();
    }
    public loadTimeline()
    {
        this.timelineAPI.getFullTimeline().subscribe((timeline) => this.timeline = timeline);
    }

    public editEreignis(ereignis: EreignisModel)
    {
        this.ereignis = ereignis;
    }

    public clearEreignisEditing()
    {
        this.ereignis = null;
    }

    public createEreignis()
    {
        this.ereignis = new EreignisModel();
        this.ereignis.kontextualisierung = this.selects.kontextualisierungen[0];
        this.ereignis.priorisierung = this.selects.priorisierungen[0];
        this.ereignis.themaBereich = this.selects.themaBereiche[0];
        this.ereignis.themaUnterkategorie = this.selects.themaUnterkategorien[0];
        this.ereignis.themaHauptkategorie = this.selects.themaUnterkategorien[0].hauptkategorie;
    }
}
