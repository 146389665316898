<oag-content-wrapper>
    <h2>{{ stats.eintraegeAnzahlPerJahrBestand?.name }}</h2>
    <table summary="Einträge per Jahr und Bestand">
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col" *ngFor="let dataset of stats.eintraegeAnzahlPerJahrBestand?.gesamt.years">{{ dataset.year }}</th>
                <th scope="col">Gesamt</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let bestand of stats.eintraegeAnzahlPerJahrBestand?.bestaende">
                <th scope="row">{{ bestand.name }}</th>
                <td *ngFor="let dataset of bestand.years">{{ dataset.count }}</td>
                <td>{{ bestand.gesamt }}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th scope="row">Summe</th>
                <td *ngFor="let dataset of stats.eintraegeAnzahlPerJahrBestand?.gesamt.years">{{ dataset.count }}</td>
                <td>{{ stats.eintraegeAnzahlPerJahrBestand?.gesamt.gesamt }}</td>
            </tr>

        </tfoot>
    </table>
    <div class="chart-full-width">
        <canvas height="100" id="chart-eintraegeAnzahlPerJahrBestand">{{ charts.eintraegeAnzahlPerJahrBestand }}</canvas>
    </div>

    <h2>{{ stats.digitalisierungsgrad?.name }}</h2>
    <div class="digitalisierungsgrad">

        <oag-chart-digitalisierungsgrad
            *ngIf="stats.digitalisierungsgrad"
            id="digitalisierungsgrad-gesamt"
            titel="Gesamt"
            [betroffen]="stats.digitalisierungsgrad?.gesamt.betroffen"
            [gesamt]="stats.digitalisierungsgrad?.gesamt.gesamt">
        </oag-chart-digitalisierungsgrad>

        <oag-chart-digitalisierungsgrad
            *ngFor="let bestand of stats.digitalisierungsgrad?.bestaende"
            [id]="'digitalisierungsgrad-' + bestand.name"
            [titel]="bestand.name"
            [betroffen]="bestand.betroffen"
            [gesamt]="bestand.gesamt">
        </oag-chart-digitalisierungsgrad>
    </div>

    <h2>{{ stats.digitalisierteItemsAnzahlPerJahr?.name }}</h2>
    <div class="chart-full-width">
        <canvas height="100" id="chart-digitalisierteItemsAnzahlPerJahr">{{ charts.digitalisierteItemsAnzahlPerJahr }}</canvas>
    </div>

    <h2>{{ stats.digitalisierteSeitenAnzahlPerJahr?.name }}</h2>
    <div class="chart-full-width">
        <canvas height="100" id="chart-digitalisierteSeitenAnzahlPerJahr">{{ charts.digitalisierteSeitenAnzahlPerJahr }}</canvas>
    </div>
</oag-content-wrapper>
