import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import { FrontendService } from '../../frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class StatistikApiService
{
    private showBackdrop = false;

    constructor(private http: HttpClient, private api: ApiService, public frontend: FrontendService) { }

    public eintraegeAnzahlPerJahrBestand(): Observable<any>
    {
        this.frontend.startLoadingIndicator(this.showBackdrop);
        return this.http
            .get(`${this.api.url}/stats/eintraegeAnzahlPerJahrBestand`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator(this.showBackdrop)),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public digitalisierungsgrad(): Observable<any>
    {
        this.frontend.startLoadingIndicator(this.showBackdrop);
        return this.http
            .get(`${this.api.url}/stats/digitalisierungsgrad`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator(this.showBackdrop)),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public digitalisierteItemsAnzahlPerJahr(): Observable<any>
    {
        this.frontend.startLoadingIndicator(this.showBackdrop);
        return this.http
            .get(`${this.api.url}/stats/digitalisierteItemsAnzahlPerJahr`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator(this.showBackdrop)),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public digitalisierteSeitenAnzahlPerJahr(): Observable<any>
    {
        this.frontend.startLoadingIndicator(this.showBackdrop);
        return this.http
            .get(`${this.api.url}/stats/digitalisierteSeitenAnzahlPerJahr`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator(this.showBackdrop)),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
