<oag-content-wrapper>

    <h2>1. Eintrag</h2>
    <oag-info-item-row>
        <oag-info-item-wrapper width="2" titel="Bestand">{{ eintragView.eintrag?.bestand.signatur }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="2" titel="ID">{{ eintragView.eintrag?.idFormattedPrefix }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="8" titel="Titel">{{ eintragView.eintrag?.titel }}</oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper width="12" titel="Kommentar">{{ eintragView.eintrag?.kommentar }}</oag-info-item-wrapper>
    </oag-info-item-row>

    <h3>1.1. Datierung</h3>
    <oag-info-item-row>
        <oag-info-item-wrapper width="4" titel="Freitext">{{ eintragView.eintrag?.datierung.text }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Von">{{ eintragView.eintrag?.datierung.von | date: 'dd.MM.yyyy' }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Bis">{{ eintragView.eintrag?.datierung.bis | date: 'dd.MM.yyyy' }}</oag-info-item-wrapper>
    </oag-info-item-row>

    <h3>1.2. Thema</h3>
    <oag-info-item-row>
        <oag-info-item-wrapper width="4" titel="Bereich">{{ eintragView.eintrag?.themaUnterkategorie.hauptkategorie.bereich.titel }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Hauptkategorie">{{ eintragView.eintrag?.themaUnterkategorie.hauptkategorie.titel }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Unterkategorie">{{ eintragView.eintrag?.themaUnterkategorie.titel }}</oag-info-item-wrapper>
    </oag-info-item-row>

    <h3>1.3. Medium</h3>
    <oag-info-item-row>
        <oag-info-item-wrapper width="6" titel="Hauptkategorie">{{ eintragView.eintrag?.mediumUnterkategorie.hauptkategorie.titel }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="6" titel="Unterkategorie">{{ eintragView.eintrag?.mediumUnterkategorie.titel }}</oag-info-item-wrapper>
    </oag-info-item-row>

    <h3>1.4. Quelle</h3>
    <oag-info-item-row>
        <oag-info-item-wrapper width="4" titel="Name">{{ eintragView.eintrag?.quelleName }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="8" titel="Infos">{{ eintragView.eintrag?.quelleInfos }}</oag-info-item-wrapper>
    </oag-info-item-row>

</oag-content-wrapper>