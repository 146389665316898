import { Component, OnInit } from '@angular/core';
import { EintragApiService } from 'src/app/shared/services/api-endpoints/eintrag-api/eintrag-api.service';
import { EreignisApiService } from 'src/app/shared/services/api-endpoints/ereignis-api/ereignis-api.service';
import { IndexApiService } from 'src/app/shared/services/api-endpoints/index-api/index-api.service';
import { PersonApiService } from 'src/app/shared/services/api-endpoints/person-api/person-api.service';

@Component({
    selector: 'oag-indizes-page',
    templateUrl: './indizes-page.component.html',
    styleUrls: ['./indizes-page.component.scss']
})
export class IndizesPageComponent implements OnInit
{
    public runEintraege = false;
    public startIndexEintraege = 0;
    public failedEintraege = [];
    public statusEintraege = "nicht  gestartet";

    public runEreignisse = false;
    public failedEreignisse = [];
    public statusEreignisse = "nicht  gestartet";

    public runPersonen = false;
    public failedPersonen = [];
    public statusPersonen = "nicht  gestartet";

    constructor(
        private eintrag: EintragApiService,
        private person: PersonApiService,
        private ereignis: EreignisApiService,
        private index: IndexApiService
    ) { }
    ngOnInit(): void { }

    public async indiziereEintraege(startIndex: number = 0)
    {
        this.runEintraege = true;
        this.failedEintraege = [];
        const eintraege = await this.eintrag.readAllMinimal().toPromise();

        let count = 0;
        for (const eintrag of eintraege.filter((el, i) => i > startIndex))
        {
            if (!this.runEintraege)
                break;

            count++;
            const percent = (count / eintraege.length * 100.00).toFixed(2);
            this.statusEintraege = `${count} von ${eintraege.length} (= ${percent}%)`;

            await this.index.indexEintrag(eintrag.bestand.id, eintrag.id).toPromise().catch(error => this.failedEintraege.push(eintrag));
        }

        this.runEintraege = false;
    }

    public async indiziereEreignisse()
    {
        this.runEreignisse = true;
        const ereignisse = await this.ereignis.readAll().toPromise();

        let count = 0;
        for (const ereignis of ereignisse)
        {
            if (!this.runEreignisse)
                break;

            count++;
            const percent = (count / ereignisse.length * 100.00).toFixed(2);
            this.statusEreignisse = `${count} von ${ereignisse.length} (= ${percent}%)`;

            await this.index.indexEreignis(ereignis.id).toPromise().catch(error => this.failedEreignisse.push(ereignis));
        }

        this.runEreignisse = false;
    }

    public async indizierePersonen()
    {
        this.runPersonen = true;
        const personen = await this.person.readAll().toPromise();

        let count = 0;
        for (const person of personen)
        {
            if (!this.runPersonen)
                break;

            count++;
            const percent = (count / personen.length * 100.00).toFixed(2);
            this.statusPersonen = `${count} von ${personen.length} (= ${percent}%)`;

            await this.index.indexPerson(person.id).toPromise().catch(error => this.failedPersonen.push(person));
        }

        this.runPersonen = false;
    }

    public cancelEintraege = () => this.runEintraege = false;
    public cancelEreignisse = () => this.runEreignisse = false;
    public cancelPersonen = () => this.runPersonen = false;
}
