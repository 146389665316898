<section class="top">
    <header class="top__header">
        <ng-content select="[slot=top-header]"></ng-content>
        <h3 class="titel">{{ titel }}</h3>
        <ng-content></ng-content>
    </header>
    <aside class="top__aside">
        <ng-content select="[slot=top-aside]"></ng-content>
    </aside>
</section>
<section class="bottom">

    <div class="bottom__left">
        <ng-content select="[slot=bottom-left]"></ng-content>
    </div>
    <div class="bottom__right">
        <ng-content select="[slot=bottom-right]"></ng-content>
    </div>
</section>
