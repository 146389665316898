import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';
import { ApplikationService } from 'src/app/shared/services/applikation/applikation.service';

@Component({
    selector: 'oag-eintrag-detail-edit-wrapper',
    templateUrl: './eintrag-detail-edit-wrapper.component.html',
    styleUrls: ['./eintrag-detail-edit-wrapper.component.scss']
})
export class EintragDetailEditWrapperComponent implements OnInit
{
    public viewLink = null;

    constructor(public eintragEdit: EintragEditService, public applikation: ApplikationService, private route: ActivatedRoute, private router: Router) { }

    public ngOnInit()
    {
        if (this.route.parent === null)
        {
            return;
        }

        this.route.parent.params.subscribe((params) =>
        {
            const bestandId = Number(params.bestandId);
            const eintragId = Number(params.eintragId);

            if (bestandId && eintragId)
            {
                this.eintragEdit.load(bestandId, eintragId);
            }
            else
            {
                this.eintragEdit.createEmpty();
            }
        });
    }

    public switchToView()
    {
        const url = this.router.url.replace('edit', 'view');
        this.router.navigateByUrl(url);
    }
}
