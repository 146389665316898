<h4 *ngIf="!item?.id">Neues Item Intern</h4>
<h4 *ngIf="item?.id">{{ item?.idFormattedPrefix }}</h4>
<oag-info-item-row>
    <oag-info-item-wrapper width="3" titel="Originalität">
        <select [(ngModel)]="item.originalitaet" [compareWith]="eintragEdit.compareIds">
            <option *ngFor="let originalitaet of eintragEdit.selects?.originalitaeten" [ngValue]="originalitaet">{{ originalitaet.titel }}</option>
        </select>
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Farbraum">
        <select [(ngModel)]="item.farbraum" [compareWith]="eintragEdit.compareIds">
            <option *ngFor="let farbraum of eintragEdit.selects?.farbraeume" [ngValue]="farbraum">{{ farbraum.titel }}</option>
        </select>
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Größe">
        <input type="text" [(ngModel)]="item.groesse">
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Beschreibung">
        <input type="text" [(ngModel)]="item.beschreibung">
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper width="2" titel="Behältnis oder Fach">
        <select [(ngModel)]="behaeltnisOrFach" (change)="behaeltnisOrFachChanged()">
            <option value="behaeltnis">Behältnis</option>
            <option value="fach">Fach</option>
        </select>
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="10" *ngIf="item.behaeltnis" titel="Behältnis">
        <select [(ngModel)]="item.behaeltnis" [compareWith]="eintragEdit.compareIds">
            <option *ngFor="let behaeltnis of eintragEdit.selects?.behaeltnisse" [ngValue]="behaeltnis">
                {{ behaeltnis.bestand.signatur }}/BEH{{ behaeltnis.idFormatted }} ({{ behaeltnis.name}})
            </option>
        </select>
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="10" *ngIf="item.aufstellungsFach" titel="Kasten und Fach">
        <select [(ngModel)]="item.aufstellungsFach" [compareWith]="eintragEdit.compareIds">
            <option *ngFor="let fach of eintragEdit.selects?.faecher" [ngValue]="fach">
                {{ fach.kasten.name }} ({{ fach.spalte ? ('Fach: ' + fach.spalte + ' / ' + fach.reihe) : (fach.name) }})
            </option>
        </select>
    </oag-info-item-wrapper>
</oag-info-item-row>
<ng-content></ng-content>
