import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-upload-item-step',
    templateUrl: './upload-item-step.component.html',
    styleUrls: ['./upload-item-step.component.scss']
})
export class UploadItemStepComponent implements OnInit
{
    @Input() public number: number = null;
    @Input() public progress: string = null;
    @Input() public title: string = null;
    @Input() public done = false;
    @Input() public inProgress = false;

    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
