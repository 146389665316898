import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-timeline-decade',
    templateUrl: './timeline-decade.component.html',
    styleUrls: ['./timeline-decade.component.scss']
})
export class TimelineDecadeComponent implements OnInit
{
    @Input() public decade = null;

    constructor() { }
    public ngOnInit() { }
}
