<div class="frontend-backdrop frontend-backdrop--white" [hidden]="!frontend.isLoadingIndicatorBackdropShown()"></div>

<div class="frontend-backdrop" [hidden]="!frontend.modal.show">
    <div class="frontend-backdrop__center">

        <div class="frontend-modal" [hidden]="!frontend.modal.show">
            <h2>{{ frontend.modal.headline }}</h2>
            <p *ngIf="frontend.modal.type === 'text'">{{ frontend.modal.content }}</p>
            <div *ngIf="frontend.modal.type === 'html'" [innerHTML]="frontend.modal.content"></div>
            <div *ngIf="frontend.modal.type === 'http-error'" class="http-error">
                <div class="item">
                    <div class="key">Status Code</div>
                    <div class="value">{{ frontend.modal.content.status }} ({{ frontend.modal.content.statusText }})</div>
                </div>
                <div class="item">
                    <div class="key">Fehlermeldung</div>
                    <div class="value">{{ frontend.modal.content.error.message }}</div>
                </div>
            </div>
            <button [hidden]="frontend.modal.hideButton" class="button" (click)="frontend.closeModal()">
                Schließen
            </button>
        </div>
    </div>
</div>
