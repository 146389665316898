import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EintragModule } from './eintrag/eintrag.module';
import { MediaViewModule } from './media-view/media-view.module';
import { SearchModule } from './search/search.module';
import { SettingsModule } from './settings/settings.module';
import { SharedModule } from './shared/shared.module';
import { UserModule } from './user/user.module';
import { VerwaltungModule } from './verwaltung/verwaltung.module';
import { ZeittafelModule } from './zeittafel/zeittafel.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        SearchModule,
        EintragModule,
        MediaViewModule,
        VerwaltungModule,
        ZeittafelModule,
        SettingsModule,
        UserModule,
        SharedModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
