import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { EintragModel, LetzteEintraegeModel } from '@ortsarchiv-gemeinlebarn/data';
import { ApiService } from '../../api/api.service';
import { FrontendService } from '../../frontend/frontend.service';
import { EintragPolicyModel } from '@ortsarchiv-gemeinlebarn/data';

@Injectable({
    providedIn: 'root'
})
export class EintragApiService
{
    constructor(private http: HttpClient, private api: ApiService, public frontend: FrontendService) { }

    public getByBestandIdEintragId(bestandId: number, eintragId: number): Observable<EintragModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/bestaende/${bestandId}/eintraege/${eintragId}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public create(eintrag: EintragModel)
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .post(`${this.api.url}/eintraege`, eintrag, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public update(eintrag: EintragModel)
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .put(`${this.api.url}/eintraege/${eintrag.idFormattedPrefix}`, eintrag, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public jumpSearch(term: string): Observable<any>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/eintraege/jump-search/${term}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public readAllMinimal(): Observable<Array<any>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/eintraege/all/minimal`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public readLetzteEintraege(anzahl: number = 500): Observable<Array<LetzteEintraegeModel>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/eintraege/letzte/minimal/${anzahl}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public updateItemsInternBehaeltnis(eintragIdWithPrefix: string, behaeltnis: any): Observable<any>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .put(`${this.api.url}/eintraege/${eintragIdWithPrefix}/items/intern/behaeltnis`, behaeltnis, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public updateItemsInternAufstellungsFach(eintragIdWithPrefix: string, fach: any): Observable<any>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .put(`${this.api.url}/eintraege/${eintragIdWithPrefix}/items/intern/aufstellungsFach`, fach, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public addPolicy(bestandId: number, eintragId: number, eintragPolicy: EintragPolicyModel): Observable<EintragModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .post(`${this.api.url}/bestaende/${bestandId}/eintraege/${eintragId}/policies`, eintragPolicy, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public removePolicy(bestandId: number, eintragId: number, eintragPolicyId: number): Observable<EintragModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .delete(`${this.api.url}/bestaende/${bestandId}/eintraege/${eintragId}/policies/${eintragPolicyId}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
