<h3 class="titel">{{ ereignis?.titel }}</h3>

<div class="meta-informationen">
    <oag-meta-information-item key="Thema" [value]="ereignis?.themaUnterkategorie.hauptkategorie.titel + ' / ' + ereignis?.themaUnterkategorie.titel"></oag-meta-information-item>
    <oag-meta-information-item key="Kontext" [value]="ereignis?.kontextualisierung.name"></oag-meta-information-item>
    <oag-meta-information-item key="Priorität" [value]="ereignis?.priorisierung.name"></oag-meta-information-item>
</div>

<p class="beschreibung">{{ ereignis?.beschreibung }}</p>

<div class="toggle-buttons">
    <button class="toggle-button" (click)="toggleEintraege()" [ngClass]="{'toggle-button--active': showEintraege}" [disabled]="ereignis?.eintraege.length === 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-h</title><path d="M256,160c16-63.16,76.43-95.41,208-96a15.94,15.94,0,0,1,16,16V368a16,16,0,0,1-16,16c-128,0-177.45,25.81-208,64-30.37-38-80-64-208-64-9.88,0-16-8.05-16-17.93V80A15.94,15.94,0,0,1,48,64C179.57,64.59,240,96.84,256,160Z" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="256" y1="160" x2="256" y2="448" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
        <span>Einträge</span>
    </button>
    <button class="toggle-button" (click)="togglePersonen()" [ngClass]="{'toggle-button--active': showPersonen}" [disabled]="ereignis?.personen.length === 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-j</title><path d="M402,168c-2.93,40.67-33.1,72-66,72s-63.12-31.32-66-72c-3-42.31,26.37-72,66-72S405,126.46,402,168Z" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><path d="M336,304c-65.17,0-127.84,32.37-143.54,95.41-2.08,8.34,3.15,16.59,11.72,16.59H467.83c8.57,0,13.77-8.25,11.72-16.59C463.85,335.36,401.18,304,336,304Z" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px"/><path d="M200,185.94C197.66,218.42,173.28,244,147,244S96.3,218.43,94,185.94C91.61,152.15,115.34,128,147,128S202.39,152.77,200,185.94Z" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><path d="M206,306c-18.05-8.27-37.93-11.45-59-11.45-52,0-102.1,25.85-114.65,76.2C30.7,377.41,34.88,384,41.72,384H154" style="fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"/></svg>
        <span>Personen</span>
    </button>
    <button class="toggle-button" (click)="toggleQuellen()" [ngClass]="{'toggle-button--active': showQuellen}">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-o</title><path d="M208,352H144a96,96,0,0,1,0-192h64" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:36px"/><path d="M304,160h64a96,96,0,0,1,0,192H304" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:36px"/><line x1="163.29" y1="256" x2="350.71" y2="256" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:36px"/></svg>
        <span>Quellen</span>
    </button>
</div>
<div class="expandable-informationen">
    <oag-expendable-information [expended]="showEintraege">
        <oag-label-wrapper *ngFor="let eintrag of ereignis?.eintraege" [titel]="eintrag.idFormattedPrefix" [quickViewEintrag]="eintrag"></oag-label-wrapper>
    </oag-expendable-information>
    <oag-expendable-information [expended]="showPersonen">
        <oag-label-wrapper *ngFor="let person of ereignis?.personen" [titel]="person.label" [quickViewPerson]="person"></oag-label-wrapper>
    </oag-expendable-information>
    <oag-expendable-information [expended]="showQuellen">
        <p class="quellen">{{ ereignis?.quellen }}</p>
    </oag-expendable-information>
</div>
