import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-page-wrapper',
    templateUrl: './page-wrapper.component.html',
    styleUrls: ['./page-wrapper.component.scss']
})
export class PageWrapperComponent implements OnInit
{
    @Input() public type: string;
    @HostBinding('class') public class = '';

    constructor() { }
    public ngOnInit()
    {
        this.class += this.type;
    }
}
