import { Component, OnInit } from '@angular/core';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';
import { PersonModel } from '@ortsarchiv-gemeinlebarn/data';
import { TagModel } from '@ortsarchiv-gemeinlebarn/data';
import { PersonApiService } from 'src/app/shared/services/api-endpoints/person-api/person-api.service';
import { TagApiService } from 'src/app/shared/services/api-endpoints/tag-api/tag-api.service';

@Component({
    selector: 'oag-eintrag-detail-edit-tags-personen',
    templateUrl: './eintrag-detail-edit-tags-personen.component.html',
    styleUrls: ['./eintrag-detail-edit-tags-personen.component.scss']
})
export class EintragDetailEditTagsPersonenComponent implements OnInit
{
    public tags: Array<TagModel> = [];
    public personen: Array<PersonModel> = [];

    constructor(public eintragEdit: EintragEditService, public tagAPI: TagApiService, public personAPI: PersonApiService) { }

    public ngOnInit()
    {
        this.tagAPI.readAll().subscribe((tags) => this.tags = tags);
        this.personAPI.readAll().subscribe((personen) => this.personen = personen);
    }
}
