import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { ArbeitslisteItemEintragModel } from '@ortsarchiv-gemeinlebarn/data';
import { ArbeitslisteModel } from '@ortsarchiv-gemeinlebarn/data';
import { ApiService } from '../../api/api.service';
import { FrontendService } from '../../frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class ArbeitslisteApiService
{
    constructor(public http: HttpClient, public api: ApiService, public frontend: FrontendService) { }

    public create(arbeitsliste: ArbeitslisteModel): Observable<ArbeitslisteModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .post(`${this.api.url}/arbeitslisten/manuell`, arbeitsliste, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public readSingle(id: number): Observable<Array<ArbeitslisteModel>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/arbeitslisten/manuell/${id}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public readAll(): Observable<Array<ArbeitslisteModel>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/arbeitslisten/manuell`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public update(arbeitsliste: ArbeitslisteModel): Observable<ArbeitslisteModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .put(`${this.api.url}/arbeitslisten/manuell/${arbeitsliste.id}`, arbeitsliste, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public delete(id: number)
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .delete(`${this.api.url}/arbeitslisten/manuell/${id}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }
    public addItemOfArbeitsliste(arbeitslistenId: number, arbeitslisteItem: ArbeitslisteItemEintragModel): Observable<ArbeitslisteItemEintragModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .post(`${this.api.url}/arbeitslisten/manuell/${arbeitslistenId}/items`, arbeitslisteItem, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public readAllItemsOfArbeitsliste(arbeitslistenId: number): Observable<Array<ArbeitslisteItemEintragModel>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/arbeitslisten/manuell/${arbeitslistenId}/items`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public finishItemOfArbeitsliste(arbeitslistenId: number, arbeitslisteItemId: number)
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .put(`${this.api.url}/arbeitslisten/manuell/${arbeitslistenId}/items/${arbeitslisteItemId}/finish`, {}, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public deleteItemOfArbeitsliste(arbeitslistenId: number, arbeitslisteItemId: number)
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .delete(`${this.api.url}/arbeitslisten/manuell/${arbeitslistenId}/items/${arbeitslisteItemId}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
