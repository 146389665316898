import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EintragApiService } from 'src/app/shared/services/api-endpoints/eintrag-api/eintrag-api.service';

@Component({
    selector: 'oag-eintrag-choose-page',
    templateUrl: './eintrag-choose-page.component.html',
    styleUrls: ['./eintrag-choose-page.component.scss']
})
export class EintragChoosePageComponent implements OnInit
{
    public search = '';

    constructor(private eintragAPI: EintragApiService, private router: Router) { }
    public ngOnInit() { } // NOSONAR

    public searchAndJump()
    {
        this.eintragAPI
            .jumpSearch(this.search)
            .subscribe((result) => this.router.navigate([`/eintrag/${result.eintrag.bestand.id}/${result.eintrag.id}/view/${result.view}`]));
    }
}
