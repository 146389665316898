<oag-content-wrapper *ngIf="eintragEdit.eintrag">

    <h2>1. Eintrag</h2>
    <oag-info-item-row>
        <oag-info-item-wrapper width="4" titel="Bestand" *ngIf="!eintragEdit.eintrag.id">
            <select [(ngModel)]="eintragEdit.eintrag.bestand" [compareWith]="eintragEdit.compareIds">
                <option
                    *ngFor="let bestand of eintragEdit.selects?.bestaende"
                    [ngValue]="bestand">{{ bestand.signatur }}</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="2" titel="Bestand" *ngIf="eintragEdit.eintrag.id">{{ eintragEdit.eintrag.bestand.signatur }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="2" titel="ID" *ngIf="eintragEdit.eintrag.id">{{ eintragEdit.eintrag.idFormattedPrefix }}</oag-info-item-wrapper>
        <oag-info-item-wrapper width="8" titel="Titel">
            <input type="text" [(ngModel)]="eintragEdit.eintrag.titel">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper width="12" titel="Kommentar">
            <textarea [(ngModel)]="eintragEdit.eintrag.kommentar"></textarea>
        </oag-info-item-wrapper>
    </oag-info-item-row>

    <h3>1.1. Datierung</h3>
    <oag-info-item-row *ngIf="eintragEdit.eintrag.datierung">
        <oag-info-item-wrapper width="4" titel="Freitext">
            <input type="text" [(ngModel)]="eintragEdit.eintrag.datierung.text">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Von">
            <input type="date" [(ngModel)]="eintragEdit.eintrag.datierung.von">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Bis">
            <input type="date" [(ngModel)]="eintragEdit.eintrag.datierung.bis">
        </oag-info-item-wrapper>
    </oag-info-item-row>

    <h3>1.2. Thema</h3>
    <oag-info-item-row *ngIf="eintragEdit.eintrag.themaBereich">
        <oag-info-item-wrapper width="4" titel="Bereich">
            <select [(ngModel)]="eintragEdit.eintrag.themaBereich" [compareWith]="eintragEdit.compareIds" (change)="themaBereichChanged()">
                <option
                    *ngFor="let bereich of eintragEdit.selects?.themaBereiche"
                    [ngValue]="bereich">{{ bereich.titel }}</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Hauptkategorie">
            <select [(ngModel)]="eintragEdit.eintrag.themaHauptkategorie" [compareWith]="eintragEdit.compareIds" (change)="themaHauptkategorieChanged()">
                <option
                    *ngFor="let hauptkategorie of eintragEdit.selects?.themaHauptkategorien"
                    [hidden]="hauptkategorie.bereich.id !== eintragEdit.eintrag.themaBereich.id"
                    [ngValue]="hauptkategorie">{{ hauptkategorie.titel }}</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Unterkategorie">
            <select [(ngModel)]="eintragEdit.eintrag.themaUnterkategorie" [compareWith]="eintragEdit.compareIds">
                <option
                    *ngFor="let unterkategorie of eintragEdit.selects?.themaUnterkategorien"
                    [hidden]="unterkategorie.hauptkategorie.id != eintragEdit.eintrag.themaUnterkategorie.hauptkategorie.id"
                    [ngValue]="unterkategorie">{{ unterkategorie.titel }}</option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>

    <h3>1.3. Medium</h3>
    <oag-info-item-row *ngIf="eintragEdit.eintrag.mediumUnterkategorie">
        <oag-info-item-wrapper width="6" titel="Hauptkategorie">
            <select [(ngModel)]="eintragEdit.eintrag.mediumHauptkategorie" [compareWith]="eintragEdit.compareIds" (change)="mediumHauptkategorieChanged()">
                <option
                    *ngFor="let hauptkategorie of eintragEdit.selects?.mediumHauptkategorien"
                    [ngValue]="hauptkategorie">{{ hauptkategorie.titel }}</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="6" titel="Unterkategorie">
            <select [(ngModel)]="eintragEdit.eintrag.mediumUnterkategorie" [compareWith]="eintragEdit.compareIds">
                <option
                    *ngFor="let unterkategorie of eintragEdit.selects?.mediumUnterkategorien"
                    [hidden]="unterkategorie.hauptkategorie.id != eintragEdit.eintrag.mediumUnterkategorie.hauptkategorie.id"
                    [ngValue]="unterkategorie">{{ unterkategorie.titel }}</option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>

    <h3>1.4. Quelle</h3>
    <oag-info-item-row>
        <oag-info-item-wrapper width="4" titel="Name">
            <input type="text" [(ngModel)]="eintragEdit.eintrag.quelleName">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="8" titel="Infos">
            <input type="text" [(ngModel)]="eintragEdit.eintrag.quelleInfos">
        </oag-info-item-wrapper>
    </oag-info-item-row>

    <h3>1.5. Urheber</h3>
    <oag-info-item-row>
        <oag-info-item-wrapper width="4" titel="Urheber bekannt">
            <select [(ngModel)]="eintragEdit.eintrag.urheberBekannt">
                <option [ngValue]="true">Ja</option>
                <option [ngValue]="false">Nein</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Urheber Name">
            <input type="text" [(ngModel)]="eintragEdit.eintrag.urheberName">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Infos">
            <input type="text" [(ngModel)]="eintragEdit.eintrag.urheberInfos">
        </oag-info-item-wrapper>
    </oag-info-item-row>
</oag-content-wrapper>