import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'oag-verwaltung-page',
    templateUrl: './verwaltung-page.component.html',
    styleUrls: ['./verwaltung-page.component.scss']
})
export class VerwaltungPageComponent implements OnInit
{
    constructor() { }
    public ngOnInit() { }
}
