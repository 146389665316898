import { Component, Input, OnInit } from '@angular/core';
import { QuickViewService } from 'src/app/shared/services/quick-view/quick-view.service';

@Component({
    selector: 'oag-search-result-item-person',
    templateUrl: './search-result-item-person.component.html',
    styleUrls: ['./search-result-item-person.component.scss']
})
export class SearchResultItemPersonComponent implements OnInit
{
    @Input() public person = null;

    constructor(public quickView: QuickViewService) { }
    public ngOnInit() { } // NOSONAR
}
