<oag-content-wrapper>

    <h2>2. Vorhandene interne, externe & digitale Objekte</h2>

    <h3>2.1. Intern <button class="button" (click)="addItemIntern()">hinzufügen</button></h3>
    <oag-item-intern-edit *ngFor="let item of eintragEdit.eintrag?.items?.intern; let i = index" [item]="item">
        <button class="button button--transparent button--medium" (click)="removeItemIntern(i)">
            <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-e</title><path d="M112,112l20,320c.95,18.49,14.4,32,32,32H348c17.67,0,30.87-13.51,32-32l20-320" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="80" y1="112" x2="432" y2="112" style="stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"/><path d="M192,112V72h0a23.93,23.93,0,0,1,24-24h80a23.93,23.93,0,0,1,24,24h0v40" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="256" y1="176" x2="256" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="184" y1="176" x2="192" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="328" y1="176" x2="320" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
            entfernen
        </button>
    </oag-item-intern-edit>
    <p [hidden]="eintragEdit.eintrag?.items?.intern.length">Diesem Eintrag sind keine interne (physisch vorhandene) Objekte zugewiesen.</p>

    <h3>2.2. Extern <button class="button" (click)="addItemExtern()">hinzufügen</button></h3>
    <oag-item-extern-edit *ngFor="let item of eintragEdit.eintrag?.items?.extern; let i = index" [item]="item">
        <button class="button button--transparent button--medium" (click)="removeItemExtern(i)">
            <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-e</title><path d="M112,112l20,320c.95,18.49,14.4,32,32,32H348c17.67,0,30.87-13.51,32-32l20-320" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="80" y1="112" x2="432" y2="112" style="stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"/><path d="M192,112V72h0a23.93,23.93,0,0,1,24-24h80a23.93,23.93,0,0,1,24,24h0v40" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="256" y1="176" x2="256" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="184" y1="176" x2="192" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="328" y1="176" x2="320" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
            entfernen
        </button>
    </oag-item-extern-edit>
    <p [hidden]="eintragEdit.eintrag?.items?.extern.length">Diesem Eintrag sind keine externen (in anderen Archiven oder Privatbeständen vorhandene) Objekte zugewiesen.</p>

    <h3>2.3. Digital <button class="button" (click)="addItemDigital()">hinzufügen</button></h3>
    <oag-item-digital-edit *ngFor="let item of eintragEdit.eintrag?.items?.digital; let i = index" [item]="item">
        <button class="button button--transparent button--medium" (click)="removeItemDigital(i)">
            <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-e</title><path d="M112,112l20,320c.95,18.49,14.4,32,32,32H348c17.67,0,30.87-13.51,32-32l20-320" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="80" y1="112" x2="432" y2="112" style="stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"/><path d="M192,112V72h0a23.93,23.93,0,0,1,24-24h80a23.93,23.93,0,0,1,24,24h0v40" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="256" y1="176" x2="256" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="184" y1="176" x2="192" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="328" y1="176" x2="320" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
            entfernen
        </button>
    </oag-item-digital-edit>
    <p [hidden]="eintragEdit.eintrag?.items?.digital.length">Diesem Eintrag sind keine digitalen Objekte zugewiesen.</p>

</oag-content-wrapper>
