<oag-page-wrapper *ngIf="auth.loggedIn">
    <oag-sub-menu-wrapper>
        <oag-sub-menu-item titel="Task Logs" icon="analytics-outline" link="task-logging" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Anwendungsinformationen" icon="code-slash-outline" link="anwendungsinformationen" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Benutzerprofil" link="profile" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Logout" slot="right" (click)="auth.logout()"></oag-sub-menu-item>
    </oag-sub-menu-wrapper>
    <oag-content-wrapper>
        <router-outlet></router-outlet>
    </oag-content-wrapper>
</oag-page-wrapper>
