import { Component, OnInit } from '@angular/core';
import { EintragViewService } from 'src/app/eintrag/services/eintrag-view/eintrag-view.service';

@Component({
    selector: 'oag-eintrag-detail-view-tags-personen',
    templateUrl: './eintrag-detail-view-tags-personen.component.html',
    styleUrls: ['./eintrag-detail-view-tags-personen.component.scss']
})
export class EintragDetailViewTagsPersonenComponent implements OnInit
{
    constructor(public eintragView: EintragViewService) { }
    public ngOnInit() { } // NOSONAR
}
