<header>
    <h3>Upload #{{ upload?.id }}</h3>
    <p>{{ upload?.itemDigital.idFormattedPrefix }}<span>&bull;</span>{{ upload?.itemDigital.eintrag.idFormattedPrefix }}<span>&bull;</span>{{ upload?.fileName }}<span>&bull;</span>{{ upload?.datumErstellt | date: 'dd.MM.yyyy HH:mm:ss' }}</p>
</header>
<section [hidden]="upload?.processingFinished">
    <oag-upload-item-step
        number="0" title="Analyze"
        [done]="upload?.stepAnalyzeDone"
        [inProgress]="upload?.processingStarted && !upload?.stepAnalyzeDone"></oag-upload-item-step>
    <oag-upload-item-step-divider [done]="upload?.stepAnalyzeDone"></oag-upload-item-step-divider>
    <oag-upload-item-step
        number="1" title="Original"
        [done]="upload?.stepOriginalDone"
        [inProgress]="upload?.stepAnalyzeDone && !upload?.stepOriginalDone"
        [progress]="upload?.stepOriginalPagesNumber + ' / ' + upload?.pagesNumber"></oag-upload-item-step>
    <oag-upload-item-step-divider [done]="upload?.stepOriginalDone"></oag-upload-item-step-divider>
    <oag-upload-item-step
        number="2"
        title="Compression Big"
        [done]="upload?.stepCompressionBigDone"
        [inProgress]="upload?.stepOriginalDone && !upload?.stepCompressionBigDone"
        [progress]="upload?.stepCompressionBigPagesNumber + ' / ' + upload?.pagesNumber"></oag-upload-item-step>
    <oag-upload-item-step-divider [done]="upload?.stepCompressionBigDone"></oag-upload-item-step-divider>
    <oag-upload-item-step
        number="3" title="Compression Thumbnail"
        [done]="upload?.stepCompressionThumbnailDone"
        [inProgress]="upload?.stepCompressionBigDone && !upload?.stepCompressionThumbnailDone"
        [progress]="upload?.stepCompressionThumbnailPagesNumber + ' / ' + upload?.pagesNumber"></oag-upload-item-step>
    <oag-upload-item-step-divider [done]="upload?.stepCompressionThumbnailDone"></oag-upload-item-step-divider>
    <oag-upload-item-step
        number="4"
        title="Finish"
        [done]="upload?.stepFinishDone"
        [inProgress]="upload?.stepCompressionThumbnailDone && !upload?.stepFinishDone"></oag-upload-item-step>
</section>
