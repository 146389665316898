import { Component, OnInit } from '@angular/core';
import { Feature, Map, View } from 'ol';
import { Circle } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { Draw } from 'ol/interaction';
import { Tile, Vector as VectorLayer } from 'ol/layer';
import { transform } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';

@Component({
    selector: 'oag-eintrag-detail-edit-verortung',
    templateUrl: './eintrag-detail-edit-verortung.component.html',
    styleUrls: ['./eintrag-detail-edit-verortung.component.scss']
})
export class EintragDetailEditVerortungComponent implements OnInit
{
    public map: Map = null;
    public interactionSource: VectorSource = new VectorSource({});
    public interactionLayer: VectorLayer = new VectorLayer({
        source: this.interactionSource,
        style: new Style({
            fill: new Fill({
                color: 'rgba(57, 86, 155, 0.5)'
            }),
            stroke: new Stroke({
                color: '#39569b',
                width: 3
            })
        })
    });
    public drawInteraction: Draw = new Draw({
        source: this.interactionSource,
        type: GeometryType.CIRCLE
    });

    public mode = 'view';
    public drawn = false;

    constructor(public eintragEdit: EintragEditService) { }
    public ngOnInit()
    {
        this.map = new Map({
            target: 'map',
            controls: [],
            layers: [
                new Tile({
                    source: new OSM({})
                }),
                this.interactionLayer
            ],
            view: new View({
                center: transform([15.804525, 48.337050], 'EPSG:4326', 'EPSG:3857'),
                zoom: 15
            })
        });

        this.view();
        this.drawExisting();

        this.drawInteraction.on('drawend', () => this.afterDrawed());
    }

    public drawExisting()
    {
        if (this.eintragEdit.eintrag.verortung)
        {
            if (this.eintragEdit.eintrag.verortung.lat && this.eintragEdit.eintrag.verortung.lng)
            {
                const center = transform([this.eintragEdit.eintrag.verortung.lat, this.eintragEdit.eintrag.verortung.lng], 'EPSG:4326', 'EPSG:3857');
                const circle = new Circle(center, this.eintragEdit.eintrag.verortung.radius);
                const feature = new Feature({
                    geometry: circle
                });
                this.interactionSource.addFeature(feature);
            }

        }
        else
        {
            setTimeout(() => this.drawExisting(), 1000);
        }
    }

    private afterDrawed()
    {
        this.drawn = true;
        this.view();

        setTimeout(() =>
        {
            const geom: any = this.interactionSource.getFeatures()[0].getGeometry();
            const latlng = transform(geom.getCenter(), 'EPSG:3857', 'EPSG:4326');
            const radius = geom.getRadius();

            this.eintragEdit.eintrag.verortung.lat = latlng[0];
            this.eintragEdit.eintrag.verortung.lng = latlng[1];
            this.eintragEdit.eintrag.verortung.radius = radius;
        }, 300);

    }

    public view()
    {
        this.mode = 'view';
        this.map.removeInteraction(this.drawInteraction);
    }

    public edit()
    {
        if (this.drawn === false)
        {
            this.mode = 'edit';
            this.map.addInteraction(this.drawInteraction);
        }
    }

    public remove()
    {
        this.mode = 'remove';
        this.interactionSource.clear();
        this.drawn = false;
        this.view();
        this.resetVerortungInEintrag();
    }

    public resetVerortungInEintrag()
    {
        this.eintragEdit.eintrag.verortung.lat = null;
        this.eintragEdit.eintrag.verortung.lng = null;
        this.eintragEdit.eintrag.verortung.radius = null;
    }
}
