import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { ItemDigitalModel } from '@ortsarchiv-gemeinlebarn/data';
import { ItemDigitalApiService } from '../api-endpoints/item-digital-api/item-digital-api.service';

@Injectable({
    providedIn: 'root'
})
export class MediaListService
{
    public display = false;
    public displayPageSelect = false;
    public displayItemsDigitalSelect = false;

    public currentItemDigital = null;
    public currentItemDigitalIndex = null;
    public currentItemDigitalHasPreviousItem = false;
    public currentItemDigitalHasNextItem = false;

    public currentItemDigitalPage = null;
    public currentItemDigitalPageIndex = null;
    public currentItemDigitalPageHasPreviousPage = false;
    public currentItemDigitalPageHasNextPage = false;

    public items = [];

    constructor(private itemDigitalAPI: ItemDigitalApiService)
    {
        document.body.addEventListener('keydown', event => this.onKeyDown(event));
    }

    public openItemDigitalById(itemDigitalId: number, itemList: Array<ItemDigitalModel> = [], pageNumer: number = 1)
    {
        this.itemDigitalAPI.readSilent(itemDigitalId).subscribe((itemDigital) =>
        {
            this.currentItemDigital = itemDigital;
            this.items = itemList;

            this.currentItemDigitalIndex = this.items.findIndex(item => item.id === this.currentItemDigital.id);
            if (this.currentItemDigitalIndex === -1) this.currentItemDigitalIndex = null;

            this.currentItemDigitalHasPreviousItem = this.items[this.currentItemDigitalIndex - 1] !== undefined;
            this.currentItemDigitalHasNextItem = this.items[this.currentItemDigitalIndex + 1] !== undefined;

            if (this.currentItemDigital.type == 'image')
            {
                this.switchToPageNumber(pageNumer);
            }
            else
            {

            }

            this.display = true;
        });
    }

    public openItemsDigtialSelect()
    {
        this.displayItemsDigitalSelect = true;
    }

    public openPageSelect()
    {
        this.displayPageSelect = true;
    }

    public previousPage()
    {
        if (this.currentItemDigitalPageHasPreviousPage)
        {
            const item = this.currentItemDigital.pages[this.currentItemDigitalPageIndex - 1];
            this.switchToPageNumber(item.number);
        }
    }

    public nextPage()
    {
        if (this.currentItemDigitalPageHasNextPage)
        {
            const item = this.currentItemDigital.pages[this.currentItemDigitalPageIndex + 1];
            this.switchToPageNumber(item.number);
        }
    }

    public switchToPageNumber(pageNumer: number)
    {
        this.currentItemDigitalPageIndex = this.currentItemDigital.pages.findIndex(item => item.number == pageNumer);
        this.currentItemDigitalPage = this.currentItemDigital.pages[this.currentItemDigitalPageIndex];

        this.currentItemDigitalPageHasPreviousPage = this.currentItemDigital.pages[this.currentItemDigitalPageIndex - 1] !== undefined;
        this.currentItemDigitalPageHasNextPage = this.currentItemDigital.pages[this.currentItemDigitalPageIndex + 1] !== undefined;

        this.displayPageSelect = false;
    }

    public onKeyDown(event)
    {
        if (event.key === 'ArrowRight')
        {
            if (this.currentItemDigitalPageHasNextPage)
            {
                this.nextPage();
            }
            else if (this.currentItemDigitalHasNextItem)
            {
                const item = this.items[this.currentItemDigitalIndex + 1];
                this.openItemDigitalById(item.id, this.items);
            }
        }

        if (event.key === 'ArrowLeft')
        {
            if (this.currentItemDigitalPageHasPreviousPage)
            {
                this.previousPage();
            }
            else if (this.currentItemDigitalHasPreviousItem)
            {
                const item = this.items[this.currentItemDigitalIndex - 1];
                this.openItemDigitalById(item.id, this.items);
            }
        }
    }

    public close()
    {
        this.display = false;
    }
}
