import { Component, OnInit } from '@angular/core';
import { FrontendService } from '../../services/frontend/frontend.service';

@Component({
    selector: 'oag-frontend',
    templateUrl: './frontend.component.html',
    styleUrls: ['./frontend.component.scss']
})
export class FrontendComponent implements OnInit
{
    constructor(public frontend: FrontendService) { }
    public ngOnInit() { } // NOSONAR
}
