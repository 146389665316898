import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueOrUnknown'
})
export class ValueOrUnknownPipe implements PipeTransform
{
    public transform(value: any): any
    {
        return value ? value : 'Unbekannt';
    }
}
