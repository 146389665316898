import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { FrontendService } from 'src/app/shared/services/frontend/frontend.service';
import { ApiService } from '../../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class IndexApiService
{
    constructor(private http: HttpClient, private api: ApiService, public frontend: FrontendService) { }

    public indexEintrag(bestandId: number, eintragId: number): Observable<any>
    {
        return this.http
            .post(`${this.api.url}/search/eintraege/index/${bestandId}/${eintragId}`, null, { headers: this.api.headers })
            .pipe(
                retry(1),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public indexEreignis(id: number): Observable<any>
    {
        return this.http
            .post(`${this.api.url}/search/ereignisse/index/${id}`, null, { headers: this.api.headers })
            .pipe(
                retry(1),
                catchError((error) => this.api.errorHandler(error))
            );
    }
    public indexPerson(id: number): Observable<any>
    {
        return this.http
            .post(`${this.api.url}/search/personen/index/${id}`, null, { headers: this.api.headers })
            .pipe(
                retry(1),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
