import { Component, Input, OnInit } from '@angular/core';
import { EintragPolicyModel, PolicyModel } from '@ortsarchiv-gemeinlebarn/data';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';

@Component({
    selector: 'oag-eintrag-policy-edit',
    templateUrl: './eintrag-policy-edit.component.html',
    styleUrls: ['./eintrag-policy-edit.component.scss']
})
export class EintragPolicyEditComponent implements OnInit
{
    public policies: Array<PolicyModel> = [];
    @Input() public item: EintragPolicyModel = new EintragPolicyModel();
    @Input() public index: number = null;

    public startISO = "";
    public endISO = "";

    constructor(public eintragEdit: EintragEditService) { }

    ngOnInit(): void
    {
        if (this.item?.start)
        {
            const date = new Date(this.item.start);
            this.startISO = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);;
        }

        if (this.item?.end)
        {
            const date = new Date(this.item.end);
            this.endISO = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);;
        }
    }

    public onDatesChange()
    {
        if (this.startISO)
            this.item.start = this.startISO.replace('T', ' ') + ":00";

        if (this.endISO)
            this.item.end = this.endISO.replace('T', ' ') + ":00";
    }

    public removeMe()
    {
        if (this.index !== null)
        {
            this.eintragEdit.eintrag.policies.splice(this.index, 1);
        }
    }
}
