<oag-info-item-row>
    <oag-info-item-wrapper width="12" titel="Policy">
        <select [(ngModel)]="item.policy" [compareWith]="eintragEdit.compareSlugs">
            <option *ngFor="let policy of eintragEdit.policies" [ngValue]="policy">{{ policy.name }}</option>
        </select>
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper width="4" titel="Beginn">
        <input type="datetime-local" [(ngModel)]="startISO" (change)="onDatesChange()">
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="2" titel="">
        <button class="button" (click)="startISO = item.start = null;" [disabled]="item.start ? '' : 'disabled'">NULL</button>
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="4" titel="Ende">
        <input type="datetime-local" [(ngModel)]="endISO" (change)="onDatesChange()">
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="2" titel="">
        <button class="button" (click)="endISO = item.end = null;" [disabled]="item.end ? '' : 'disabled'">NULL</button>
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper width="12" titel="Anmerkung">
        <input type="text" [(ngModel)]="item.note">
    </oag-info-item-wrapper>
</oag-info-item-row>

<div class="buttons">
    <button class="button button--outline button--medium" (click)="removeMe()">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-e</title><path d="M112,112l20,320c.95,18.49,14.4,32,32,32H348c17.67,0,30.87-13.51,32-32l20-320" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="80" y1="112" x2="432" y2="112" style="stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"/><path d="M192,112V72h0a23.93,23.93,0,0,1,24-24h80a23.93,23.93,0,0,1,24,24h0v40" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="256" y1="176" x2="256" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="184" y1="176" x2="192" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="328" y1="176" x2="320" y2="400" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
        Richtlinie von Eintrag entfernen
    </button>
</div>