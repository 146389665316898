<oag-page-wrapper>
    <oag-sub-menu-wrapper>
        <oag-sub-menu-item titel="Neues Ereignis" (click)="timeline.createEreignis()" slot="left"></oag-sub-menu-item>
        <oag-sub-menu-item *ngFor="let century of timeline.timeline" [titel]="century.jahrhundert + '. Jahrhundert'" (click)="scrolltoCentury(century.jahrhundert)" slot="right"></oag-sub-menu-item>
    </oag-sub-menu-wrapper>
    <oag-content-wrapper *ngIf="timeline.ereignis">
        <oag-ereignis-edit></oag-ereignis-edit>
    </oag-content-wrapper>
    <div class="wrapper" id="scroll">
        <oag-timeline-century [id]="'century_'+jahrhundert.jahrhundert" *ngFor="let jahrhundert of timeline.timeline" [century]="jahrhundert"></oag-timeline-century>
    </div>
</oag-page-wrapper>
