import { Component, Input, OnInit } from '@angular/core';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';
import { ItemDigitalModel } from '@ortsarchiv-gemeinlebarn/data';

@Component({
    selector: 'oag-item-digital-edit',
    templateUrl: './item-digital-edit.component.html',
    styleUrls: ['./item-digital-edit.component.scss']
})
export class ItemDigitalEditComponent implements OnInit
{
    @Input() public item: ItemDigitalModel = new ItemDigitalModel();

    constructor(public eintragEdit: EintragEditService) { }
    public ngOnInit() { } // NOSONAR
}
