<oag-page-wrapper type="center" [ngClass]="displayModeCLass()">
    <header>
        <div class="searchform">
            <h2 class="headline">
                <span style="font-weight: 600;">Findbuch</span>
                <span style="font-weight: 300;">Suche</span>
            </h2>
            <div class="controls">
                <input type="text" placeholder="Suchtext" [(ngModel)]="search.searchTerm" (keyup.enter)="search.getSearchresults()" />
            </div>
            <button class="button" (click)="search.getSearchresults()">
                Im Archiv suchen
            </button>
        </div>
        <div class="tabs">
            <div class="tabs__left">
                <div class="tab" [ngClass]="{ 'tab--active': isActiveEntity(entityTypes.Eintraege), 'tab--not-empty': search.resultsEintraege?.hits.total.value}" (click)="switchEntity(entityTypes.Eintraege)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-i</title><path d="M384,80H128c-26,0-43,14-48,40L48,272V384a48.14,48.14,0,0,0,48,48H416a48.14,48.14,0,0,0,48-48V272L432,120C427,93,409,80,384,80Z" style="fill:none;stroke:#000;stroke-linejoin:round;stroke-width:32px"/><line x1="48" y1="272" x2="192" y2="272" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="320" y1="272" x2="464" y2="272" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><path d="M192,272a64,64,0,0,0,128,0" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
                    <span class="tab__titel">Einträge</span>
                    <span class="tab__bubble">{{ search.resultsEintraege?.hits.total.value }}</span>
                </div>
                <div class="tab" [ngClass]="{ 'tab--active': isActiveEntity(entityTypes.Ereignisse), 'tab--not-empty': search.resultsEreignisse?.hits.total.value}" (click)="switchEntity(entityTypes.Ereignisse)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-e</title><rect x="48" y="80" width="416" height="384" rx="48" ry="48" style="fill:none;stroke:#000;stroke-linejoin:round;stroke-width:32px"/><path d="M397.82,80H114.18C77.69,80,48,110.15,48,147.2V208H64c0-16,16-32,32-32H416c16,0,32,16,32,32h16V147.2C464,110.15,434.31,80,397.82,80Z"/><circle cx="296" cy="232" r="24"/><circle cx="376" cy="232" r="24"/><circle cx="296" cy="312" r="24"/><circle cx="376" cy="312" r="24"/><circle cx="136" cy="312" r="24"/><circle cx="216" cy="312" r="24"/><circle cx="136" cy="392" r="24"/><circle cx="216" cy="392" r="24"/><circle cx="296" cy="392" r="24"/><line x1="128" y1="48" x2="128" y2="80" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="384" y1="48" x2="384" y2="80" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
                    <span class="tab__titel">Ereignisse</span>
                    <span class="tab__bubble">{{ search.resultsEreignisse?.hits.total.value }}</span>
                </div>
                <div class="tab" [ngClass]="{ 'tab--active': isActiveEntity(entityTypes.Personen), 'tab--not-empty': search.resultsPersonen?.hits.total.value}" (click)="switchEntity(entityTypes.Personen)">
                    <svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'><title>ionicons-v5-j</title><path d='M344,144c-3.92,52.87-44,96-88,96s-84.15-43.12-88-96c-4-55,35-96,88-96S348,90,344,144Z' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/><path d='M256,304c-87,0-175.3,48-191.64,138.6C62.39,453.52,68.57,464,80,464H432c11.44,0,17.62-10.48,15.65-21.4C431.3,352,343,304,256,304Z' style='fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px'/></svg>
                    <span class="tab__titel">Personen</span>
                    <span class="tab__bubble">{{ search.resultsPersonen?.hits.total.value }}</span>
                </div>
            </div>
        </div>
    </header>

    <div class="results">

        <div class="results__list" *ngIf="isActiveEntity(entityTypes.Eintraege)">
            <oag-search-result-item-eintrag *ngFor="let searchResult of search.resultsEintraege?.hits.hits" [eintrag]="searchResult._source"></oag-search-result-item-eintrag>
            <h2 [hidden]="search.resultsEintraege?.hits.total.value || !search.firstSearchDone">Es wurden leider keine Einträge zur Suche gefunden!</h2>
        </div>

        <div class="results__list" *ngIf="isActiveEntity(entityTypes.Ereignisse)">
            <h2 [hidden]="search.resultsEreignisse?.hits.total.value || !search.firstSearchDone">Es wurden leider keine Ereignisse zur Suche gefunden!</h2>
            <oag-search-result-item-ereignis *ngFor="let searchResult of search.resultsEreignisse?.hits.hits" [ereignis]="searchResult._source"></oag-search-result-item-ereignis>
        </div>

        <div class="results__list" *ngIf="isActiveEntity(entityTypes.Personen)">
            <h2 [hidden]="search.resultsPersonen?.hits.total.value || !search.firstSearchDone">Es wurden leider keine Personen zur Suche gefunden!</h2>
            <oag-search-result-item-person *ngFor="let searchResult of search.resultsPersonen?.hits.hits" [person]="searchResult._source"></oag-search-result-item-person>
        </div>

    </div>

</oag-page-wrapper>
