<oag-page-wrapper>
    <oag-sub-menu-wrapper>
        <oag-sub-menu-item titel="Bearbeiten" icon="create-outline" (click)="switchToEdit()" slot="left"></oag-sub-menu-item>

        <oag-sub-menu-item titel="1. Eintrag" link="eintrag" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="2. Items" link="items" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="3. Tags & Personen" link="tags-personen" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="4. Verortung" link="verortung" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="5. Ereignisse & Serien" link="ereignisse-serien" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="6. Schaufenster & Richtlinien" link="policies" slot="right"></oag-sub-menu-item>
    </oag-sub-menu-wrapper>
    <router-outlet></router-outlet>
</oag-page-wrapper>
