<oag-page-wrapper>
    <oag-sub-menu-wrapper>{{viewLink}}
        <oag-sub-menu-item titel="Ansicht" icon="glasses-outline" (click)="switchToView()" slot="left" *ngIf="eintragEdit.eintrag.id"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Speichern" icon="save-outline" slot="left" (click)="eintragEdit.save()"></oag-sub-menu-item>

        <oag-sub-menu-item titel="1. Eintrag" link="eintrag" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="2. Items" link="items" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="3. Tags & Personen" link="tags-personen" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="4. Verortung" link="verortung" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="5. Ereignisse & Serien" link="ereignisse-serien" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="6. Schaufenster & Richtlinien" link="policies" slot="right"></oag-sub-menu-item>
    </oag-sub-menu-wrapper>
    <router-outlet *ngIf="eintragEdit.eintrag && applikation.selects"></router-outlet>
</oag-page-wrapper>
