import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EintragIdFormattedPrefixComponent } from './components/eintrag-id-formatted-prefix/eintrag-id-formatted-prefix.component';
import { ExpendableInformationComponent } from './components/expendable-information/expendable-information.component';
import { FrontendComponent } from './components/frontend/frontend.component';
import { LabelWrapperComponent } from './components/label-wrapper/label-wrapper.component';
import { InfoItemEditInputComponent } from './components/layout/info-item-edit-input/info-item-edit-input.component';
import { InfoItemRowComponent } from './components/layout/info-item-row/info-item-row.component';
import { InfoItemWrapperComponent } from './components/layout/info-item-wrapper/info-item-wrapper.component';
import { ContentWrapperComponent } from './components/layout/page/content-wrapper/content-wrapper.component';
import { PageWrapperComponent } from './components/layout/page/page-wrapper/page-wrapper.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { MetaInformationItemComponent } from './components/meta-information-item/meta-information-item.component';
import { MultipleSelectEintragComponent } from './components/multiple-select-eintrag/multiple-select-eintrag.component';
import { MultipleSelectEreignisComponent } from './components/multiple-select-ereignis/multiple-select-ereignis.component';
import { MultipleSelectPersonComponent } from './components/multiple-select-person/multiple-select-person.component';
import { MultipleSelectSerieComponent } from './components/multiple-select-serie/multiple-select-serie.component';
import { MultipleSelectTagComponent } from './components/multiple-select-tag/multiple-select-tag.component';
import { BottomNavItemComponent } from './components/navigation/bottom-nav-item/bottom-nav-item.component';
import { MainMenuItemComponent } from './components/navigation/main-menu-item/main-menu-item.component';
import { SubMenuItemComponent } from './components/navigation/sub-menu-item/sub-menu-item.component';
import { SubMenuWrapperComponent } from './components/navigation/sub-menu-wrapper/sub-menu-wrapper.component';
import { QuickViewEintragComponent } from './components/quick-view-eintrag/quick-view-eintrag.component';
import { QuickViewPersonEditComponent } from './components/quick-view-person-edit/quick-view-person-edit.component';
import { QuickViewPersonComponent } from './components/quick-view-person/quick-view-person.component';
import { QuickViewComponent } from './components/quick-view/quick-view.component';
import { ArrayJoinChildesPipe } from './pipes/array-join-childes/array-join-childes.pipe';
import { BooleanAsTextPipe } from './pipes/boolean-as-text/boolean-as-text.pipe';
import { NoContentMessagePipe } from './pipes/no-content-message/no-content-message.pipe';
import { ValueOrUnknownPipe } from './pipes/value-or-unkown/value-or-unknown.pipe';
import { EintragApiService } from './services/api-endpoints/eintrag-api/eintrag-api.service';
import { ApiService } from './services/api/api.service';
import { ApplikationService } from './services/applikation/applikation.service';
import { FrontendService } from './services/frontend/frontend.service';
import { MediaListService } from './services/media-list/media-list.service';

@NgModule({
    declarations: [
        PageWrapperComponent,
        MainMenuItemComponent,
        SubMenuWrapperComponent,
        SubMenuItemComponent,
        EintragIdFormattedPrefixComponent,
        InfoItemWrapperComponent,
        InfoItemRowComponent,
        InfoItemEditInputComponent,
        ContentWrapperComponent,
        FrontendComponent,
        ArrayJoinChildesPipe,
        LoadingIndicatorComponent,
        MetaInformationItemComponent,
        ExpendableInformationComponent,
        BooleanAsTextPipe,
        LabelWrapperComponent,
        NoContentMessagePipe,
        BottomNavItemComponent,
        QuickViewComponent,
        QuickViewEintragComponent,
        QuickViewPersonComponent,
        ValueOrUnknownPipe,
        QuickViewPersonEditComponent,
        MultipleSelectPersonComponent,
        MultipleSelectTagComponent,
        MultipleSelectEintragComponent,
        MultipleSelectSerieComponent,
        MultipleSelectEreignisComponent
    ],
    exports: [
        PageWrapperComponent,
        MainMenuItemComponent,
        SubMenuWrapperComponent,
        SubMenuItemComponent,
        EintragIdFormattedPrefixComponent,
        InfoItemWrapperComponent,
        InfoItemRowComponent,
        InfoItemEditInputComponent,
        ContentWrapperComponent,
        FrontendComponent,
        ArrayJoinChildesPipe,
        LoadingIndicatorComponent,
        MetaInformationItemComponent,
        ExpendableInformationComponent,
        ArrayJoinChildesPipe,
        BooleanAsTextPipe,
        LabelWrapperComponent,
        NoContentMessagePipe,
        BottomNavItemComponent,
        QuickViewComponent,
        QuickViewEintragComponent,
        QuickViewPersonComponent,
        ValueOrUnknownPipe,
        QuickViewPersonEditComponent,
        MultipleSelectPersonComponent,
        MultipleSelectTagComponent,
        MultipleSelectEintragComponent,
        MultipleSelectSerieComponent,
        MultipleSelectEreignisComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule
    ],
    providers: [
        FrontendService,
        MediaListService,
        ApplikationService,
        ApiService,
        EintragApiService
    ]
})
export class SharedModule { }
