import { Component, Input, OnInit } from '@angular/core';
import { UploadModel } from '@ortsarchiv-gemeinlebarn/data';

@Component({
    selector: 'oag-upload-item',
    templateUrl: './upload-item.component.html',
    styleUrls: ['./upload-item.component.scss']
})
export class UploadItemComponent implements OnInit
{
    @Input() public upload: UploadModel = null;

    constructor() { }
    public ngOnInit() { } // NOSONAR
}
