<section>
    <div class="image">
        <img alt="Bild" [src]="image" alt="">
    </div>
    <div class="action">
        <oag-eintrag-id-formatted-prefix [eintrag]="eintragEdit.eintrag" size="26px"></oag-eintrag-id-formatted-prefix>
        <p>Beschlagwortung</p>

        <h3>Hauptobjekte</h3>
        <oag-multiple-select-tag [(choosen)]="eintragEdit.eintrag.tags.hauptobjekte"></oag-multiple-select-tag>

        <h3>Nebenobjekte</h3>
        <oag-multiple-select-tag [(choosen)]="eintragEdit.eintrag.tags.nebenobjekte"></oag-multiple-select-tag>

        <h3>Personen</h3>
        <oag-multiple-select-person [(choosen)]="eintragEdit.eintrag.personen"></oag-multiple-select-person>

        <button class="button" (click)="eintragEdit.save()">Speichern</button>
    </div>
</section>
