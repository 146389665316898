import { Component, OnInit } from '@angular/core';
import { Feature, Map, View } from 'ol';
import { Circle } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { Draw } from 'ol/interaction';
import { Tile, Vector as VectorLayer } from 'ol/layer';
import { transform } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import { EintragViewService } from 'src/app/eintrag/services/eintrag-view/eintrag-view.service';

@Component({
    selector: 'oag-eintrag-detail-view-verortung',
    templateUrl: './eintrag-detail-view-verortung.component.html',
    styleUrls: ['./eintrag-detail-view-verortung.component.scss']
})
export class EintragDetailViewVerortungComponent implements OnInit
{
    public map: Map = null;
    public interactionSource: VectorSource = new VectorSource({});
    public interactionLayer: VectorLayer = new VectorLayer({
        source: this.interactionSource,
        style: new Style({
            fill: new Fill({
                color: 'rgba(57, 86, 155, 0.5)'
            }),
            stroke: new Stroke({
                color: '#39569b',
                width: 3
            })
        })
    });
    public drawInteraction: Draw = new Draw({
        source: this.interactionSource,
        type: GeometryType.CIRCLE
    });

    public mode = 'view';
    public drawn = false;

    constructor(public eintragView: EintragViewService) { }
    public ngOnInit()
    {
        this.map = new Map({
            target: 'map',
            controls: [],
            layers: [
                new Tile({
                    source: new OSM({})
                }),
                this.interactionLayer
            ],
            view: new View({
                center: transform([15.804525, 48.337050], 'EPSG:4326', 'EPSG:3857'),
                zoom: 15
            })
        });

        this.drawExisting();
    }

    public drawExisting()
    {
        if (this.eintragView.eintrag)
        {
            if (this.eintragView.eintrag.verortung.lat && this.eintragView.eintrag.verortung.lng)
            {
                const center = transform([this.eintragView.eintrag.verortung.lat, this.eintragView.eintrag.verortung.lng], 'EPSG:4326', 'EPSG:3857');
                const circle = new Circle(center, this.eintragView.eintrag.verortung.radius);
                const feature = new Feature({
                    geometry: circle
                });
                this.interactionSource.addFeature(feature);
            }

        }
        else
        {
            setTimeout(() => this.drawExisting(), 1000);
        }
    }
}
