import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { version } from 'src/info/version';

@Component({
    selector: 'oag-anwendungsinformationen-page',
    templateUrl: './anwendungsinformationen-page.component.html',
    styleUrls: ['./anwendungsinformationen-page.component.scss']
})
export class AnwendungsinformationenPageComponent implements OnInit
{
    public env = environment;
    public version = version;
    public currentJWT = '...';

    constructor(public auth: AuthService)
    {
        this.auth.getTokenSilently$().subscribe((jwt) => this.currentJWT = jwt);
    }
    public ngOnInit() { }
}
