import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-timeline-item-wrapper',
    templateUrl: './timeline-item-wrapper.component.html',
    styleUrls: ['./timeline-item-wrapper.component.scss']
})
export class TimelineItemWrapperComponent implements OnInit
{
    @Input() public datierung = null;
    @Input() public color = null;

    constructor() { }
    public ngOnInit() { }
}
