import { Component, OnInit } from '@angular/core';
import { QuickViewService } from '../../services/quick-view/quick-view.service';

@Component({
    selector: 'oag-quick-view',
    templateUrl: './quick-view.component.html',
    styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit
{
    constructor(public quickView: QuickViewService) { }
    public ngOnInit() { } // NOSONAR
}
