import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SearchResultItemEintragComponent } from './components/search-result-item-eintrag/search-result-item-eintrag.component';
import { SearchResultItemEreignisComponent } from './components/search-result-item-ereignis/search-result-item-ereignis.component';
import { SearchResultItemPersonComponent } from './components/search-result-item-person/search-result-item-person.component';
import { SearchResultItemComponent } from './components/search-result-item/search-result-item.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        SharedModule
    ],
    declarations: [
        SearchPageComponent,
        SearchResultItemComponent,
        SearchResultItemEintragComponent,
        SearchResultItemEreignisComponent,
        SearchResultItemPersonComponent
    ],
    exports: [
        SearchPageComponent,
        SearchResultItemEintragComponent,
        SearchResultItemEreignisComponent,
        SearchResultItemPersonComponent
    ]
})
export class SearchModule { }
