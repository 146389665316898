<oag-content-wrapper>

    <h2>5. Ereignisse & Serien</h2>
    <h3>5.1. Ereignisse</h3>
    <oag-ereignis [ereignis]="ereignis" *ngFor="let ereignis of eintragView.eintrag?.ereignisse"></oag-ereignis>

    <h3>5.2. Serien</h3>
    <oag-label-wrapper *ngFor="let serie of eintragView.eintrag?.serien" [titel]="serie.name"></oag-label-wrapper>

</oag-content-wrapper>
