<oag-page-wrapper>
    <oag-sub-menu-wrapper>
        <oag-sub-menu-item titel="Schaufenster" icon="videocam-outline" link="schaufenster" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Indizes" icon="search-outline" link="indizes" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Fastlane" icon="speedometer-outline" link="fastlane" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Uploads" icon="cloud-upload-outline" link="uploads" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Letzte Einträge" icon="albums-outline" link="letzte-eintraege" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Behältnis Zuweisung" icon="folder-open-outline" link="behaeltnis-zuweisung" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Etiketten" icon="barcode-outline" link="etiketten" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Exports" icon="document-outline" link="exports" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Statistiken" icon="stats-chart-outline" link="statistiken" slot="right"></oag-sub-menu-item>
    </oag-sub-menu-wrapper>
    <router-outlet></router-outlet>
</oag-page-wrapper>
