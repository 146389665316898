<h4>{{ item?.idFormattedPrefix }}</h4>
<oag-info-item-row>
    <oag-info-item-wrapper width="3" titel="Originalität">{{ item?.originalitaet.titel }}</oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Farbraum">{{ item?.farbraum.titel }}</oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Größe">{{ item?.groesse }}</oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Beschreibung">{{ item?.beschreibung }}</oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper width="9" titel="Externer Besitzer">
        <span *ngIf="item?.externerBesitzer.type === 'Institution'">{{ item?.externerBesitzer.name }}<br /></span>
        <span [hidden]="!item?.externerBesitzer.nachname">{{ item?.externerBesitzer.vorname }} {{ item?.externerBesitzer.nachname }}<br /></span>
        <span [hidden]="!item?.externerBesitzer.adresse">{{ item?.externerBesitzer.adresse }}<br /></span>
        <span [hidden]="!item?.externerBesitzer.plz">{{ item?.externerBesitzer.plz }} {{ item?.externerBesitzer.ort }}<br /></span>
        <span [hidden]="!item?.externerBesitzer.land">{{ item?.externerBesitzer.land }}<br /></span>
        <span [hidden]="!item?.externerBesitzer.email">{{ item?.externerBesitzer.email }}<br /></span>
        <span [hidden]="!item?.externerBesitzer.telefon">{{ item?.externerBesitzer.telefon }}</span>
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Externe Signatur">{{ item?.externeSignatur }}</oag-info-item-wrapper>
</oag-info-item-row>
