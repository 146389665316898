import { Component, Input, OnInit } from '@angular/core';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';
import { ItemExternModel } from '@ortsarchiv-gemeinlebarn/data';

@Component({
    selector: 'oag-item-extern-edit',
    templateUrl: './item-extern-edit.component.html',
    styleUrls: ['./item-extern-edit.component.scss']
})
export class ItemExternEditComponent implements OnInit
{
    @Input() public item: ItemExternModel = new ItemExternModel();

    constructor(public eintragEdit: EintragEditService) { }
    public ngOnInit() { } // NOSONAR
}
