<oag-content-wrapper class="list">

    <h1>Themen</h1>

    <header class="headline-button-container">
        <h2>Bereiche</h2>
        <button class="button button--medium" (click)="editBereich()">Neuer Bereich</button>
    </header>
    <table summary="Themen Bereiche">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Titel</th>
                <th scope="col">SortIndex</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let bereich of themaBereiche">
                <td>{{ bereich.id }}</td>
                <td>{{ bereich.titel }}</td>
                <td>{{ bereich.sortIndex }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="editBereich(bereich)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>

    <header class="headline-button-container">
        <h2>Hauptkategorien</h2>
        <button class="button button--medium" (click)="editHauptkategorie()">Neue Hauptkategorie</button>
    </header>
    <table summary="Themen Hauptkategorien">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Bereich</th>
                <th scope="col">Titel</th>
                <th scope="col">Abkürzung</th>
                <th scope="col">SortIndex</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let hauptkategorie of themaHauptkategorien">
                <td>{{ hauptkategorie.id }}</td>
                <td>{{ hauptkategorie.bereich.titel }}</td>
                <td>{{ hauptkategorie.titel }}</td>
                <td>{{ hauptkategorie.abkuerzung }}</td>
                <td>{{ hauptkategorie.sortIndex }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="editHauptkategorie(hauptkategorie)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>

    <header class="headline-button-container">
        <h2>Unterkategorien</h2>
        <button class="button button--medium" (click)="editUnterkategorie()">Neue Unterkategorie</button>
    </header>
    <table summary="Themen Unterkategorien">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Titel</th>
                <th scope="col">Abkuerzung</th>
                <th scope="col">Hauptkategorie</th>
                <th scope="col">SortIndex</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let unterkategorie of themaUnterkategorien">
                <td>{{ unterkategorie.id }}</td>
                <td>{{ unterkategorie.titel }}</td>
                <td>{{ unterkategorie.abkuerzung }}</td>
                <td>{{ unterkategorie.hauptkategorie.titel }}</td>
                <td>{{ unterkategorie.sortIndex }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="editUnterkategorie(unterkategorie)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>
</oag-content-wrapper>

<div class="edit" *ngIf="themaBereichEdit || themaHauptkategorieEdit || themaUnterkategorieEdit">
    <div *ngIf="themaBereichEdit">
        <header class="headline-button-container">
            <h3>Bereich</h3>
            <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
        </header>
        <oag-info-item-row>
            <oag-info-item-wrapper width="12" titel="Titel">
                <input type="text" [(ngModel)]="themaBereichEdit.titel">
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <oag-info-item-row>
            <oag-info-item-wrapper width="12" titel="SortIndex">
                <input type="number" [(ngModel)]="themaBereichEdit.sortIndex">
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <div class="edit-button-container">
            <button class="button" *ngIf="themaBereichEdit.id" (click)="updateEditThemaBereich()">Speichern</button>
            <button class="button" *ngIf="!themaBereichEdit.id" (click)="createEditThemaBereich()">Erstellen</button>
            <button class="button button--outline" *ngIf="themaBereichEdit.id" (click)="deleteEditThemaBereich()">Löschen</button>
        </div>
    </div>

    <div *ngIf="themaHauptkategorieEdit">
        <header class="headline-button-container">
            <h3>Hauptkategorie</h3>
            <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
        </header>
        <oag-info-item-row>
            <oag-info-item-wrapper width="12" titel="Titel">
                <input type="text" [(ngModel)]="themaHauptkategorieEdit.titel">
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <oag-info-item-row>
            <oag-info-item-wrapper width="12" titel="Bereich">
                <select [(ngModel)]="themaHauptkategorieEdit.bereich" [compareWith]="compareIds">
                    <option *ngFor="let bereich of themaBereiche" [ngValue]="bereich">{{ bereich.titel }}</option>
                </select>
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <oag-info-item-row>
            <oag-info-item-wrapper width="6" titel="Abkürzung">
                <input type="text" [(ngModel)]="themaHauptkategorieEdit.abkuerzung">
            </oag-info-item-wrapper>
            <oag-info-item-wrapper width="6" titel="SortIndex">
                <input type="number" [(ngModel)]="themaHauptkategorieEdit.sortIndex">
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <div class="edit-button-container">
            <button class="button" *ngIf="themaHauptkategorieEdit.id" (click)="updateEditThemaHauptkategorie()">Speichern</button>
            <button class="button" *ngIf="!themaHauptkategorieEdit.id" (click)="createEditThemaHauptkategorie()">Erstellen</button>
            <button class="button button--outline" *ngIf="themaHauptkategorieEdit.id" (click)="deleteEditThemaHauptkategorie()">Löschen</button>
        </div>
    </div>

    <div *ngIf="themaUnterkategorieEdit">
        <header class="headline-button-container">
            <h3>Unterkategorie</h3>
            <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
        </header>
        <oag-info-item-row>
            <oag-info-item-wrapper width="12" titel="Titel">
                <input type="text" [(ngModel)]="themaUnterkategorieEdit.titel">
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <oag-info-item-row>
            <oag-info-item-wrapper width="12" titel="Hauptkategorie">
                <select [(ngModel)]="themaUnterkategorieEdit.hauptkategorie" [compareWith]="compareIds">
                    <option *ngFor="let themaHauptkategorie of themaHauptkategorien" [ngValue]="themaHauptkategorie">{{ themaHauptkategorie.titel }}</option>
                </select>
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <oag-info-item-row>
            <oag-info-item-wrapper width="6" titel="Abkürzung">
                <input type="text" [(ngModel)]="themaUnterkategorieEdit.abkuerzung">
            </oag-info-item-wrapper>
            <oag-info-item-wrapper width="6" titel="SortIndex">
                <input type="number" [(ngModel)]="themaUnterkategorieEdit.sortIndex">
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <div class="edit-button-container">
            <button class="button" *ngIf="themaUnterkategorieEdit.id" (click)="updateEditThemaUnterkategorie()">Speichern</button>
            <button class="button" *ngIf="!themaUnterkategorieEdit.id" (click)="createEditThemaUnterkategorie()">Erstellen</button>
            <button class="button button--outline" *ngIf="themaUnterkategorieEdit.id" (click)="deleteEditThemaUnterkategorie()">Löschen</button>
        </div>
    </div>
</div>
