import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EintragApiService } from 'src/app/shared/services/api-endpoints/eintrag-api/eintrag-api.service';
import { FindbuchApiService } from 'src/app/shared/services/api-endpoints/findbuch-api/findbuch-api.service';
import { ItemInternApiService } from 'src/app/shared/services/api-endpoints/item-intern-api/item-intern-api.service';

@Component({
    selector: 'oag-behaeltnis-zuweisung-page',
    templateUrl: './behaeltnis-zuweisung-page.component.html',
    styleUrls: ['./behaeltnis-zuweisung-page.component.scss']
})
export class BehaeltnisZuweisungPageComponent implements OnInit
{
    public eintragAndOrItemId = '';
    public zuweisungsTyp = 'behaeltnis';
    public selects = null;
    public selectedBehaeltnis = null;
    public selectedFach = null;
    public log = [];
    @ViewChild('input', { static: true }) public input: ElementRef;

    constructor(private findbuchAPI: FindbuchApiService, private eintraegeAPI: EintragApiService, private itemsInternAPI: ItemInternApiService)
    {
        this.findbuchAPI.selects().subscribe((selects) =>
        {
            this.selects = selects;
            this.selectedBehaeltnis = this.selects.behaeltnisse[0];
            this.selectedFach = this.selects.faecher[0];
            this.focusInput();
        });
    }
    public ngOnInit() { }

    public focusInput()
    {
        this.input.nativeElement.focus();
    }

    public zuweisen()
    {
        const uppercaseIds = this.eintragAndOrItemId.toUpperCase();
        let itemInternId = null;
        let eintragIdWithPrefix = null;

        if (uppercaseIds.indexOf('I') !== -1)
        {
            itemInternId = Number(uppercaseIds.split('I')[1]);
        }
        else
        {
            eintragIdWithPrefix = uppercaseIds;
        }

        if (this.zuweisungsTyp === 'behaeltnis')
        {
            if (itemInternId !== null)
            {
                this.itemsInternAPI.updateBehaeltnis(itemInternId, this.selectedBehaeltnis).subscribe();
            }
            else
            {
                this.eintraegeAPI.updateItemsInternBehaeltnis(eintragIdWithPrefix, this.selectedBehaeltnis).subscribe();
            }
            this.addLogEntry(this.zuweisungsTyp, this.selectedBehaeltnis);
        }

        if (this.zuweisungsTyp === 'fach')
        {
            if (itemInternId !== null)
            {
                this.itemsInternAPI.updateAufstellungsFach(itemInternId, this.selectedFach).subscribe();
            }
            else
            {
                this.eintraegeAPI.updateItemsInternAufstellungsFach(eintragIdWithPrefix, this.selectedFach).subscribe();
            }
            this.addLogEntry(this.zuweisungsTyp, this.selectedFach);
        }

        this.eintragAndOrItemId = '';
        this.focusInput();
    }

    private addLogEntry(zuweisungsTyp: string, item: any)
    {
        this.log.push({ type: zuweisungsTyp, item });
    }
}
