import { Injectable } from '@angular/core';
import { SearchEntitesEnum } from 'src/app/shared/enums/SearchEntitesEnum';
import { SearchResultItemModel } from '@ortsarchiv-gemeinlebarn/data';
import { ItemDigitalApiService } from 'src/app/shared/services/api-endpoints/item-digital-api/item-digital-api.service';
import { SearchApiService } from 'src/app/shared/services/api-endpoints/search-api/search-api.service';
import { MediaListService } from 'src/app/shared/services/media-list/media-list.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService
{
    public firstSearchDone = false;
    public searchTerm = '';
    public resultsEintraege: any = null;
    public resultsEreignisse: any = null;
    public resultsPersonen: any = null;
    public selectedEntity: SearchEntitesEnum = SearchEntitesEnum.Eintraege;

    constructor(public searchAPI: SearchApiService, private itemDigitalAPI: ItemDigitalApiService, public mediaList: MediaListService) { }

    public getSearchresults()
    {
        this.firstSearchDone = true;
        this.searchAPI.eintraege(this.searchTerm).subscribe((eintraege) => this.resultsEintraege = eintraege);
        this.searchAPI.ereignisse(this.searchTerm).subscribe((ereignisse) => this.resultsEreignisse = ereignisse);
        this.searchAPI.personen(this.searchTerm).subscribe((personen) => this.resultsPersonen = personen);
    }
}
