import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-info-item-wrapper',
    templateUrl: './info-item-wrapper.component.html',
    styleUrls: ['./info-item-wrapper.component.scss']
})
export class InfoItemWrapperComponent implements OnInit
{
    @Input() public width;
    @Input() public titel = null;

    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
