import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EreignisModel } from '@ortsarchiv-gemeinlebarn/data';

@Component({
    selector: 'oag-search-result-item-ereignis',
    templateUrl: './search-result-item-ereignis.component.html',
    styleUrls: ['./search-result-item-ereignis.component.scss']
})
export class SearchResultItemEreignisComponent implements OnInit
{
    @Input() public ereignis = null;

    constructor(public router: Router) { }
    public ngOnInit() { } // NOSONAR

    public openTimelineAndScrollToEreignis(ereignis: EreignisModel)
    {
        this.router.navigate(['/zeittafel'], { queryParams: { ereignisId: ereignis.id } });
    }
}
