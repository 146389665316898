import { Component, Input, OnInit } from '@angular/core';
import { MediaListService } from 'src/app/shared/services/media-list/media-list.service';

@Component({
    selector: 'oag-item-digital-view',
    templateUrl: './item-digital-view.component.html',
    styleUrls: ['./item-digital-view.component.scss']
})
export class ItemDigitalViewComponent implements OnInit
{
    @Input() public item = null;

    constructor(public mediaList: MediaListService) { }
    public ngOnInit() { } // NOSONAR
}
