import { Component, OnInit } from '@angular/core';
import { SearchEntitesEnum } from 'src/app/shared/enums/SearchEntitesEnum';
import { SearchService } from '../../services/search/search.service';

@Component({
    selector: 'oag-search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit
{
    public entityTypes = SearchEntitesEnum;

    constructor(public search: SearchService) { }
    public ngOnInit() { } // NOSONAR

    public displayModeCLass()
    {
        return { activeInput: this.search.firstSearchDone };
    }

    public isSearchDone()
    {
        return this.search.firstSearchDone;
    }

    public switchEntity(entity: SearchEntitesEnum)
    {
        this.search.selectedEntity = entity;
    }

    public isActiveEntity(entity: SearchEntitesEnum)
    {
        return entity === this.search.selectedEntity;
    }
}
