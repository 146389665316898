import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-sub-menu-item',
    templateUrl: './sub-menu-item.component.html',
    styleUrls: ['./sub-menu-item.component.scss']
})
export class SubMenuItemComponent implements OnInit
{
    @Input() public icon = '';
    @Input() public titel = '';
    @Input() public link = '';

    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
