<oag-content-wrapper>

    <h2>3. Tags & Personen</h2>
    <p>Verknüpfte Schlagworte und Personen</p>

    <h3>3.1. Hauptobjekte</h3>
    <oag-label-wrapper *ngFor="let tag of eintragView.eintrag?.tags.hauptobjekte" [titel]="tag.label"></oag-label-wrapper>
    <p [hidden]="eintragView.eintrag?.tags.hauptobjekte.length">Dieser Eintrag ist mit keinen Hauptobjekt-Tags beschlagwortet.</p>

    <h3>3.2. Nebenobjekte</h3>
    <oag-label-wrapper *ngFor="let tag of eintragView.eintrag?.tags.nebenobjekte" [titel]="tag.label"></oag-label-wrapper>
    <p [hidden]="eintragView.eintrag?.tags.nebenobjekte.length">Dieser Eintrag ist mit keinen Nebenobjekt-Tags beschlagwortet.</p>

    <h3>3.3. Personen</h3>
    <oag-label-wrapper *ngFor="let person of eintragView.eintrag?.personen" [titel]="person.label" [quickViewPerson]="person"></oag-label-wrapper>
    <p [hidden]="eintragView.eintrag?.personen.length">Mit diesem Eintrag sind keine Personen verknüpft.</p>

</oag-content-wrapper>
