import { Component, OnInit } from '@angular/core';
import { MediumHauptkategorieModel } from '@ortsarchiv-gemeinlebarn/data';
import { MediumUnterkategorieModel } from '@ortsarchiv-gemeinlebarn/data';
import { MediumHauptkategorieApiService } from 'src/app/shared/services/api-endpoints/medium-hauptkategorie-api/medium-hauptkategorie-api.service';
import { MediumUnterkategorieApiService } from 'src/app/shared/services/api-endpoints/medium-unterkategorie-api/medium-unterkategorie-api.service';

@Component({
    selector: 'oag-medien-page',
    templateUrl: './medien-page.component.html',
    styleUrls: ['./medien-page.component.scss']
})
export class MedienPageComponent implements OnInit
{
    public mediumHauptkategorien: Array<MediumHauptkategorieModel> = [];
    public mediumHauptkategorieEdit: MediumHauptkategorieModel = null;
    public mediumUnterkategorien: Array<MediumUnterkategorieModel> = [];
    public mediumUnterkategorieEdit: MediumUnterkategorieModel = null;

    constructor(public mediumHauptkategorieAPI: MediumHauptkategorieApiService, public mediumUnterkategorieAPI: MediumUnterkategorieApiService)
    {
        this.loadMediumHauptkategorien();
        this.loadMediumUnterkategorien();
    }

    public ngOnInit() { }

    public resetEdit()
    {
        this.mediumHauptkategorieEdit = null;
        this.mediumUnterkategorieEdit = null;
    }

    private loadMediumHauptkategorien()
    {
        this.resetEdit();
        this.mediumHauptkategorieAPI.readAll().subscribe((hauptkategorien) => this.mediumHauptkategorien = hauptkategorien);
    }

    private loadMediumUnterkategorien()
    {
        this.resetEdit();
        this.mediumUnterkategorieAPI.readAll().subscribe((unterkategorien) => this.mediumUnterkategorien = unterkategorien);
    }

    public editHauptkategorie(hauptkategorie: MediumHauptkategorieModel = null)
    {
        if (hauptkategorie === null)
        {
            hauptkategorie = new MediumHauptkategorieModel();
        }

        this.resetEdit();
        this.mediumHauptkategorieEdit = hauptkategorie;
    }

    public editUnterkategorie(unterkategorie: MediumUnterkategorieModel = null)
    {
        if (unterkategorie === null)
        {
            unterkategorie = new MediumUnterkategorieModel();
            unterkategorie.hauptkategorie = this.mediumHauptkategorien[0];
        }

        this.resetEdit();
        this.mediumUnterkategorieEdit = unterkategorie;
    }

    public updateEditMediumHauptkategorie = () => this.mediumHauptkategorieAPI.update(this.mediumHauptkategorieEdit).subscribe(() => this.loadMediumHauptkategorien());
    public createEditMediumHauptkategorie = () => this.mediumHauptkategorieAPI.create(this.mediumHauptkategorieEdit).subscribe(() => this.loadMediumHauptkategorien());
    public deleteEditMediumHauptkategorie = () => this.mediumHauptkategorieAPI.delete(this.mediumHauptkategorieEdit.id).subscribe(() => this.loadMediumHauptkategorien());

    public updateEditMediumUnterkategorie = () => this.mediumUnterkategorieAPI.update(this.mediumUnterkategorieEdit).subscribe(() => this.loadMediumUnterkategorien());
    public createEditMediumUnterkategorie = () => this.mediumUnterkategorieAPI.create(this.mediumUnterkategorieEdit).subscribe(() => this.loadMediumUnterkategorien());
    public deleteEditMediumUnterkategorie = () => this.mediumUnterkategorieAPI.delete(this.mediumUnterkategorieEdit.id).subscribe(() => this.loadMediumUnterkategorien());

    public compareIds = (a, b) => (!a || !b) ? false : a.id === b.id;
}
