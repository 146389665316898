<oag-content-wrapper>

    <h2>6. Schaufenster & Richtlinien</h2>
    <h3>6.1. Schaufenster Objekte</h3>

    <h3>6.2. Richtlinien</h3>
    <p>Dem Eintrag zugewiesene Richtlinien zur Verwaltung von Zugriffsrechten</p>

    <oag-eintrag-policy-view *ngFor="let eintragPoliciy of eintragView.eintrag?.policies" [item]="eintragPoliciy"></oag-eintrag-policy-view>
    
</oag-content-wrapper>
