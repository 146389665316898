<div class="circle" [ngClass]="{done: done, inProgress: inProgress}">
    <div class="wrapper">
        <div class="number">{{ number }}</div>
        <div class="progress">{{ progress }}</div>
    </div>
    <div class="spinner">
        <div class="spinner__rotate"></div>
        <div class="spinner__inner"></div>
    </div>
</div>
<div class="title">{{ title }}</div>
