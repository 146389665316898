<h3>{{ eintrag?.idFormattedPrefix }}</h3>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Titel" width="12">
        {{ eintrag?.titel }}
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Bestand" width="12">
        {{ eintrag?.bestand?.signatur }} ({{ eintrag?.bestand?.titel }})
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Datierung" width="12">
        {{ eintrag?.datierung?.text | valueOrUnknown }}
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Thema" width="12">
        {{ eintrag?.themaUnterkategorie?.hauptkategorie.titel }} / {{ eintrag?.themaUnterkategorie?.titel }}
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Medium" width="12">
        {{ eintrag?.mediumUnterkategorie?.hauptkategorie.titel }} / {{ eintrag?.mediumUnterkategorie?.titel }}
    </oag-info-item-wrapper>
</oag-info-item-row>

<button class="button" [routerLink]="['/eintrag', eintrag.bestand?.id, eintrag.id, 'view', 'eintrag']" (click)="quickView.close()">Ansehen</button>
