import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EintragModel, PolicyModel } from '@ortsarchiv-gemeinlebarn/data';
import { SelectsModel } from '@ortsarchiv-gemeinlebarn/data';
import { EintragApiService } from 'src/app/shared/services/api-endpoints/eintrag-api/eintrag-api.service';
import { FindbuchApiService } from 'src/app/shared/services/api-endpoints/findbuch-api/findbuch-api.service';
import { PolicyApiService } from 'src/app/shared/services/api-endpoints/policy-api/policy-api.service';
import { FrontendService } from 'src/app/shared/services/frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class EintragEditService
{
    public selects: SelectsModel = null;
    public policies: Array<PolicyModel> = [];
    public eintrag: EintragModel = new EintragModel();

    constructor(public findbuchAPI: FindbuchApiService, private eintragAPI: EintragApiService, public router: Router, public frontend: FrontendService, public policyAPI: PolicyApiService) { }

    public load(bestandId: number, eintragId: number)
    {
        this.policyAPI.readAll().subscribe((policies) => this.policies = policies);

        this.findbuchAPI.selects().subscribe((selects) =>
        {
            this.selects = selects;
            this.eintragAPI.getByBestandIdEintragId(bestandId, eintragId).subscribe((eintrag) => this.eintrag = eintrag);
        });
    }

    public createEmpty()
    {
        this.findbuchAPI.selects().subscribe((selects) =>
        {
            this.selects = selects;
            this.eintrag = new EintragModel();
            this.eintrag.bestand = this.selects.bestaende[0];
            this.eintrag.themaUnterkategorie = this.selects.themaUnterkategorien[0];
            this.eintrag.themaHauptkategorie = this.selects.themaUnterkategorien[0].hauptkategorie;
            this.eintrag.themaBereich = this.selects.themaUnterkategorien[0].hauptkategorie.bereich;
            this.eintrag.mediumUnterkategorie = this.selects.mediumUnterkategorien[0];
            this.eintrag.mediumHauptkategorie = this.selects.mediumUnterkategorien[0].hauptkategorie;
        });
    }

    public save()
    {
        if (this.eintrag.titel === undefined || this.eintrag.titel === '')
        {
            this.frontend.showModal('Eintrag unvollständig', 'Der Eintrag muss einen Titel enthalten!');
            return;
        }

        if (this.eintrag.id && this.eintrag.bestand.id)
        {
            this.eintragAPI.update(this.eintrag).subscribe((eintrag) => this.eintrag = eintrag);
        }
        else
        {
            this.eintragAPI.create(this.eintrag).subscribe((eintrag) => this.router.navigateByUrl(`/eintrag/${eintrag.bestand.id}/${eintrag.id}/view/eintrag`));
        }
    }

    public compareIds(a, b)
    {
        if (!a || !b)
        {
            return false;
        }
        return a.id === b.id;
    }

    public compareIdsAndBestandIds(a, b)
    {
        if (!a || !b)
        {
            return false;
        }
        return a.id === b.id && a.bestand.id === b.bestand.id;
    }

    public compareSlugs(a, b)
    {
        if (!a || !b)
        {
            return false;
        }
        return a.slug == b.slug;
    }
}
