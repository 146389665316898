
<oag-content-wrapper>

    <div *ngFor="let letzteEintraegeItem of letzteEintraege">
        <h2 class="datum" (click)="toggleEintraege(letzteEintraegeItem)">{{ letzteEintraegeItem.datum | date: 'dd.MM.yyy' }} ({{ letzteEintraegeItem.eintraege.length }} Einträge)</h2>
      
        <div class="eintrag" *ngFor="let eintrag of letzteEintraegeItem.eintraege" [hidden]="letzteEintraegeItem.hidden">
            <oag-eintrag-id-formatted-prefix slot="top-header" [eintrag]="eintrag"></oag-eintrag-id-formatted-prefix>
            <h3>{{ eintrag.titel }}</h3>
        </div>
    </div>
</oag-content-wrapper>
