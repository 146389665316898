import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'oag-content-wrapper',
    templateUrl: './content-wrapper.component.html',
    styleUrls: ['./content-wrapper.component.scss']
})
export class ContentWrapperComponent implements OnInit
{
    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
