import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AnwendungsinformationenPageComponent } from './pages/anwendungsinformationen-page/anwendungsinformationen-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { TaskLoggingPageComponent } from './pages/task-logging-page/task-logging-page.component';
import { UserPageComponent } from './pages/user-page/user-page.component';

@NgModule({
    declarations: [
        LoginPageComponent,
        ProfilePageComponent,
        UserPageComponent,
        TaskLoggingPageComponent,
        AnwendungsinformationenPageComponent
    ],
    exports: [
        LoginPageComponent,
        ProfilePageComponent,
        UserPageComponent,
        TaskLoggingPageComponent,
        AnwendungsinformationenPageComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterModule
    ]
})
export class UserModule { }
