<oag-content-wrapper class="list">

    <header class="headline-button-container">
        <h1>Personen</h1>
        <button class="button button--medium" (click)="edit()">Neue Person</button>
    </header>
    <table summary="Personen">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Vorname</th>
                <th scope="col">Nachname</th>
                <th scope="col">Suffix</th>
                <th scope="col">Spitzname</th>
                <th scope="col">Geburtsjahr</th>
                <th scope="col">Sterbejahr</th>
                <th scope="col">Notizen</th>
                <th scope="col">Suche Freigabe</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let person of personen">
                <td>{{ person.id }}</td>
                <td>{{ person.vorname }}</td>
                <td>{{ person.nachname }}</td>
                <td>{{ person.suffix }}</td>
                <td>{{ person.spitzname }}</td>
                <td>{{ person.geburtsjahr }}</td>
                <td>{{ person.sterbejahr }}</td>
                <td>{{ person.notizen }}</td>
                <td>{{ person.sucheFreigabe | booleanAsText }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="edit(person)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>

</oag-content-wrapper>

<div class="edit" *ngIf="personEdit">
    <header class="headline-button-container">
        <h3>{{ personEdit.vorname }} {{ personEdit.nachname }} {{ personEdit.suffix }}</h3>
        <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
    </header>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Vorname" width="6">
            <input type="text" [(ngModel)]="personEdit.vorname">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Nachname" width="6">
            <input type="text" [(ngModel)]="personEdit.nachname">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Suffix" width="6">
            <input type="text" [(ngModel)]="personEdit.suffix">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Spitzname" width="6">
            <input type="text" [(ngModel)]="personEdit.spitzname">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Geburtsjahr" width="6">
            <input type="number" [(ngModel)]="personEdit.geburtsjahr">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Sterbejahr" width="6">
            <input type="number" [(ngModel)]="personEdit.sterbejahr">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Notizen" width="12">
            <textarea [(ngModel)]="personEdit.notizen"></textarea>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Suche Freigabe" width="12">
            <select [(ngModel)]="personEdit.sucheFreigabe">
                <option [ngValue]="true">Ja</option>
                <option [ngValue]="false">Nein</option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <div class="edit-button-container">
        <button class="button" *ngIf="personEdit.id" (click)="update()">Speichern</button>
        <button class="button" *ngIf="!personEdit.id" (click)="create()">Erstellen</button>
        <button class="button button--outline" *ngIf="personEdit.id" (click)="delete()">Löschen</button>
    </div>
</div>
