import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { KastenModel } from '@ortsarchiv-gemeinlebarn/data';
import { ApiService } from '../../api/api.service';
import { FrontendService } from '../../frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class KastenApiService
{
    constructor(public http: HttpClient, public api: ApiService, public frontend: FrontendService) { }

    public create(kasten: KastenModel): Observable<KastenModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .post(`${this.api.url}/kaesten`, kasten, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public readAll(): Observable<Array<KastenModel>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/kaesten`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public update(kasten: KastenModel): Observable<KastenModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .put(`${this.api.url}/kaesten/${kasten.id}`, kasten, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public delete(id: number)
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .delete(`${this.api.url}/kaesten/${id}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
