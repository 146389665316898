import { Component, OnInit } from '@angular/core';
import { ExternerBesitzerModel } from '@ortsarchiv-gemeinlebarn/data';
import { ExternerBesitzerApiService } from 'src/app/shared/services/api-endpoints/externer-besitzer-api/externer-besitzer-api.service';

@Component({
    selector: 'oag-externe-besitzer-page',
    templateUrl: './externe-besitzer-page.component.html',
    styleUrls: ['./externe-besitzer-page.component.scss']
})
export class ExterneBesitzerPageComponent implements OnInit
{
    public externeBesitzer: Array<ExternerBesitzerModel> = [];
    public externerBesitzerEdit: ExternerBesitzerModel = null;

    constructor(public externerBesitzerAPI: ExternerBesitzerApiService) { }

    public ngOnInit()
    {
        this.loadExterneBesitzer();
    }

    public resetEdit()
    {
        this.externerBesitzerEdit = null;
    }

    private loadExterneBesitzer()
    {
        this.resetEdit();
        this.externerBesitzerAPI.readAll().subscribe((externeBesitzer) => this.externeBesitzer = externeBesitzer);
    }

    public edit(externerBesitzer: ExternerBesitzerModel = null)
    {
        if (externerBesitzer === null)
        {
            externerBesitzer = new ExternerBesitzerModel();
        }

        this.resetEdit();
        this.externerBesitzerEdit = externerBesitzer;
    }

    public update = () => this.externerBesitzerAPI.update(this.externerBesitzerEdit).subscribe(() => this.loadExterneBesitzer());
    public create = () => this.externerBesitzerAPI.create(this.externerBesitzerEdit).subscribe(() => this.loadExterneBesitzer());
    public delete = () => this.externerBesitzerAPI.delete(this.externerBesitzerEdit.id).subscribe(() => this.loadExterneBesitzer());

    public compareIds = (a, b) => (!a || !b) ? false : a.id === b.id;
}
