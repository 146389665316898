import { Component, OnInit } from '@angular/core';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';
import { ApplikationService } from 'src/app/shared/services/applikation/applikation.service';

@Component({
    selector: 'oag-eintrag-detail-edit-eintrag',
    templateUrl: './eintrag-detail-edit-eintrag.component.html',
    styleUrls: ['./eintrag-detail-edit-eintrag.component.scss']
})
export class EintragDetailEditEintragComponent implements OnInit
{
    constructor(public eintragEdit: EintragEditService, public applikation: ApplikationService) { }
    public ngOnInit() { } // NOSONAR 

    public themaBereichChanged()
    {
        this.eintragEdit.eintrag.themaHauptkategorie = this.eintragEdit.selects.themaHauptkategorien.find((kategorie) => kategorie.bereich.id === this.eintragEdit.eintrag.themaBereich.id);
        this.themaHauptkategorieChanged();
    }

    public themaHauptkategorieChanged()
    {
        this.eintragEdit.eintrag.themaUnterkategorie = this.eintragEdit.selects.themaUnterkategorien.find((kategorie) => kategorie.hauptkategorie.id === this.eintragEdit.eintrag.themaHauptkategorie.id);
    }

    public mediumHauptkategorieChanged()
    {
        this.eintragEdit.eintrag.mediumUnterkategorie = this.eintragEdit.selects.mediumUnterkategorien.find((kategorie) => kategorie.hauptkategorie.id === this.eintragEdit.eintrag.mediumHauptkategorie.id);
    }
}
