import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectsModel } from '@ortsarchiv-gemeinlebarn/data';
import { FrontendService } from 'src/app/shared/services/frontend/frontend.service';
import { QuickViewService } from 'src/app/shared/services/quick-view/quick-view.service';
import { TimelineService } from '../../services/timeline/timeline.service';

@Component({
    selector: 'oag-zeittafel-page',
    templateUrl: './zeittafel-page.component.html',
    styleUrls: ['./zeittafel-page.component.scss']
})
export class ZeittafelPageComponent implements OnInit
{
    public selects: SelectsModel = null;

    constructor(public timeline: TimelineService, public quickView: QuickViewService, public route: ActivatedRoute, public frontend: FrontendService)
    {
        this.route.queryParams.subscribe((params) =>
        {
            if (params.ereignisId)
            {
                this.frontend.startLoadingIndicator();
                this.waitUntilTimelineAvailable(3000).then(() =>
                {
                    this.frontend.stopLoadingIndicator();
                    this.scrolltoEreignis(params.ereignisId);
                });
            }
        });
    }
    public ngOnInit() { }

    private waitUntilTimelineAvailable(maxWait: number): Promise<any>
    {
        const pollSize = 200;
        let maxCount = 0;

        return new Promise(async (resolve) =>
        {
            while (maxCount < maxWait)
            {
                if (this.timeline.timeline)
                {
                    resolve(true);
                    break;
                }
                else
                {
                    maxCount += pollSize;
                    await new Promise(((resolveWait) => setTimeout(() => resolveWait(), pollSize)));
                }
            }

            resolve(false);
        });
    }

    public scrolltoCentury(jahrhundert)
    {
        this.scrollToTarget('century_' + jahrhundert);
    }

    public scrolltoEreignis(ereignisId: number)
    {
        setTimeout(() =>
        {
            const offsetElement: any = document.getElementsByClassName('century')[0];
            this.scrollToTarget('ereignis_' + ereignisId, offsetElement.offsetHeight);
        }, 100);
    }

    public scrollToTarget(nativeId: string, offset: number = 0)
    {
        const scrollWrapper = document.getElementById('scroll');
        const targetElement = document.getElementById(nativeId);
        scrollWrapper.scroll({ top: (targetElement.offsetTop - offset), left: 0, behavior: 'smooth' });
    }
}
