import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { version } from 'src/info/version';
import { AuthService } from './shared/services/auth/auth.service';
import { FrontendService } from './shared/services/frontend/frontend.service';
import { MediaListService } from './shared/services/media-list/media-list.service';

@Component({
    selector: 'oag-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent
{
    public env = environment;
    public version = version;

    constructor(public mediaList: MediaListService, public frontend: FrontendService, public auth: AuthService) { }
}
