
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { ItemDigitalModel } from '@ortsarchiv-gemeinlebarn/data';
import { ApiService } from '../../api/api.service';
import { FrontendService } from '../../frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class ItemDigitalApiService
{
    constructor(private http: HttpClient, private api: ApiService, public frontend: FrontendService) { }

    public readSilent(id: number): Observable<ItemDigitalModel>
    {
        this.frontend.startLoadingIndicator(false);
        return this.http
            .get(`${this.api.url}/items/digital/${id}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public read(id: number): Observable<ItemDigitalModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/items/digital/${id}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public getItemsOfEintrag(bestandId: number, eintragId: number): Observable<any>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/bestaende/${bestandId}/eintraege/${eintragId}/items/digital`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
