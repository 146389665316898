<oag-content-wrapper class="list">

    <h1>Medien</h1>

    <header class="headline-button-container">
        <h2>Hauptkategorien</h2>
        <button class="button button--medium" (click)="editHauptkategorie()">Neue Hauptkategorie</button>
    </header>
    <table summary="Medien Hauptkategorien">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Titel</th>
                <th scope="col">SortIndex</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let hauptkategorie of mediumHauptkategorien">
                <td>{{ hauptkategorie.id }}</td>
                <td>{{ hauptkategorie.titel }}</td>
                <td>{{ hauptkategorie.sortIndex }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="editHauptkategorie(hauptkategorie)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>

    <header class="headline-button-container">
        <h2>Unterkategorien</h2>
        <button class="button button--medium" (click)="editUnterkategorie()">Neue Unterkategorie</button>
    </header>
    <table summary="Medien Unterkategorien">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Titel</th>
                <th scope="col">Topothek Kategoriepfad</th>
                <th scope="col">Hauptkategorie</th>
                <th scope="col">SortIndex</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let unterkategorie of mediumUnterkategorien">
                <td>{{ unterkategorie.id }}</td>
                <td>{{ unterkategorie.titel }}</td>
                <td>{{ unterkategorie.topothekKategoriepfad }}</td>
                <td>{{ unterkategorie.hauptkategorie.titel }}</td>
                <td>{{ unterkategorie.sortIndex }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="editUnterkategorie(unterkategorie)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>
</oag-content-wrapper>

<div class="edit" *ngIf="mediumHauptkategorieEdit || mediumUnterkategorieEdit">
    <div *ngIf="mediumHauptkategorieEdit">
        <header class="headline-button-container">
            <h3>Hauptkategorie</h3>
            <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
        </header>
        <oag-info-item-row>
            <oag-info-item-wrapper width="9" titel="Titel">
                <input type="text" [(ngModel)]="mediumHauptkategorieEdit.titel">
            </oag-info-item-wrapper>
            <oag-info-item-wrapper width="3" titel="SortIndex">
                <input type="number" [(ngModel)]="mediumHauptkategorieEdit.sortIndex">
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <div class="edit-button-container">
            <button class="button" *ngIf="mediumHauptkategorieEdit.id" (click)="updateEditMediumHauptkategorie()">Speichern</button>
            <button class="button" *ngIf="!mediumHauptkategorieEdit.id" (click)="createEditMediumHauptkategorie()">Erstellen</button>
            <button class="button button--outline" *ngIf="mediumHauptkategorieEdit.id" (click)="deleteEditMediumHauptkategorie()">Löschen</button>
        </div>
    </div>

    <div *ngIf="mediumUnterkategorieEdit">
        <header class="headline-button-container">
            <h3>Unterkategorie</h3>
            <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
        </header>
        <oag-info-item-row>
            <oag-info-item-wrapper width="12" titel="Titel">
                <input type="text" [(ngModel)]="mediumUnterkategorieEdit.titel">
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <oag-info-item-row>
            <oag-info-item-wrapper width="12" titel="Hauptkategorie">
                <select [(ngModel)]="mediumUnterkategorieEdit.hauptkategorie" [compareWith]="compareIds">
                    <option *ngFor="let mediumHauptkategorie of mediumHauptkategorien" [ngValue]="mediumHauptkategorie">{{ mediumHauptkategorie.titel }}</option>
                </select>
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <oag-info-item-row>
            <oag-info-item-wrapper width="6" titel="Topothek Kategoriepfad">
                <input type="text" [(ngModel)]="mediumUnterkategorieEdit.topothekKategoriepfad">
            </oag-info-item-wrapper>
            <oag-info-item-wrapper width="6" titel="SortIndex">
                <input type="number" [(ngModel)]="mediumUnterkategorieEdit.sortIndex">
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <div class="edit-button-container">
            <button class="button" *ngIf="mediumUnterkategorieEdit.id" (click)="updateEditMediumUnterkategorie()">Speichern</button>
            <button class="button" *ngIf="!mediumUnterkategorieEdit.id" (click)="createEditMediumUnterkategorie()">Erstellen</button>
            <button class="button button--outline" *ngIf="mediumUnterkategorieEdit.id" (click)="deleteEditMediumUnterkategorie()">Löschen</button>
        </div>
    </div>
</div>
