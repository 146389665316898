import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EintragListModel, EintragModel, PersonModel } from '@ortsarchiv-gemeinlebarn/data';
import { QuickViewService } from '../../services/quick-view/quick-view.service';

@Component({
    selector: 'oag-label-wrapper',
    templateUrl: './label-wrapper.component.html',
    styleUrls: ['./label-wrapper.component.scss']
})
export class LabelWrapperComponent implements OnInit
{
    @Input() public titel = '';
    @Input() public quickViewPerson: PersonModel = null;
    @Input() public quickViewEintrag: EintragListModel | EintragModel = null;
    @Output() public remove = new EventEmitter<void>();

    constructor(public quickView: QuickViewService) { }
    public ngOnInit() { } // NOSONAR

    public openQuickView()
    {
        if (this.quickViewPerson)
        {
            this.quickView.showPerson(this.quickViewPerson);
        }

        if (this.quickViewEintrag)
        {
            this.quickView.showEintrag(this.quickViewEintrag);
        }
    }

    public removeClicked()
    {
        this.remove.emit();
    }
}
