import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EintragListModel } from '@ortsarchiv-gemeinlebarn/data';
import { EintragApiService } from '../../services/api-endpoints/eintrag-api/eintrag-api.service';

@Component({
    selector: 'oag-multiple-select-eintrag',
    templateUrl: './multiple-select-eintrag.component.html',
    styleUrls: ['./multiple-select-eintrag.component.scss']
})
export class MultipleSelectEintragComponent implements OnInit
{
    public opend = false;
    public searchTerm = '';
    public pool: Array<EintragListModel> = [];

    @Input() public choosen: Array<EintragListModel> = [];
    @Output() public choosenChange: EventEmitter<EintragListModel[]> = new EventEmitter<EintragListModel[]>();

    constructor(public eintragAPI: EintragApiService)
    {
        this.eintragAPI.readAllMinimal().subscribe((eintraege) => this.pool = eintraege);
    }
    public ngOnInit() { }

    public getFiltredPool()
    {
        return this.pool.filter((eintrag) => eintrag.idFormattedPrefix.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1 || this.searchTerm === '') || [];
    }

    public isInChoosen(eintrag)
    {
        return this.choosen.findIndex((obj) => obj.id === eintrag.id) !== -1;
    }

    /* Open & Close */

    public openSelection()
    {
        this.opend = true;
    }

    public closeSelection()
    {
        setTimeout(() => this.opend = false, 200);
    }

    /* Add & Remove */

    public add(eintrag)
    {
        if (!this.isInChoosen(eintrag))
        {
            this.choosen.push(eintrag);
        }
        this.searchTerm = '';
        this.choosenChange.emit(this.choosen);
    }

    public remove(eintrag)
    {
        this.choosen = this.choosen.filter((obj) => obj.id !== eintrag.id);
        this.choosenChange.emit(this.choosen);
    }
}
