import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { BestandModel } from '@ortsarchiv-gemeinlebarn/data';
import { ApiService } from '../../api/api.service';
import { FrontendService } from '../../frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class BestandApiService
{
    constructor(public http: HttpClient, public api: ApiService, public frontend: FrontendService) { }

    public readAll(): Observable<Array<BestandModel>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/bestaende`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
