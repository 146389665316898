<div class="main">
    <div class="main__content">
        <div class="content">
            <div class="content__media media">
                <div class="media__previous" (click)="mediaList.previousPage()" [hidden]="!mediaList.currentItemDigitalPageHasPreviousPage">
                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Arrow Back</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M244 400L100 256l144-144M120 256h292'/></svg>
                </div>
                <oag-seadragon [url]="mediaList.currentItemDigitalPage?.urlLarge" #seadragon></oag-seadragon>
                <div class="media__next" (click)="mediaList.nextPage()" [hidden]="!mediaList.currentItemDigitalPageHasNextPage">
                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Arrow Forward</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M268 112l144 144-144 144M392 256H100'/></svg>
                </div>

                <div class="media__openPageSelect button" (click)="mediaList.openPageSelect()" [hidden]="mediaList.displayPageSelect || mediaList.currentItemDigital?.pages.length == 1">
                    Seitenauswahl öffnen
                </div>
            </div>
            <div class="content__info">
                <button class="button button--icon-only close" (click)="mediaList.close()">
                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Close</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M368 368L144 144M368 144L144 368'/></svg>
                </button>
                <h3 class="info__titel">{{ mediaList.currentItemDigital?.eintrag.titel }}</h3>
                <oag-eintrag-id-formatted-prefix [eintrag]="mediaList.currentItemDigital?.eintrag" size="22px"></oag-eintrag-id-formatted-prefix>
                <oag-info-item-row>
                    <oag-info-item-wrapper width="12" titel="Datierung">{{ mediaList.currentItemDigital?.eintrag?.datierung.text }}</oag-info-item-wrapper>
                </oag-info-item-row>
                <oag-info-item-row>
                    <oag-info-item-wrapper width="12" titel="Thema">{{ mediaList.currentItemDigital?.eintrag.themaUnterkategorie.hauptkategorie.bereich.titel }} / {{ mediaList.currentItemDigital?.eintrag.themaUnterkategorie.hauptkategorie.titel }} / {{ mediaList.currentItemDigital?.eintrag.themaUnterkategorie.titel }}</oag-info-item-wrapper>
                </oag-info-item-row>
               
                <h3>Digitalisat</h3>
                <oag-info-item-row>
                    <oag-info-item-wrapper width="6" titel="Seitenanzahl">{{ mediaList.currentItemDigital?.pages.length }}</oag-info-item-wrapper>
                    <oag-info-item-wrapper width="6" titel="Datum der Digitalisierung">{{ mediaList.currentItemDigital?.datum | date: 'dd.MM.yyyy' }}</oag-info-item-wrapper>
                </oag-info-item-row>

                <h3>Aktuelle Seite</h3>
                <oag-info-item-row>
                    <oag-info-item-wrapper width="6" titel="Seite Nr.">Seite {{ mediaList.currentItemDigitalPage?.number }} von {{ mediaList.currentItemDigital?.pages.length }}</oag-info-item-wrapper>
                    <oag-info-item-wrapper width="6" titel="Auflösung">{{ mediaList.currentItemDigitalPage?.aufloesungX }} / {{ mediaList.currentItemDigitalPage?.aufloesungY }} DPI</oag-info-item-wrapper>
                </oag-info-item-row>
            </div>

            <div class="pages-wrapper" [hidden]="!mediaList.displayPageSelect">
                <div class="pages">
                    <div class="page" (click)="this.mediaList.switchToPageNumber(page.number)" *ngFor="let page of mediaList.currentItemDigital?.pages" [ngClass]="{'current': mediaList.currentItemDigitalPage.number == page.number}">
                        <img [src]="page.urlThumbnail" (load)="page.loaded = true" [alt]="'Seite ' + page.number" [ngStyle]="{width: 160/page.aspectRatio  + 'px', height: '160px'}">
                        <div class="page__number">{{ page.number }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="items-wrapper" *ngIf="mediaList.items.length">
    <div class="items">
        <div class="item" *ngFor="let item of mediaList.items; let index = index" [ngClass]="{'current': mediaList.currentItemDigitalIndex == index}" (click)="this.mediaList.openItemDigitalById(item.id, this.mediaList.items)">
            <span>{{ item.eintrag.idFormattedPrefix }}</span>
            <strong>{{ item.idFormattedPrefix }}</strong>
        </div>
    </div>
</div>