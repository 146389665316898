import { Injectable } from '@angular/core';
import { EintragListModel, EreignisModel, PersonModel } from '@ortsarchiv-gemeinlebarn/data';

@Injectable({
    providedIn: 'root'
})
export class QuickViewService
{
    public opend = false;

    public personEdit = false;

    public eintrag: EintragListModel = null;
    public person: PersonModel = null;
    public ereignis: EreignisModel = null;

    constructor() { }

    public open()
    {
        this.opend = true;
    }

    public close()
    {
        this.opend = false;
    }

    public showPerson(person: PersonModel)
    {
        this.reset();
        this.open();
        this.person = person;
    }

    public editPerson()
    {
        if (!this.person)
            throw new Error('Es wurde keine Person zum Bearbeiten in Quick View geladen!');

        this.personEdit = true;
        this.open();
    }

    public showEintrag(eintrag: EintragListModel)
    {
        this.reset();
        this.open();
        this.eintrag = eintrag;
    }

    private reset()
    {
        this.personEdit = false;

        this.person = null;
        this.eintrag = null;
        this.ereignis = null;
    }
}
