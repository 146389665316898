import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { FrontendService } from '../frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService
{
    public url: string = null;
    public headers: HttpHeaders = null;

    constructor(public frontend: FrontendService, private auth: AuthService)
    {
        this.url = environment.api.url;
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }

    public errorHandler(error: Error | any): Observable<any>
    {
        const headline = `Es trat ein Fehler in der Kommunikation mit der OAG-API auf!`;

        this.frontend.stopLoadingIndicator();
        this.frontend.showModal(headline, error, false, 'http-error');
        return throwError(error);
    }

}
