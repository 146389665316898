import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate
{
    constructor(private auth: AuthService, private router: Router) { }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean | UrlTree> | boolean
    {
        return this.auth.isAuthenticated$.pipe(
            delay(300),
            map((letThroughToLoginPage) =>
            {
                if (letThroughToLoginPage)
                {
                    this.router.navigateByUrl('/search');
                    return false;
                }

                return true;
            })
        );
    }

}
