<ng-container *ngIf="timeline.ereignis">

    <h1>Ereignis bearbeiten</h1>
    <h2>Titel und Datierung</h2>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Titel" width="8">
            <input type="text" [(ngModel)]="timeline.ereignis.titel">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Datierung Text" width="4">
            <input type="text" [(ngModel)]="timeline.ereignis.datierung.text">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Von" width="2">
            <select [(ngModel)]="timeline.ereignis.datierung.von.annoDomini">
                <option [ngValue]="true">Ja</option>
                <option [ngValue]="false">Nein</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Von" width="4">
            <input type="date" [(ngModel)]="timeline.ereignis.datierung.von.date">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Bis" width="2">
            <select [(ngModel)]="timeline.ereignis.datierung.bis.annoDomini">
                <option [ngValue]="true">Ja</option>
                <option [ngValue]="false">Nein</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Bis" width="4">
            <input type="date" [(ngModel)]="timeline.ereignis.datierung.bis.date">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <h2>Beschreibung & Quellen</h2>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Beschreibung" width="6">
            <textarea [(ngModel)]="timeline.ereignis.beschreibung"></textarea>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Quellen" width="6">
            <textarea [(ngModel)]="timeline.ereignis.quellen"></textarea>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <h2>Thema</h2>
    <oag-info-item-row>
        <oag-info-item-wrapper width="4" titel="Bereich">
            <select [(ngModel)]="timeline.ereignis.themaBereich" [compareWith]="compareIds" (change)="themaBereichChanged()">
                <option
                    *ngFor="let bereich of selects?.themaBereiche"
                    [ngValue]="bereich">{{ bereich.titel }}</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Hauptkategorie">
            <select [(ngModel)]="timeline.ereignis.themaHauptkategorie" [compareWith]="compareIds" (change)="themaHauptkategorieChanged()">
                <option
                    *ngFor="let hauptkategorie of selects?.themaHauptkategorien"
                    [hidden]="hauptkategorie.bereich.id !== timeline.ereignis.themaBereich.id"
                    [ngValue]="hauptkategorie">{{ hauptkategorie.titel }}</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Unterkategorie">
            <select [(ngModel)]="timeline.ereignis.themaUnterkategorie" [compareWith]="compareIds">
                <option
                    *ngFor="let unterkategorie of selects?.themaUnterkategorien"
                    [hidden]="unterkategorie.hauptkategorie.id != timeline.ereignis.themaUnterkategorie.hauptkategorie.id"
                    [ngValue]="unterkategorie">{{ unterkategorie.titel }}</option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <h2>Kontext & Priorität</h2>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Kontextualisierung" width="6">
            <select [(ngModel)]="timeline.ereignis.kontextualisierung" [compareWith]="compareIds">
                <option
                    *ngFor="let kontextualisierung of selects?.kontextualisierungen"
                    [ngValue]="kontextualisierung">{{ kontextualisierung?.name }}</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Priorisierung" width="6">
            <select [(ngModel)]="timeline.ereignis.priorisierung" [compareWith]="compareIds">
                <option
                    *ngFor="let priorisierung of selects?.priorisierungen"
                    [ngValue]="priorisierung">{{ priorisierung?.name }}</option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <h2>Verknüpfte Personen</h2>
    <oag-info-item-row>
        <oag-multiple-select-person [(choosen)]="timeline.ereignis.personen"></oag-multiple-select-person>
    </oag-info-item-row>
    <h2>Verknüpfte Einträge</h2>
    <oag-info-item-row>
        <oag-multiple-select-eintrag [(choosen)]="timeline.ereignis.eintraege"></oag-multiple-select-eintrag>
    </oag-info-item-row>

    <div class="button-line">
        <button class="button" (click)="save()">Speichern</button>
        <button class="button button--light" (click)="timeline.clearEreignisEditing()">Abbrechen</button>
    </div>

</ng-container>
