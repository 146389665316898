import { Component, OnInit } from '@angular/core';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';
import { EreignisModel } from '@ortsarchiv-gemeinlebarn/data';
import { SerieModel } from '@ortsarchiv-gemeinlebarn/data';
import { EreignisApiService } from 'src/app/shared/services/api-endpoints/ereignis-api/ereignis-api.service';
import { SerieApiService } from 'src/app/shared/services/api-endpoints/serie-api/serie-api.service';

@Component({
    selector: 'oag-eintrag-detail-edit-ereignisse',
    templateUrl: './eintrag-detail-edit-ereignisse.component.html',
    styleUrls: ['./eintrag-detail-edit-ereignisse.component.scss']
})
export class EintragDetailEditEreignisseComponent implements OnInit
{
    public ereignisse: Array<EreignisModel> = [];
    public serien: Array<SerieModel> = [];

    constructor(public eintragEdit: EintragEditService, public ereignisAPI: EreignisApiService, public serieAPI: SerieApiService) { }

    public ngOnInit()
    {
        this.ereignisAPI.readAll().subscribe((ereignisse) => this.ereignisse = ereignisse);
        this.serieAPI.readAll().subscribe((serien) => this.serien = serien);
    }
}
