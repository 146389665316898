import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { FrontendService } from 'src/app/shared/services/frontend/frontend.service';
import { ApiService } from '../../api/api.service';
@Injectable({
    providedIn: 'root'
})
export class TimelineApiService
{
    constructor(private http: HttpClient, private api: ApiService, public frontend: FrontendService) { }

    public getFullTimeline(): Observable<Array<any>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/timeline`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
