import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-meta-information-item',
    templateUrl: './meta-information-item.component.html',
    styleUrls: ['./meta-information-item.component.scss']
})
export class MetaInformationItemComponent implements OnInit
{
    @Input() public key = '';
    @Input() public value = '';

    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
