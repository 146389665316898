<h4 *ngIf="!item?.id">Neues Item Extern</h4>
<h4 *ngIf="item?.id">{{ item?.idFormattedPrefix }}</h4>
<oag-info-item-row>
    <oag-info-item-wrapper width="3" titel="Originalität">
        <select [(ngModel)]="item.originalitaet" [compareWith]="eintragEdit.compareIds">
            <option *ngFor="let originalitaet of eintragEdit.selects?.originalitaeten" [ngValue]="originalitaet">{{ originalitaet.titel }}</option>
        </select>
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Farbraum">
        <select [(ngModel)]="item.farbraum" [compareWith]="eintragEdit.compareIds">
            <option *ngFor="let farbraum of eintragEdit.selects?.farbraeume" [ngValue]="farbraum">{{ farbraum.titel }}</option>
        </select>
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Größe">
        <input type="text" [(ngModel)]="item.groesse">
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="3" titel="Beschreibung">
        <input type="text" [(ngModel)]="item.beschreibung">
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper width="6" titel="Externer Besitzer">
        <select [(ngModel)]="item.externerBesitzer" [compareWith]="eintragEdit.compareIds">
            <option *ngFor="let externerBesitzer of eintragEdit.selects?.externeBesitzer" [ngValue]="externerBesitzer">
                {{ externerBesitzer.type === 'Institution' ? externerBesitzer.name : 'Privat'}} ({{ externerBesitzer.vorname }} {{ externerBesitzer.nachname }})
            </option>
        </select>
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="6" titel="Externe Signatur">
        <input type="text" [(ngModel)]="item.externeSignatur">
    </oag-info-item-wrapper>
</oag-info-item-row>
<ng-content></ng-content>
