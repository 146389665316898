import { Component, OnInit } from '@angular/core';
import { FrontendService } from '../../services/frontend/frontend.service';

@Component({
    selector: 'oag-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit
{
    constructor(public frontend: FrontendService) { }
    public ngOnInit() { } // NOSONAR
}
