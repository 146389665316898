import { Component, OnInit } from '@angular/core';
import { BehaeltnisModel } from '@ortsarchiv-gemeinlebarn/data';
import { BestandModel } from '@ortsarchiv-gemeinlebarn/data';
import { FachModel } from '@ortsarchiv-gemeinlebarn/data';
import { BehaeltnisApiService } from 'src/app/shared/services/api-endpoints/behaeltnis-api/behaeltnis-api.service';
import { BestandApiService } from 'src/app/shared/services/api-endpoints/bestand-api/bestand-api.service';
import { FachApiService } from 'src/app/shared/services/api-endpoints/fach-api/fach-api.service';

@Component({
    selector: 'oag-behaeltnisse-page',
    templateUrl: './behaeltnisse-page.component.html',
    styleUrls: ['./behaeltnisse-page.component.scss']
})
export class BehaeltnissePageComponent implements OnInit
{
    public faecher: Array<FachModel> = [];
    public bestaende: Array<BestandModel> = [];
    public behaeltnisse: Array<BehaeltnisModel> = [];
    public behaeltnisEdit: BehaeltnisModel = null;

    constructor(public bestandAPI: BestandApiService, public behaeltnisAPI: BehaeltnisApiService, public fachAPI: FachApiService) { }

    public ngOnInit()
    {
        this.bestandAPI.readAll().subscribe((bestaende) => this.bestaende = bestaende);
        this.fachAPI.readAll().subscribe((faecher) => this.faecher = faecher);
        this.loadBehaeltnisse();
    }

    public resetEdit()
    {
        this.behaeltnisEdit = null;
    }

    private loadBehaeltnisse()
    {
        this.resetEdit();
        this.behaeltnisAPI.readAll().subscribe((behaeltnisse) => this.behaeltnisse = behaeltnisse);
    }

    public edit(behaeltnis: BehaeltnisModel = null)
    {
        if (behaeltnis === null)
        {
            behaeltnis = new BehaeltnisModel();
            behaeltnis.bestand = this.bestaende[0];
            behaeltnis.fach = this.faecher[0];
        }

        this.resetEdit();
        this.behaeltnisEdit = behaeltnis;
    }

    public update = () => this.behaeltnisAPI.update(this.behaeltnisEdit).subscribe(() => this.loadBehaeltnisse());
    public create = () => this.behaeltnisAPI.create(this.behaeltnisEdit).subscribe(() => this.loadBehaeltnisse());
    public delete = () => this.behaeltnisAPI.delete(this.behaeltnisEdit.bestand.id, this.behaeltnisEdit.id).subscribe(() => this.loadBehaeltnisse());

    public compareIds = (a, b) => (!a || !b) ? false : a.id === b.id;
}
