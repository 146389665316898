import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { ThemenPageComponent } from './pages/themen-page/themen-page.component';
import { MedienPageComponent } from './pages/medien-page/medien-page.component';
import { PersonenPageComponent } from './pages/personen-page/personen-page.component';
import { TagsPageComponent } from './pages/tags-page/tags-page.component';
import { ExterneBesitzerPageComponent } from './pages/externe-besitzer-page/externe-besitzer-page.component';
import { BehaeltnissePageComponent } from './pages/behaeltnisse-page/behaeltnisse-page.component';
import { KaestenPageComponent } from './pages/kaesten-page/kaesten-page.component';
import { FaecherPageComponent } from './pages/faecher-page/faecher-page.component';

@NgModule({
    declarations: [SettingsPageComponent, ThemenPageComponent, MedienPageComponent, PersonenPageComponent, TagsPageComponent, ExterneBesitzerPageComponent, BehaeltnissePageComponent, KaestenPageComponent, FaecherPageComponent],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        FormsModule
    ]
})
export class SettingsModule { }
