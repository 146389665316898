<oag-page-wrapper>
    <oag-sub-menu-wrapper>
        <oag-sub-menu-item titel="Themen" link="themen" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Medien" link="medien" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Personen" link="personen" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Tags" link="tags" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Externe Besitzer" link="externe-besitzer" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Behältnisse" link="behaeltnisse" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Fächer" link="faecher" slot="right"></oag-sub-menu-item>
        <oag-sub-menu-item titel="Kästen" link="kaesten" slot="right"></oag-sub-menu-item>
    </oag-sub-menu-wrapper>
    <router-outlet></router-outlet>
</oag-page-wrapper>
