<oag-content-wrapper>

    <button class="button button--top" (click)="schaufenster.newVisible = !schaufenster.newVisible">
        <span *ngIf="!schaufenster.newVisible">Neus Objekt anlegen</span>
        <span *ngIf="schaufenster.newVisible">Abbrechen</span>
    </button>

    <div class="new" *ngIf="schaufenster.newVisible">

        <div class="jump-search">
            <input type="text" placeholder="Jump Search" [(ngModel)]="search" (keyup.enter)="searchForNew()">
            <button class="button" (click)="searchForNew()">Search Eintrag</button>
        </div>
        
        <div class="eintrag" *ngIf="schaufenster.new.eintrag">
            <h2>{{ schaufenster.new.eintrag?.titel }}</h2>
            <p>{{ schaufenster.new.eintrag?.datierung.text }}</p>
        </div>

        <oag-info-item-row>
            <oag-info-item-wrapper width="4" titel="Beginn">
                <input type="datetime-local" [(ngModel)]="schaufenster.new.beginn">
            </oag-info-item-wrapper>
            <oag-info-item-wrapper width="4" titel="Ende">
                <input type="datetime-local" [(ngModel)]="schaufenster.new.ende">
            </oag-info-item-wrapper>
            <oag-info-item-wrapper width="4" titel="Anmerkung">
                <input type="text" [(ngModel)]="schaufenster.new.anmerkung">
            </oag-info-item-wrapper>
        </oag-info-item-row>
        
        <div class="item" *ngFor="let item of schaufenster.new.availableItems; let itemIndex = index">
            <h3>{{ item.idFormattedPrefix }}</h3>
            <div class="pages">
                <div class="page" *ngFor="let page of schaufenster.new.availablePages[itemIndex]; let pageIndex = index" (click)="schaufenster.togglePage(itemIndex, pageIndex)" [ngClass]="{'active': page}">{{ pageIndex + 1 }}</div>
            </div>
        </div>
        <button class="button button--create" (click)="schaufenster.create()">Objekt Anlegen</button>
    </div>

    <oag-schaufenster-objekt *ngFor="let objekt of schaufenster.objekte" [objekt]="objekt"></oag-schaufenster-objekt>
</oag-content-wrapper>
