import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { EreignisEditComponent } from './components/ereignis-edit/ereignis-edit.component';
import { EreignisComponent } from './components/ereignis/ereignis.component';
import { TimelineCenturyComponent } from './components/timeline-century/timeline-century.component';
import { TimelineDecadeComponent } from './components/timeline-decade/timeline-decade.component';
import { TimelineEreignisComponent } from './components/timeline-ereignis/timeline-ereignis.component';
import { TimelineItemWrapperComponent } from './components/timeline-item-wrapper/timeline-item-wrapper.component';
import { TimelineYearComponent } from './components/timeline-year/timeline-year.component';
import { ZeittafelPageComponent } from './pages/zeittafel-page/zeittafel-page.component';

@NgModule({
    declarations: [
        ZeittafelPageComponent,
        TimelineDecadeComponent,
        TimelineCenturyComponent,
        TimelineYearComponent,
        TimelineEreignisComponent,
        TimelineItemWrapperComponent,
        EreignisComponent,
        EreignisEditComponent
    ],
    exports: [
        ZeittafelPageComponent,
        EreignisComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule
    ]
})
export class ZeittafelModule { }
