import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'oag-info-item-row',
    templateUrl: './info-item-row.component.html',
    styleUrls: ['./info-item-row.component.scss']
})
export class InfoItemRowComponent implements OnInit
{
    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
