<h4 *ngIf="!item?.id">Neues Digital Intern</h4>
<h4 *ngIf="item?.id">{{ item?.idFormattedPrefix }}</h4>
<oag-info-item-row>
    <oag-info-item-wrapper width="4" titel="Nachbearbeitet">
        <select [(ngModel)]="item.nachbearbeitet">
            <option [ngValue]="true">Ja</option>
            <option [ngValue]="false">Nein</option>
        </select>
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="4" titel="Datum Digitalisiert">
        <input type="date" [(ngModel)]="item.datum">
    </oag-info-item-wrapper>
    <oag-info-item-wrapper width="4" titel="Nachbearbeitet">
        <select [(ngModel)]="item.type">
            <option value="image">Image</option>
            <option value="video">Video</option>
            <option value="audio">Audio</option>
        </select>
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper width="12" titel="Beschreibung">
        <input type="text" [(ngModel)]="item.beschreibung">
    </oag-info-item-wrapper>
</oag-info-item-row>
<ng-content></ng-content>
