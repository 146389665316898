import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'oag-eintrag-detail-page',
    templateUrl: './eintrag-detail-page.component.html',
    styleUrls: ['./eintrag-detail-page.component.scss']
})
export class EintragDetailPageComponent implements OnInit
{
    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
