import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as OpenSeadragon from 'openseadragon';
import { MediaListService } from 'src/app/shared/services/media-list/media-list.service';

@Component({
    selector: 'oag-seadragon',
    templateUrl: './seadragon.component.html',
    styleUrls: ['./seadragon.component.scss']
})
export class SeadragonComponent implements OnInit, OnChanges
{
    @ViewChild('seadragon', { static: true }) public seadragon: ElementRef;
    @Input() public url: string = null;
    public seadragonViewer = null;

    constructor(public mediaList: MediaListService) { }
    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges)
    {
        if (!this.seadragonViewer)
        {
            this.seadragonViewer = OpenSeadragon({
                id: this.seadragon.nativeElement.id,
                showNavigator: true,
                navigatorPosition: "ABSOLUTE",
                navigatorTop: "20px",
                navigatorLeft: "20px",
                navigatorHeight: "90px",
                navigatorWidth: "120px",
                showZoomControl: false,
                showFullPageControl: false,
                showHomeControl: false,
                maxZoomPixelRatio: 2
            });
        }

        if (changes.url.currentValue)
        {
            this.seadragonViewer.open({
                type: 'image',
                url: changes.url.currentValue,
                ajaxWithCredentials: false
            });
        }
    }
}
