<div class="selection">
    <input class="selection__input" type="text" placeholder="Suchen" [(ngModel)]="searchTerm" (focus)="openSelection()" (blur)="closeSelection()">
    <div class="selection__pool" [ngClass]="{'selection__pool--opend': opend}">
        <div class="pool-item" *ngFor="let item of getFiltredPool()" (click)="add(item)">
            {{ item.titel }} ({{ item.datierung.text }})
        </div>
    </div>
</div>
<div class="choosen">
    <oag-label-wrapper class="item" *ngFor="let item of choosen" [titel]="item.label" (remove)="remove(item)"></oag-label-wrapper>
</div>
