import { Component, OnInit } from '@angular/core';
import { FileDownload } from 'src/app/shared/classes/file-download/file-download';
import { ExportsApiService } from 'src/app/shared/services/api-endpoints/exports-api/exports-api.service';
import { FrontendService } from 'src/app/shared/services/frontend/frontend.service';

@Component({
    selector: 'oag-etiketten-page',
    templateUrl: './etiketten-page.component.html',
    styleUrls: ['./etiketten-page.component.scss']
})
export class EtikettenPageComponent implements OnInit
{
    public requestBody = {
        offset: 0,
        goa: '',
        gfa: '',
        gsa: '',
        gda: '',
        ids: ''
    };

    public options = {
        quellen: [{
            titel: 'Datenbank',
            path: 'db'
        }, {
            titel: 'Statische Inhalte',
            path: 'static'
        }],
        layouts: [{
            titel: 'Sichttaschen Medium 2x4 Landscape - Behältnis',
            path: 'SichttaschenMedium2x4LandscapeBehaeltnisLayout',
            entities: [{
                titel: 'Behältnis',
                path: 'behaeltnisse'
            }]
        }, {
            titel: 'Sichttaschen Medium 2x4 Portrait - Behältnis',
            path: 'SichttaschenMedium2x4PortraitBehaeltnisLayout',
            entities: [{
                titel: 'Behältnis',
                path: 'behaeltnisse'
            }]
        }, {
            titel: 'Sichttaschen Small 2x12 Landscape - Behältnis',
            path: 'SichttaschenSmall2x12LandscapeBehaeltnisLayout',
            entities: [{
                titel: 'Behältnis',
                path: 'behaeltnisse'
            }]
        }, {
            titel: 'Ordner Small 5x1 Portrait - Behältnis',
            path: 'OrdnerSmall5x1PortraitBehaeltnisLayout',
            entities: [{
                titel: 'Behältnis',
                path: 'behaeltnisse'
            }]
        }, {
            titel: 'Ordner Large 5x1 Portrait - Behältnis',
            path: 'OrdnerLarge5x1PortraitBehaeltnisLayout',
            entities: [{
                titel: 'Behältnis',
                path: 'behaeltnisse'
            }]
        }, {
            titel: 'Sichttaschen Medium 2x4 Landscape - Eintrag',
            path: 'SichttaschenMedium2x4LandscapeEintragLayout',
            entities: [{
                titel: 'Eintrag',
                path: 'eintraege'
            }]
        }, {
            titel: 'Klebeetiketten 2x6 Landscape - Eintrag',
            path: 'Klebeetiketten2x6LandscapeEintragLayout',
            entities: [{
                titel: 'Eintrag',
                path: 'eintraege'
            }]
        }, {
            titel: 'Diaetiketten 4x32 Landscape - Eintrag',
            path: 'Diaetiketten4x32LandscapeEintragLayout',
            entities: [{
                titel: 'Eintrag',
                path: 'eintraege'
            }]
        }, {
            titel: 'Klebeetiketten 4x10 Landscape - Item Intern',
            path: 'Klebeetiketten4x10LandscapeItemInternLayout',
            entities: [{
                titel: 'Item Intern',
                path: 'itemsIntern'
            }]
        }]
    };

    public selected = {
        quelle: this.options.quellen[0],
        layout: this.options.layouts[0],
        entity: this.options.layouts[0].entities[0]
    };

    constructor(
        private exportsAPI: ExportsApiService,
        public frontend: FrontendService)
    {
    }

    public ngOnInit() { }

    public changeLayout()
    {
        this.selected.entity = this.selected.layout.entities[0];
    }

    public download()
    {
        this.exportsAPI
            .getEtiketten(this.selected.quelle.path, this.selected.layout.path, this.selected.entity.path, this.requestBody)
            .subscribe((res) => new FileDownload(res).directDownload(`Etiketten_${this.selected.quelle.titel}_${this.selected.layout.titel}_${this.selected.entity.titel}`, true));
    }

}
