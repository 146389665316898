<section>

    <h2>Applikation</h2>
    <h3>Versionsinformationen</h3>
    <oag-info-item-row>
        <oag-info-item-wrapper width="3" titel="Number">
            {{ version.number }}
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="3" titel="Build Run ID">
            {{ version.buildRunId }}
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="3" titel="Build Run Number">
            {{ version.buildRunNumber }}
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="3" titel="Build Date">
            {{ version.buildDate }}
        </oag-info-item-wrapper>
    </oag-info-item-row>

    <h2>Environment</h2>
    <h3>Basic & API</h3>
    <oag-info-item-row>
        <oag-info-item-wrapper width="3" titel="Name">
            {{ env.name }}
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="3" titel="Production Flag">
            {{ env.production | booleanAsText }}
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="6" titel="API">
            {{ env.api.url }}
        </oag-info-item-wrapper>
    </oag-info-item-row>

    <h3>Auth0</h3>
    <oag-info-item-row>
        <oag-info-item-wrapper width="4" titel="Domain">
            {{ env.auth.domain }}
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Client ID">
            {{ env.auth.clientId }}
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Audience">
            {{ env.auth.audience }}
        </oag-info-item-wrapper>
    </oag-info-item-row>


    <h2>Runtime</h2>
    <h3>Auth0 OAG-API JWT Token</h3>
    <code>{{ currentJWT }}</code>
</section>
