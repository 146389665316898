<h3>{{ person?.label }}</h3>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Vorname" width="12">
        {{ person?.vorname }}
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Nachname" width="12">
        {{ person?.nachname }}
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Suffix" width="12">
        {{ person?.suffix }}
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Spitzname" width="12">
        {{ person?.spitzname }}
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Geburtsjahr" width="6">
        {{ person?.geburtsjahr }}
    </oag-info-item-wrapper>
    <oag-info-item-wrapper titel="Sterbejahr" width="6">
        {{ person?.sterbejahr }}
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Notizen" width="12">
        {{ person?.notizen }}
    </oag-info-item-wrapper>
</oag-info-item-row>

<button class="button" (click)="quickView.editPerson()">Bearbeiten</button>
