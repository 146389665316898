import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'oag-settings-page',
    templateUrl: './settings-page.component.html',
    styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit
{
    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
