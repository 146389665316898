<oag-content-wrapper>

    <h2>5. Ereignisse & Serien</h2>
    <h3>5.1. Ereignisse</h3>
    <oag-multiple-select-ereignis [(choosen)]="eintragEdit.eintrag.ereignisse"></oag-multiple-select-ereignis>

    <h3>5.2. Serien</h3>
    <oag-multiple-select-serie [(choosen)]="eintragEdit.eintrag.serien"></oag-multiple-select-serie>

</oag-content-wrapper>
