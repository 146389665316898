<oag-content-wrapper>
    <h1>Indizes</h1>
    <p>Elastic Search Indzies indizieren</p>

    <h2>Einträge</h2>
    <div class="row">
        <button *ngIf="!runEintraege" class="button" (click)="indiziereEintraege(startIndexEintraege)">Alle Einträge neu indizieren</button>
        <input *ngIf="!runEintraege" type="number" [(ngModel)]="startIndexEintraege">
        <button *ngIf="runEintraege" class="button" (click)="cancelEintraege()">Abbrechen</button>
    </div>
    <p class="status" *ngIf="runEintraege">{{ statusEintraege }}</p>

    <h3 [hidden]="failedEintraege.length == 0">Failed</h3>
    <ul [hidden]="failedEintraege.length == 0">
        <li *ngFor="let eintrag of failedEintraege">{{ eintrag.idFormattedPrefix }}</li>
    </ul>
    
    <h2>Ereignisse</h2>
    <button *ngIf="!runEreignisse" class="button" (click)="indiziereEreignisse()">Alle Ereignisse neu indizieren</button>
    <button *ngIf="runEreignisse" class="button" (click)="cancelEreignisse()">Abbrechen</button>
    <p class="status" *ngIf="runEreignisse">{{ statusEreignisse }}</p>

    <h3 [hidden]="failedEreignisse.length == 0">Failed</h3>
    <ul [hidden]="failedEreignisse.length == 0">
        <li *ngFor="let ereignis of failedEreignisse">{{ ereignis.id }}</li>
    </ul>
    
    <h2>Personen</h2>
    <button *ngIf="!runPersonen" class="button" (click)="indizierePersonen()">Alle Personen neu indizieren</button>
    <button *ngIf="runPersonen" class="button" (click)="cancelPersonen()">Abbrechen</button>
    <p class="status" *ngIf="runPersonen">{{ statusPersonen }}</p>

    <h3 [hidden]="failedPersonen.length == 0">Failed</h3>
    <ul [hidden]="failedPersonen.length == 0">
        <li *ngFor="let person of failedPersonen">{{ person.id }}</li>
    </ul>

</oag-content-wrapper>
