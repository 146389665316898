<oag-content-wrapper class="list">

    <header class="headline-button-container">
        <h1>Kästen</h1>
        <button class="button button--medium" (click)="edit()">Neuer Kasten</button>
    </header>
    <table summary="Kästen">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Platz</th>
                <th scope="col">Größe</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let kasten of kaesten">
                <td>{{ kasten.id }}</td>
                <td>{{ kasten.name }}</td>
                <td>{{ kasten.platz }}</td>
                <td>{{ kasten.groesse }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="edit(kasten)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>

</oag-content-wrapper>

<div class="edit" *ngIf="kastenEdit">
    <header class="headline-button-container">
        <h3>{{ kastenEdit.name }}</h3>
        <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
    </header>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Name" width="12">
            <input type="text" [(ngModel)]="kastenEdit.name">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Platz" width="6">
            <input type="text" [(ngModel)]="kastenEdit.platz">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Größe" width="6">
            <input type="text" [(ngModel)]="kastenEdit.groesse">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <div class="edit-button-container">
        <button class="button" *ngIf="kastenEdit.id" (click)="update()">Speichern</button>
        <button class="button" *ngIf="!kastenEdit.id" (click)="create()">Erstellen</button>
        <button class="button button--outline" *ngIf="kastenEdit.id" (click)="delete()">Löschen</button>
    </div>
</div>
