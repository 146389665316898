<oag-content-wrapper>

    <oag-info-item-row>
        <oag-info-item-wrapper width="7" titel="Layout">
            <select [(ngModel)]="selected.layout" (change)="changeLayout()">
                <option [ngValue]="layout" *ngFor="let layout of options.layouts">{{ layout.titel }}</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="3" titel="Entität">
            <select [(ngModel)]="selected.entity">
                <option [ngValue]="entity" *ngFor="let entity of selected.layout.entities">{{ entity.titel }}</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="2" titel="Quelle">
            <select [(ngModel)]="selected.quelle">
                <option [ngValue]="quelle" *ngFor="let quelle of options.quellen">{{ quelle.titel }}</option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>

    <oag-info-item-row>
        <oag-info-item-wrapper width="4" titel="Offset">
            <input type="number" min="0" [(ngModel)]="requestBody.offset">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="GOA">
            <input type="text" [(ngModel)]="requestBody.goa">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="GFA">
            <input type="text" [(ngModel)]="requestBody.gfa">
        </oag-info-item-wrapper>
    </oag-info-item-row>

    <oag-info-item-row>
        <oag-info-item-wrapper width="4" titel="GSA">
            <input type="text" [(ngModel)]="requestBody.gsa">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="GDA">
            <input type="text" [(ngModel)]="requestBody.gda">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="4" titel="Download">
            <button class="button" (click)="download()">Download</button>
        </oag-info-item-wrapper>
    </oag-info-item-row>

</oag-content-wrapper>
