<oag-content-wrapper>
    <div class="new" *ngIf="files.length">
        <oag-info-item-row>
            <oag-info-item-wrapper titel="File" width="12">
                <select [(ngModel)]="newUpload.path">
                    <option [value]="file.path" *ngFor="let file of files">{{ file.fileName }}</option>
                </select>
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <oag-info-item-row>
            <oag-info-item-wrapper titel="Eintrag" width="3">
                <input type="text" placeholder="Suche nach ID" [(ngModel)]="search" (keydown.enter)="searchForItemsDigital()">
            </oag-info-item-wrapper>
            <oag-info-item-wrapper titel="Item Digital" width="3">
                <select [(ngModel)]="newUpload.itemDigitalId">
                    <option [value]="null">Kein Item gewählt</option>
                    <option [value]="itemDigital.idFormatted" *ngFor="let itemDigital of foundEintrag?.items?.digital; let i = index">{{ itemDigital.idFormattedPrefix }} ({{ i + 1 }}/{{ foundEintrag?.items?.digital.length }})</option>
                </select>
            </oag-info-item-wrapper>
            <oag-info-item-wrapper titel="PDF Resolution" width="2">
                <input type="number" placeholder="PDF Resolution" [(ngModel)]="newUpload.pdfResolution">
            </oag-info-item-wrapper>
            <oag-info-item-wrapper titel="Erstellen" width="4">
                <button class="button" (click)="createAndStartProcessing()">Upload erstellen & starte Porcessing</button>
            </oag-info-item-wrapper>
        </oag-info-item-row>
    </div>

    <h2>Laufend</h2>
    <oag-upload-item *ngFor="let upload of activeUploads" [upload]="upload"></oag-upload-item>
    <p [hidden]="activeUploads.length">Keine laufenden Uploads vorhanden.</p>

    <h2>Abgeschlossen</h2>
    <oag-upload-item *ngFor="let upload of finishedUploads" [upload]="upload"></oag-upload-item>
    <p [hidden]="finishedUploads.length">Keine abgeschlossenen Uploads vorhanden.</p>
</oag-content-wrapper>
