import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ZeittafelModule } from '../zeittafel/zeittafel.module';
import { EintragDetailEditEintragComponent } from './components/eintrag-detail-edit/eintrag-detail-edit-eintrag/eintrag-detail-edit-eintrag.component';
import { EintragDetailEditEreignisseComponent } from './components/eintrag-detail-edit/eintrag-detail-edit-ereignisse/eintrag-detail-edit-ereignisse.component';
import { EintragDetailEditItemsComponent } from './components/eintrag-detail-edit/eintrag-detail-edit-items/eintrag-detail-edit-items.component';
import { EintragDetailEditTagsPersonenComponent } from './components/eintrag-detail-edit/eintrag-detail-edit-tags-personen/eintrag-detail-edit-tags-personen.component';
import { EintragDetailEditVerortungComponent } from './components/eintrag-detail-edit/eintrag-detail-edit-verortung/eintrag-detail-edit-verortung.component';
import { EintragDetailEditWrapperComponent } from './components/eintrag-detail-edit/eintrag-detail-edit-wrapper/eintrag-detail-edit-wrapper.component';
import { EintragDetailViewEintragComponent } from './components/eintrag-detail-view/eintrag-detail-view-eintrag/eintrag-detail-view-eintrag.component';
import { EintragDetailViewEreignisseComponent } from './components/eintrag-detail-view/eintrag-detail-view-ereignisse/eintrag-detail-view-ereignisse.component';
import { EintragDetailViewItemsComponent } from './components/eintrag-detail-view/eintrag-detail-view-items/eintrag-detail-view-items.component';
import { EintragDetailViewTagsPersonenComponent } from './components/eintrag-detail-view/eintrag-detail-view-tags-personen/eintrag-detail-view-tags-personen.component';
import { EintragDetailViewVerortungComponent } from './components/eintrag-detail-view/eintrag-detail-view-verortung/eintrag-detail-view-verortung.component';
import { EintragDetailViewWrapperComponent } from './components/eintrag-detail-view/eintrag-detail-view-wrapper/eintrag-detail-view-wrapper.component';
import { ItemDigitalEditComponent } from './components/items/item-digital-edit/item-digital-edit.component';
import { ItemDigitalViewComponent } from './components/items/item-digital-view/item-digital-view.component';
import { ItemExternEditComponent } from './components/items/item-extern-edit/item-extern-edit.component';
import { ItemExternViewComponent } from './components/items/item-extern-view/item-extern-view.component';
import { ItemInternEditComponent } from './components/items/item-intern-edit/item-intern-edit.component';
import { ItemInternViewComponent } from './components/items/item-intern-view/item-intern-view.component';
import { EintragChoosePageComponent } from './pages/eintrag-choose-page/eintrag-choose-page.component';
import { EintragDetailPageComponent } from './pages/eintrag-detail-page/eintrag-detail-page.component';
import { EintragEditService } from './services/eintrag-edit/eintrag-edit.service';
import { EintragViewService } from './services/eintrag-view/eintrag-view.service';
import { EintragPolicyViewComponent } from './components/policies/eintrag-policy-view/eintrag-policy-view.component';
import { EintragPolicyEditComponent } from './components/policies/eintrag-policy-edit/eintrag-policy-edit.component';
import { EintragDetailEditPoliciesComponent } from './components/eintrag-detail-edit/eintrag-detail-edit-policies/eintrag-detail-edit-policies.component';
import { EintragDetailViewPoliciesComponent } from './components/eintrag-detail-view/eintrag-detail-view-policies/eintrag-detail-view-policies.component';

@NgModule({
    declarations: [
        EintragChoosePageComponent,
        EintragDetailPageComponent,
        EintragDetailEditWrapperComponent,
        EintragDetailEditEintragComponent,
        EintragDetailEditItemsComponent,
        EintragDetailEditTagsPersonenComponent,
        EintragDetailEditVerortungComponent,
        EintragDetailEditEreignisseComponent,
        EintragDetailViewWrapperComponent,
        EintragDetailViewEintragComponent,
        EintragDetailViewItemsComponent,
        EintragDetailViewTagsPersonenComponent,
        EintragDetailViewVerortungComponent,
        EintragDetailViewEreignisseComponent,
        ItemInternViewComponent,
        ItemInternEditComponent,
        ItemExternViewComponent,
        ItemExternEditComponent,
        ItemDigitalViewComponent,
        ItemDigitalEditComponent,
        EintragPolicyViewComponent,
        EintragPolicyEditComponent,
        EintragDetailEditPoliciesComponent,
        EintragDetailViewPoliciesComponent
    ],
    exports: [
        EintragChoosePageComponent,
        EintragDetailPageComponent,
        EintragDetailEditWrapperComponent,
        EintragDetailEditEintragComponent,
        EintragDetailEditItemsComponent,
        EintragDetailEditTagsPersonenComponent,
        EintragDetailEditVerortungComponent,
        EintragDetailEditEreignisseComponent,
        EintragDetailViewWrapperComponent,
        EintragDetailViewEintragComponent,
        EintragDetailViewItemsComponent,
        EintragDetailViewTagsPersonenComponent,
        EintragDetailViewVerortungComponent,
        EintragDetailViewEreignisseComponent,
        ItemInternViewComponent,
        ItemInternEditComponent,
        ItemExternViewComponent,
        ItemExternEditComponent,
        ItemDigitalViewComponent,
        ItemDigitalEditComponent
    ],
    providers: [
        EintragEditService,
        EintragViewService
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        SharedModule,
        ZeittafelModule
    ]
})
export class EintragModule { }
