import { Component, OnInit } from '@angular/core';
import { KastenModel } from '@ortsarchiv-gemeinlebarn/data';
import { KastenApiService } from 'src/app/shared/services/api-endpoints/kasten-api/kasten-api.service';

@Component({
    selector: 'oag-kaesten-page',
    templateUrl: './kaesten-page.component.html',
    styleUrls: ['./kaesten-page.component.scss']
})
export class KaestenPageComponent implements OnInit
{
    public kaesten: Array<KastenModel> = [];
    public kastenEdit: KastenModel = null;

    constructor(public kastenAPI: KastenApiService)
    {
        this.loadTags();
    }

    public ngOnInit() { }

    public resetEdit()
    {
        this.kastenEdit = null;
    }

    private loadTags()
    {
        this.resetEdit();
        this.kastenAPI.readAll().subscribe((kaesten) => this.kaesten = kaesten);
    }

    public edit(kasten: KastenModel = null)
    {
        if (kasten === null)
        {
            kasten = new KastenModel();
        }

        this.resetEdit();
        this.kastenEdit = kasten;
    }

    public update = () => this.kastenAPI.update(this.kastenEdit).subscribe(() => this.loadTags());
    public create = () => this.kastenAPI.create(this.kastenEdit).subscribe(() => this.loadTags());
    public delete = () => this.kastenAPI.delete(this.kastenEdit.id).subscribe(() => this.loadTags());

    public compareIds = (a, b) => (!a || !b) ? false : a.id === b.id;
}
