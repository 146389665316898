import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import { FrontendService } from '../../frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class ExportsApiService
{
    constructor(private http: HttpClient, private api: ApiService, public frontend: FrontendService) { }

    public getEtiketten(quelle: string, layout: string, entity: string, params: any): Observable<any>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/exports/etiketten/${quelle}/${layout}/${entity}`, { headers: this.api.headers, params, responseType: 'blob' })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
