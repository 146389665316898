import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'oag-task-logging-page',
  templateUrl: './task-logging-page.component.html',
  styleUrls: ['./task-logging-page.component.scss']
})
export class TaskLoggingPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
