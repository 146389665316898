import { Component, OnInit } from '@angular/core';
import { FachModel } from '@ortsarchiv-gemeinlebarn/data';
import { KastenModel } from '@ortsarchiv-gemeinlebarn/data';
import { FachApiService } from 'src/app/shared/services/api-endpoints/fach-api/fach-api.service';
import { KastenApiService } from 'src/app/shared/services/api-endpoints/kasten-api/kasten-api.service';

@Component({
    selector: 'oag-faecher-page',
    templateUrl: './faecher-page.component.html',
    styleUrls: ['./faecher-page.component.scss']
})
export class FaecherPageComponent implements OnInit
{
    public kaesten: Array<KastenModel> = [];
    public faecher: Array<FachModel> = [];
    public fachEdit: FachModel = null;

    constructor(public fachAPI: FachApiService, public kastenAPI: KastenApiService)
    {
        this.kastenAPI.readAll().subscribe((kaesten) => this.kaesten = kaesten);
        this.loadTags();
    }

    public ngOnInit() { }

    public resetEdit()
    {
        this.fachEdit = null;
    }

    private loadTags()
    {
        this.resetEdit();
        this.fachAPI.readAll().subscribe((faecher) => this.faecher = faecher);
    }

    public edit(fach: FachModel = null)
    {
        if (fach === null)
        {
            fach = new FachModel();
        }

        this.resetEdit();
        this.fachEdit = fach;
    }

    public update = () => this.fachAPI.update(this.fachEdit).subscribe(() => this.loadTags());
    public create = () => this.fachAPI.create(this.fachEdit).subscribe(() => this.loadTags());
    public delete = () => this.fachAPI.delete(this.fachEdit.id).subscribe(() => this.loadTags());

    public compareIds = (a, b) => (!a || !b) ? false : a.id === b.id;
}
