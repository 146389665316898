<oag-page-wrapper type="center">
    <div class="action">
        <div class="jump-to">
            <input type="text" placeholder="Eintrag oder Item" [(ngModel)]="search" (keyup.enter)="searchAndJump()">
            <button class="button" (click)="searchAndJump()">Zum Eintrag springen</button>
        </div>
        <div class="neuer-eintrag">
            <button class="button button--light" [routerLink]="['/eintrag/null/null/edit/eintrag']" routerLinkActive="router-link-active">Neuen Eintrag erstellen</button>
        </div>
    </div>
</oag-page-wrapper>