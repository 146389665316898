<h3>{{ person?.vorname }} {{ person?.nachname }} {{ person?.suffix }}</h3>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Vorname" width="12">
        <input type="text" [(ngModel)]="person.vorname">
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Nachname" width="12">
        <input type="text" [(ngModel)]="person.nachname">
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Suffix" width="12">
        <input type="text" [(ngModel)]="person.suffix">
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Spitzname" width="12">
        <input type="text" [(ngModel)]="person.spitzname">
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Geburtsjahr" width="6">
        <input type="number" [(ngModel)]="person.geburtsjahr">
    </oag-info-item-wrapper>
    <oag-info-item-wrapper titel="Sterbejahr" width="6">
        <input type="number" [(ngModel)]="person.sterbejahr">
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Notizen" width="12">
        <textarea [(ngModel)]="person.notizen"></textarea>
    </oag-info-item-wrapper>
</oag-info-item-row>
<oag-info-item-row>
    <oag-info-item-wrapper titel="Suche Freigabe" width="12">
        <select [(ngModel)]="person.sucheFreigabe">
            <option [ngValue]="true">Ja</option>
            <option [ngValue]="false">Nein</option>
        </select>
    </oag-info-item-wrapper>
</oag-info-item-row>

<button class="button" (click)="save()">Speichern</button>
