import { Component, Input, OnInit } from '@angular/core';
import { TimelineService } from '../../services/timeline/timeline.service';

@Component({
    selector: 'oag-timeline-ereignis',
    templateUrl: './timeline-ereignis.component.html',
    styleUrls: ['./timeline-ereignis.component.scss']
})
export class TimelineEreignisComponent implements OnInit
{
    @Input() public ereignis = null;

    constructor(public timeline: TimelineService) { }
    public ngOnInit() { }
}
