<h2 class="name">{{ item?.policy?.name }}</h2>
<p class="description">{{ item?.policy?.description }}</p>
<div class="tags">
    <div class="tag">{{ applied ? "wird aktuell angewendet" : "wird aktuell nicht angewendet" }}</div>
</div>
<p class="keyvalue">
    <strong>Beginn: </strong>
    <span [ngClass]="{'null': !item?.start}">{{ item?.start ? (item?.start | date: 'dd.MM.yyyy HH:mm:ss') : "nicht festgelegt" }}</span>
</p>
<p class="keyvalue">
    <strong>Ende: </strong>
    <span [ngClass]="{'null': !item?.end}">{{ item?.end ? (item?.end | date: 'dd.MM.yyyy HH:mm:ss') : "nicht festgelegt" }}</span>
</p>
<p class="keyvalue">
    <strong>Anmerkung: </strong>
    <span [ngClass]="{'null': !item?.note}">{{ item?.note ? item?.note : "-" }}</span>
</p>