import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { StatistikApiService } from 'src/app/shared/services/api-endpoints/statistik-api/statistik-api.service';

@Component({
    selector: 'oag-statistiken-page',
    templateUrl: './statistiken-page.component.html',
    styleUrls: ['./statistiken-page.component.scss']
})
export class StatistikenPageComponent implements OnInit
{
    public stats = {
        eintraegeAnzahlPerJahrBestand: null,
        digitalisierungsgrad: null,
        digitalisierteItemsAnzahlPerJahr: null,
        digitalisierteSeitenAnzahlPerJahr: null
    };
    public charts = {
        eintraegeAnzahlPerJahrBestand: null,
        digitalisierungsgrad: {
            gesamt: null,
            GOA: null,
            GFA: null,
            GSA: null,
            GDA: null,
            GLA: null,
            GZA: null
        },
        digitalisierteItemsAnzahlPerJahr: null,
        digitalisierteSeitenAnzahlPerJahr: null
    };

    constructor(private statistikAPI: StatistikApiService)
    {
        this.statistikAPI.digitalisierungsgrad().subscribe((data) => this.stats.digitalisierungsgrad = data);

        this.statistikAPI.eintraegeAnzahlPerJahrBestand().subscribe((data) =>
        {
            this.stats.eintraegeAnzahlPerJahrBestand = data;
            this.charts.eintraegeAnzahlPerJahrBestand = this.getEintraegeAnzahlPerJahrBestandChart('chart-eintraegeAnzahlPerJahrBestand', this.stats.eintraegeAnzahlPerJahrBestand);
        });

        this.statistikAPI.digitalisierteItemsAnzahlPerJahr().subscribe((data) =>
        {
            this.stats.digitalisierteItemsAnzahlPerJahr = data;
            this.charts.digitalisierteItemsAnzahlPerJahr = this.getDigitalisierteItemsAnzahlPerJahrChart('chart-digitalisierteItemsAnzahlPerJahr', this.stats.digitalisierteItemsAnzahlPerJahr);
        });

        this.statistikAPI.digitalisierteSeitenAnzahlPerJahr().subscribe((data) =>
        {
            this.stats.digitalisierteSeitenAnzahlPerJahr = data;
            this.charts.digitalisierteSeitenAnzahlPerJahr = this.getDigitalisierteSeitenAnzahlPerJahrChart('chart-digitalisierteSeitenAnzahlPerJahr', this.stats.digitalisierteSeitenAnzahlPerJahr);
        });
    }

    public ngOnInit() { }

    public getEintraegeAnzahlPerJahrBestandChart(id, stats)
    {
        if (!stats)
        {
            return false;
        }

        const yearLabels = [];
        const enwticklungPerYear = [];
        const enwticklungSummarize = [];

        let summarizeCount = 0;
        stats.gesamt.years.forEach((item) =>
        {
            summarizeCount += item.count;
            yearLabels.push(item.year);
            enwticklungPerYear.push(item.count);
            enwticklungSummarize.push(summarizeCount);
        });

        return this.perJahrBarChart(id, yearLabels, enwticklungPerYear, enwticklungSummarize);
    }

    public getDigitalisierteItemsAnzahlPerJahrChart(id, stats)
    {
        if (!stats)
        {
            return false;
        }

        const yearLabels = [];
        const enwticklungPerYear = [];
        const enwticklungSummarize = [];

        let summarizeCount = 0;
        stats.years.forEach((item) =>
        {
            summarizeCount += item.count;
            yearLabels.push(item.year);
            enwticklungPerYear.push(item.count);
            enwticklungSummarize.push(summarizeCount);
        });

        return this.perJahrBarChart(id, yearLabels, enwticklungPerYear, enwticklungSummarize);
    }

    public getDigitalisierteSeitenAnzahlPerJahrChart(id, stats)
    {
        if (!stats)
        {
            return false;
        }

        const yearLabels = [];
        const enwticklungPerYear = [];
        const enwticklungSummarize = [];

        let summarizeCount = 0;
        stats.years.forEach((item) =>
        {
            summarizeCount += item.count;
            yearLabels.push(item.year);
            enwticklungPerYear.push(item.count);
            enwticklungSummarize.push(summarizeCount);
        });

        return this.perJahrBarChart(id, yearLabels, enwticklungPerYear, enwticklungSummarize);
    }

    private perJahrBarChart(id, yearLabels, enwticklungPerYear, enwticklungSummarize)
    {
        return new Chart(id, {
            type: 'line',
            data: {
                labels: yearLabels,
                datasets: [{
                    type: 'bar',
                    label: 'pro Jahr',
                    backgroundColor: '#39569b',
                    data: enwticklungPerYear
                },
                {
                    type: 'line',
                    label: 'Gesamtanzahl',
                    fill: true,
                    borderWidth: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.15)',
                    borderColor: 'rgba(57, 86, 155, 0.7)',
                    data: enwticklungSummarize
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }
}
