<h4>{{ item?.idFormattedPrefix }}</h4>
<div class="wrapper">
    <img class="preview" style="height: 238px; width: auto;" [src]="item?.thumbnail" alt="Thumbnail">
    <div class="info">
        <oag-info-item-row>
            <oag-info-item-wrapper width="3" titel="Type">
                <span *ngIf="item?.type == 'image'">Bild</span>
                <span *ngIf="item?.type == 'video'">Video</span>
                <span *ngIf="item?.type == 'audio'">Audio</span>
            </oag-info-item-wrapper>
            <oag-info-item-wrapper width="3" titel="Auflösung (Erste Seite)">{{ item?.pages[0]?.aufloesungX }} / {{ item?.pages[0]?.aufloesungY }} DPI</oag-info-item-wrapper>
            <oag-info-item-wrapper width="3" titel="Aspect Ratio (Erste Seite)">{{ item?.pages[0]?.aspectRatio }}</oag-info-item-wrapper>
            <oag-info-item-wrapper width="3" titel="Nachbearbeitet">{{ item?.nachbearbeitet | booleanAsText }}</oag-info-item-wrapper>
        </oag-info-item-row>
        <oag-info-item-row>
            <oag-info-item-wrapper width="3" titel="Datum Digitalisiert">{{ item?.datum | date: 'dd.MM.yyyy' }}</oag-info-item-wrapper>
            <oag-info-item-wrapper width="3" titel="Seitenanzahl">{{ item?.pages.length }}</oag-info-item-wrapper>
            <oag-info-item-wrapper width="3" titel="Original Archiv Größe">
                <span *ngIf="item?.glacierSize < (1024 * 1024 * 1024)">{{ item?.glacierSize / (1024 * 1024) | number:'1.0-2'}} MB</span>
                <span *ngIf="item?.glacierSize >= (1024 * 1024 * 1024)">{{ item?.glacierSize / (1024 * 1024 * 1024) | number:'1.0-2'}} GB</span>
            </oag-info-item-wrapper>
            <oag-info-item-wrapper width="3">
                <button class="button" (click)="mediaList.openItemDigitalById(item.id)">betrachten</button>
            </oag-info-item-wrapper>
        </oag-info-item-row>
        <oag-info-item-row>
            <oag-info-item-wrapper width="12" titel="Beschreibung">{{ item?.beschreibung }}</oag-info-item-wrapper>
        </oag-info-item-row>
    </div>
</div>