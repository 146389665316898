import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { UploadModel } from '@ortsarchiv-gemeinlebarn/data';
import { ApiService } from '../../api/api.service';
import { FrontendService } from '../../frontend/frontend.service';

@Injectable({
    providedIn: 'root'
})
export class UploadApiService
{
    constructor(public http: HttpClient, public api: ApiService, public frontend: FrontendService) { }

    public listFilesInUploadDirectoryWithoutUnfinishedUpload(): Observable<Array<string>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/uploads/listFilesInUploadDirectoryWithoutUnfinishedUpload`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public createAndStartProcessing(path: string, itemDigitalId: number, pdfResolution: number): Observable<UploadModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .post(`${this.api.url}/uploads/createAndStartProcessing`, { path, itemDigitalId, pdfResolution }, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public readActiveUploads(showLoadingIndicator: boolean): Observable<Array<UploadModel>>
    {
        if (showLoadingIndicator) this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/uploads/readActiveUploads`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() =>
                {
                    if (showLoadingIndicator)
                    {
                        this.frontend.stopLoadingIndicator();
                    }
                }),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public readFinishedUploads(): Observable<Array<UploadModel>>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/uploads/readFinishedUploads`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }

    public read(id: number): Observable<UploadModel>
    {
        this.frontend.startLoadingIndicator();
        return this.http
            .get(`${this.api.url}/uploads/read/{id}`, { headers: this.api.headers })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            );
    }
}
