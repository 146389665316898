<div class="eigenschaften">
    <p class="eigenschaft">
        <span>Objekt ID</span>
        <strong>{{ objekt?.id }}</strong>
    </p>
    <p class="eigenschaft">
        <span>Eintrag</span>
        <oag-eintrag-id-formatted-prefix [eintrag]="objekt?.eintrag"></oag-eintrag-id-formatted-prefix>
    </p>
    
    <p class="eigenschaft">
        <span>VÖ Beginn</span>
        <strong>{{ objekt?.beginn ? (objekt?.beginn | date: 'dd.MM.yyyy HH:mm:ss') : "nicht festgelegt" }}</strong>
    </p>
    <p class="eigenschaft">
        <span>VÖ Ende</span>
        <strong>{{ objekt?.ende ? (objekt?.ende | date: 'dd.MM.yyyy HH:mm:ss') : "nicht festgelegt" }}</strong>
    </p>
    <p class="eigenschaft">
        <span>Anmerkung</span>
        <strong>{{ objekt?.anmerkung ? objekt.anmerkung : "-" }}</strong>
    </p>
</div>

<h3>{{ objekt?.eintrag.titel }}</h3>
<p>{{ objekt?.eintrag.datierung?.text }}</p>

<div class="bestandteile">
    <div class="bestandteil" *ngFor="let bestandteil of objekt?.bestandteile; let index = index" >
        <div class="bestandteil__image" [ngStyle]="{'background-image':'url(' + storageLinkBase + '/' + bestandteil.path + ')'}"></div>
        <div class="bestandteil__content">
            <div class="bestandteil__index">#{{ index }}</div>
            <div class="bestandteil__eintrag">{{ bestandteil.bestandSignatur }}{{ bestandteil.eintragIdFormatted }}</div>
            <div class="bestandteil__digitalItem">{{ bestandteil.itemDigitalIdFormatted }}</div>
            <div class="bestandteil__page">{{ bestandteil.seiteFormatted }}</div>
        </div>
    </div>
</div>

<div class="actions">
    <button class="button" (click)="schaufenster.delete(objekt.id)">Löschen</button>
</div>