<oag-content-wrapper>

    <h2>2. Vorhandene interne, externe & digitale Objekte</h2>

    <h3>2.1. Intern</h3>
    <oag-item-intern-view *ngFor="let item of eintragView.eintrag?.items.intern" [item]="item"></oag-item-intern-view>
    <p [hidden]="eintragView.eintrag?.items.intern.length">Diesem Eintrag sind keine interne (physisch vorhandene) Objekte zugewiesen.</p>

    <h3>2.2. Extern</h3>
    <oag-item-extern-view *ngFor="let item of eintragView.eintrag?.items.extern" [item]="item"></oag-item-extern-view>
    <p [hidden]="eintragView.eintrag?.items.extern.length">Diesem Eintrag sind keine externen (in anderen Archiven oder Privatbeständen vorhandene) Objekte zugewiesen.</p>

    <h3>2.3. Digital</h3>
    <oag-item-digital-view *ngFor="let item of eintragView.eintrag?.items.digital" [item]="item"></oag-item-digital-view>
    <p [hidden]="eintragView.eintrag?.items.digital.length">Diesem Eintrag sind keine digitalen Objekte zugewiesen.</p>

</oag-content-wrapper>
