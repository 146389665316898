import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-item-extern-view',
    templateUrl: './item-extern-view.component.html',
    styleUrls: ['./item-extern-view.component.scss']
})
export class ItemExternViewComponent implements OnInit
{
    @Input() public item = null;

    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
