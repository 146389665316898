<oag-content-wrapper class="list">

    <header class="headline-button-container">
        <h1>Fächer</h1>
        <button class="button button--medium" (click)="edit()">Neues Fach</button>
    </header>
    <table summary="Fächer">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Spalte</th>
                <th scope="col">Reihe</th>
                <th scope="col">Name</th>
                <th scope="col">Beschreibung</th>
                <th scope="col">Kasten</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let fach of faecher">
                <td>{{ fach.id }}</td>
                <td>{{ fach.spalte }}</td>
                <td>{{ fach.reihe }}</td>
                <td>{{ fach.name }}</td>
                <td>{{ fach.beschreibung }}</td>
                <td>{{ fach.kasten.name }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="edit(fach)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>

</oag-content-wrapper>

<div class="edit" *ngIf="fachEdit">
    <header class="headline-button-container">
        <h3>{{ fachEdit.name }}</h3>
        <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
    </header>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Spalte" width="6">
            <input type="text" [(ngModel)]="fachEdit.spalte">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Reihe" width="6">
            <input type="text" [(ngModel)]="fachEdit.reihe">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Name" width="12">
            <input type="text" [(ngModel)]="fachEdit.name">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Beschreibung" width="12">
            <input type="text" [(ngModel)]="fachEdit.beschreibung">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Kasten" width="12">
            <select [(ngModel)]="fachEdit.kasten" [compareWith]="compareIds">
                <option [ngValue]="kasten" *ngFor="let kasten of kaesten">{{ kasten.name }}</option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <div class="edit-button-container">
        <button class="button" *ngIf="fachEdit.id" (click)="update()">Speichern</button>
        <button class="button" *ngIf="!fachEdit.id" (click)="create()">Erstellen</button>
        <button class="button button--outline" *ngIf="fachEdit.id" (click)="delete()">Löschen</button>
    </div>
</div>
