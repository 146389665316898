import { Injectable } from '@angular/core';
import { EintragApiService } from 'src/app/shared/services/api-endpoints/eintrag-api/eintrag-api.service';

@Injectable({
    providedIn: 'root'
})
export class EintragViewService
{
    public eintrag = null;

    constructor(private eintragAPI: EintragApiService) { }

    public load(bestandId: number, eintragId: number)
    {
        this.eintragAPI.getByBestandIdEintragId(bestandId, eintragId).subscribe((eintrag) =>
        {
            this.eintrag = eintrag;
        });
    }
}
