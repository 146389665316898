import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EintragViewService } from 'src/app/eintrag/services/eintrag-view/eintrag-view.service';

@Component({
    selector: 'oag-eintrag-detail-view-wrapper',
    templateUrl: './eintrag-detail-view-wrapper.component.html',
    styleUrls: ['./eintrag-detail-view-wrapper.component.scss']
})
export class EintragDetailViewWrapperComponent implements OnInit
{
    public editLink = null;

    constructor(public eintragView: EintragViewService, private route: ActivatedRoute, private router: Router)
    {
        if (this.route.parent === null)
        {
            return;
        }

        this.route.parent.params.subscribe((params) =>
        {
            const bestandId = Number(params.bestandId);
            const eintragId = Number(params.eintragId);

            if (bestandId && eintragId)
            {
                this.eintragView.load(bestandId, eintragId);
            }
        });
    }

    public ngOnInit() { }

    public switchToEdit()
    {
        const url = this.router.url.replace('view', 'edit');
        this.router.navigateByUrl(url);
    }
}
