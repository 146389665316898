import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-search-result-item',
    templateUrl: './search-result-item.component.html',
    styleUrls: ['./search-result-item.component.scss']
})
export class SearchResultItemComponent implements OnInit
{
    @Input() public titel = null;

    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR
}
