export class FileDownload
{
    public mimeType = null;
    public size = null;
    public loaded = false;

    public data = {
        blob: null,
        base64: null
    };

    constructor(response)
    {
        this.mimeType = response.type;
        this.size = response.size;
        this.data.blob = new Blob([response], { type: this.mimeType });

        const reader = new FileReader();
        reader.readAsDataURL(this.data.blob);
        reader.addEventListener('load', () =>
        {
            this.data.base64 = reader.result;
            this.loaded = true;
        }, false);
    }

    private getExtensionFromMime(mime)
    {
        if (mime === 'application/pdf')
        {
            return 'pdf';
        } else if (mime === 'image/jpeg')
        {
            return 'jpeg';
        } else if (mime === 'image/png')
        {
            return 'png';
        } else
        {
            return 'unknown';
        }
    }

    public directDownload(name, timestamp?: boolean)
    {
        const t = Math.round(new Date().getTime() / 1000);

        if (window.navigator && window.navigator.msSaveOrOpenBlob)
        {
            window.navigator.msSaveOrOpenBlob(this.data.blob);
            return;
        }

        const data = window.URL.createObjectURL(this.data.blob);

        const link = document.createElement('a');
        link.href = data;
        link.download = timestamp ? `${name}_${t}.${this.getExtensionFromMime(this.mimeType)}` : `${name}.${this.getExtensionFromMime(this.mimeType)}`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(() =>
        {
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
    }
}
