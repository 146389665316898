import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
    selector: 'oag-chart-digitalisierungsgrad',
    templateUrl: './chart-digitalisierungsgrad.component.html',
    styleUrls: ['./chart-digitalisierungsgrad.component.scss']
})
export class ChartDigitalisierungsgradComponent implements OnInit
{
    constructor() { }

    @Input() public id: string;
    @Input() public titel: string;
    @Input() public betroffen: number;
    @Input() public gesamt: number;

    public prozent = '0 %';
    public chart: any = null;
    public chartId: any = null;
    public ngOnInit()
    {
        const calcProzent = (this.betroffen / this.gesamt) * 100.00;

        this.chartId = this.id + '-chart';
        this.prozent = calcProzent.toFixed(2) + '%';

        setTimeout(() => this.chart = new Chart(this.chartId, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [
                        this.betroffen,
                        this.gesamt - this.betroffen
                    ],
                    backgroundColor: [
                        '#39569b',
                        '#dddddd'
                    ]
                }],
                labels: [
                    'Digitalisiert',
                    'Nicht Digitalisiert'
                ]
            },
            options: {
                cutoutPercentage: 60,
                responsive: true,
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                animation: {
                    animateScale: false,
                    animateRotate: true
                }
            }
        }), 300);
    }
}
