import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { EintragDetailEditEintragComponent } from './eintrag/components/eintrag-detail-edit/eintrag-detail-edit-eintrag/eintrag-detail-edit-eintrag.component';
import { EintragDetailEditEreignisseComponent } from './eintrag/components/eintrag-detail-edit/eintrag-detail-edit-ereignisse/eintrag-detail-edit-ereignisse.component';
import { EintragDetailEditItemsComponent } from './eintrag/components/eintrag-detail-edit/eintrag-detail-edit-items/eintrag-detail-edit-items.component';
import { EintragDetailEditPoliciesComponent } from './eintrag/components/eintrag-detail-edit/eintrag-detail-edit-policies/eintrag-detail-edit-policies.component';
import { EintragDetailEditTagsPersonenComponent } from './eintrag/components/eintrag-detail-edit/eintrag-detail-edit-tags-personen/eintrag-detail-edit-tags-personen.component';
import { EintragDetailEditVerortungComponent } from './eintrag/components/eintrag-detail-edit/eintrag-detail-edit-verortung/eintrag-detail-edit-verortung.component';
import { EintragDetailEditWrapperComponent } from './eintrag/components/eintrag-detail-edit/eintrag-detail-edit-wrapper/eintrag-detail-edit-wrapper.component';
import { EintragDetailViewEintragComponent } from './eintrag/components/eintrag-detail-view/eintrag-detail-view-eintrag/eintrag-detail-view-eintrag.component';
import { EintragDetailViewEreignisseComponent } from './eintrag/components/eintrag-detail-view/eintrag-detail-view-ereignisse/eintrag-detail-view-ereignisse.component';
import { EintragDetailViewItemsComponent } from './eintrag/components/eintrag-detail-view/eintrag-detail-view-items/eintrag-detail-view-items.component';
import { EintragDetailViewPoliciesComponent } from './eintrag/components/eintrag-detail-view/eintrag-detail-view-policies/eintrag-detail-view-policies.component';
import { EintragDetailViewTagsPersonenComponent } from './eintrag/components/eintrag-detail-view/eintrag-detail-view-tags-personen/eintrag-detail-view-tags-personen.component';
import { EintragDetailViewVerortungComponent } from './eintrag/components/eintrag-detail-view/eintrag-detail-view-verortung/eintrag-detail-view-verortung.component';
import { EintragDetailViewWrapperComponent } from './eintrag/components/eintrag-detail-view/eintrag-detail-view-wrapper/eintrag-detail-view-wrapper.component';
import { EintragChoosePageComponent } from './eintrag/pages/eintrag-choose-page/eintrag-choose-page.component';
import { EintragDetailPageComponent } from './eintrag/pages/eintrag-detail-page/eintrag-detail-page.component';
import { SearchPageComponent } from './search/pages/search-page/search-page.component';
import { BehaeltnissePageComponent } from './settings/pages/behaeltnisse-page/behaeltnisse-page.component';
import { ExterneBesitzerPageComponent } from './settings/pages/externe-besitzer-page/externe-besitzer-page.component';
import { FaecherPageComponent } from './settings/pages/faecher-page/faecher-page.component';
import { KaestenPageComponent } from './settings/pages/kaesten-page/kaesten-page.component';
import { MedienPageComponent } from './settings/pages/medien-page/medien-page.component';
import { PersonenPageComponent } from './settings/pages/personen-page/personen-page.component';
import { SettingsPageComponent } from './settings/pages/settings-page/settings-page.component';
import { TagsPageComponent } from './settings/pages/tags-page/tags-page.component';
import { ThemenPageComponent } from './settings/pages/themen-page/themen-page.component';
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { LoginGuard } from './shared/guards/login/login.guard';
import { InterceptorService } from './shared/services/interceptor/interceptor.service';
import { AnwendungsinformationenPageComponent } from './user/pages/anwendungsinformationen-page/anwendungsinformationen-page.component';
import { LoginPageComponent } from './user/pages/login-page/login-page.component';
import { ProfilePageComponent } from './user/pages/profile-page/profile-page.component';
import { TaskLoggingPageComponent } from './user/pages/task-logging-page/task-logging-page.component';
import { UserPageComponent } from './user/pages/user-page/user-page.component';
import { BehaeltnisZuweisungPageComponent } from './verwaltung/pages/behaeltnis-zuweisung-page/behaeltnis-zuweisung-page.component';
import { EintraegePageComponent } from './verwaltung/pages/eintraege-page/eintraege-page.component';
import { EtikettenPageComponent } from './verwaltung/pages/etiketten-page/etiketten-page.component';
import { ExportsPageComponent } from './verwaltung/pages/exports-page/exports-page.component';
import { FastlanePageComponent } from './verwaltung/pages/fastlane-page/fastlane-page.component';
import { IndizesPageComponent } from './verwaltung/pages/indizes-page/indizes-page.component';
import { SchaufensterObjektePageComponent } from './verwaltung/pages/schaufenster-objekte-page/schaufenster-objekte-page.component';
import { StatistikenPageComponent } from './verwaltung/pages/statistiken-page/statistiken-page.component';
import { UploadsPageComponent } from './verwaltung/pages/uploads-page/uploads-page.component';
import { VerwaltungPageComponent } from './verwaltung/pages/verwaltung-page/verwaltung-page.component';
import { ZeittafelPageComponent } from './zeittafel/pages/zeittafel-page/zeittafel-page.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'search',
        component: SearchPageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'zeittafel',
        component: ZeittafelPageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'eintrag',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'choose',
                pathMatch: 'full'
            },
            {
                path: 'choose',
                component: EintragChoosePageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: ':bestandId/:eintragId',
                component: EintragDetailPageComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'edit',
                        component: EintragDetailEditWrapperComponent,
                        canActivate: [AuthGuard],
                        children: [
                            {
                                path: 'eintrag',
                                component: EintragDetailEditEintragComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: 'items',
                                component: EintragDetailEditItemsComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: 'tags-personen',
                                component: EintragDetailEditTagsPersonenComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: 'verortung',
                                component: EintragDetailEditVerortungComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: 'ereignisse-serien',
                                component: EintragDetailEditEreignisseComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: 'policies',
                                component: EintragDetailEditPoliciesComponent,
                                canActivate: [AuthGuard]
                            }
                        ]
                    },
                    {
                        path: 'view',
                        component: EintragDetailViewWrapperComponent,
                        canActivate: [AuthGuard],
                        children: [
                            {
                                path: 'eintrag',
                                component: EintragDetailViewEintragComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: 'items',
                                component: EintragDetailViewItemsComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: 'tags-personen',
                                component: EintragDetailViewTagsPersonenComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: 'verortung',
                                component: EintragDetailViewVerortungComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: 'ereignisse-serien',
                                component: EintragDetailViewEreignisseComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: 'policies',
                                component: EintragDetailViewPoliciesComponent,
                                canActivate: [AuthGuard]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: 'verwaltung',
        component: VerwaltungPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'schaufenster',
                component: SchaufensterObjektePageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'indizes',
                component: IndizesPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'fastlane',
                component: FastlanePageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'letzte-eintraege',
                component: EintraegePageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'uploads',
                component: UploadsPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'etiketten',
                component: EtikettenPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'behaeltnis-zuweisung',
                component: BehaeltnisZuweisungPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'exports',
                component: ExportsPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'statistiken',
                component: StatistikenPageComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: 'settings',
        component: SettingsPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'themen',
                component: ThemenPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'medien',
                component: MedienPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'personen',
                component: PersonenPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'tags',
                component: TagsPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'externe-besitzer',
                component: ExterneBesitzerPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'behaeltnisse',
                component: BehaeltnissePageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'faecher',
                component: FaecherPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'kaesten',
                component: KaestenPageComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: 'user',
        component: UserPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfilePageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'anwendungsinformationen',
                component: AnwendungsinformationenPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'task-logging',
                component: TaskLoggingPageComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: 'login',
        canActivate: [LoginGuard],
        component: LoginPageComponent
    }
];

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        }
    ]
})
export class AppRoutingModule { }
