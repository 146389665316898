<oag-content-wrapper>

    <h2>6. Schaufenster & Richtlinien</h2>
    <h3>6.1. Schaufenster Objekte</h3>

    <h3>
        <span>6.2. Richtlinien</span>
        <button class="button" (click)="addNewPolicy()">Richtlinie hinzufügen</button>
    </h3>
    <p>Dem Eintrag zugewiesene Richtlinien zur Verwaltung von Zugriffsrechten</p>

    <oag-eintrag-policy-edit *ngFor="let eintragPoliciy of eintragEdit.eintrag?.policies; let i = index" [item]="eintragPoliciy" [index]="i"></oag-eintrag-policy-edit>
    
</oag-content-wrapper>
