<oag-content-wrapper class="list">

    <header class="headline-button-container">
        <h1>Behältnisse</h1>
        <button class="button button--medium" (click)="edit()">Neues Behältnis</button>
    </header>
    <table summary="Behältnisse">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Bestand</th>
                <th scope="col">Name</th>
                <th scope="col">Fach</th>
                <th scope="col">Type</th>
                <th scope="col">Größe</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let behaeltnis of behaeltnisse">
                <td>{{ behaeltnis.idFormatted }}</td>
                <td>{{ behaeltnis.bestand.signatur }}</td>
                <td>{{ behaeltnis.name }}</td>
                <td>{{ behaeltnis.fach.name }} (Kasten {{ behaeltnis.fach.kasten.name }}, Spalte/Reihe: {{ behaeltnis.fach.spalte }} / {{ behaeltnis.fach.reihe }})</td>
                <td>{{ behaeltnis.type }}</td>
                <td>{{ behaeltnis.groesse }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="edit(behaeltnis)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>

</oag-content-wrapper>

<div class="edit" *ngIf="behaeltnisEdit">
    <header class="headline-button-container">
        <h3>{{ behaeltnisEdit.bestand.signatur }}/BEH{{ behaeltnisEdit.idFormatted }}</h3>
        <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
    </header>
    <oag-info-item-row *ngIf="!behaeltnisEdit.id">
        <oag-info-item-wrapper titel="Bestand" width="12">
            <select [(ngModel)]="behaeltnisEdit.bestand" [compareWith]="compareIds">
                <option [ngValue]="bestand" *ngFor="let bestand of bestaende">{{ bestand.signatur }}</option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Name" width="12">
            <input type="text" [(ngModel)]="behaeltnisEdit.name">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Name Print" width="12">
            <textarea [(ngModel)]="behaeltnisEdit.namePrint"></textarea>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Name Print Untertitel" width="12">
            <textarea [(ngModel)]="behaeltnisEdit.namePrintUntertitel"></textarea>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Inhalt" width="12">
            <textarea [(ngModel)]="behaeltnisEdit.inhalt"></textarea>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Fach" width="12">
            <select [(ngModel)]="behaeltnisEdit.fach" [compareWith]="compareIds">
                <option [ngValue]="fach" *ngFor="let fach of faecher">{{ fach.name }} - {{ fach.kasten.name }}: ({{ fach.spalte }} / {{ fach.reihe }})</option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Type" width="12">
            <input type="text" [(ngModel)]="behaeltnisEdit.type">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Größe" width="12">
            <input type="text" [(ngModel)]="behaeltnisEdit.groesse">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <div class="edit-button-container">
        <button class="button" *ngIf="behaeltnisEdit.id" (click)="update()">Speichern</button>
        <button class="button" *ngIf="!behaeltnisEdit.id" (click)="create()">Erstellen</button>
        <button class="button button--outline" *ngIf="behaeltnisEdit.id" (click)="delete()">Löschen</button>
    </div>
</div>
