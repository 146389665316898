<oag-content-wrapper>

    <h2>An Behältnis oder Fach zuweisen</h2>
    <oag-info-item-row>

        <oag-info-item-wrapper width="3" titel="Zuweisung zu">
            <select [(ngModel)]="zuweisungsTyp">
                <option value="behaeltnis">Behältnis</option>
                <option value="fach">Fach</option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper class="select" width="9" titel="Behältnis" *ngIf="zuweisungsTyp == 'behaeltnis'">
            <select [(ngModel)]="selectedBehaeltnis" (change)="focusInput()">
                <option [ngValue]="behaeltnis" *ngFor="let behaeltnis of selects?.behaeltnisse">
                    {{ behaeltnis.bestand.signatur }}/BEH{{ behaeltnis.idFormatted }} ({{ behaeltnis.name }})
                </option>
            </select>
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="9" titel="Fach" *ngIf="zuweisungsTyp == 'fach'">
            <select [(ngModel)]="selectedFach" (change)="focusInput()">
                <option [ngValue]="fach" *ngFor="let fach of selects?.faecher">
                    {{ fach.kasten.name }} - {{ fach.name }} <span *ngIf="fach.spalte && fach.reihe">({{ fach.spalte }}/{{ fach.reihe }})</span>
                </option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>

    <oag-info-item-row>
        <oag-info-item-wrapper width="9" titel="Eintrag oder Item Intern">
            <input #input type="text" placeholder="ID mit Prefix" [(ngModel)]="eintragAndOrItemId" (keyup.enter)="zuweisen()">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper width="3" titel="Zuweisen">
            <button class="button" (click)="zuweisen()">Zuweisen</button>
        </oag-info-item-wrapper>

    </oag-info-item-row>

    <h2>Log</h2>
    <p>Noch nicht fertig implementiert</p>

</oag-content-wrapper>
