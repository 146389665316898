import { Component, OnInit } from '@angular/core';
import { LetzteEintraegeModel } from '@ortsarchiv-gemeinlebarn/data';
import { EintragApiService } from 'src/app/shared/services/api-endpoints/eintrag-api/eintrag-api.service';

@Component({
    selector: 'oag-eintraege-page',
    templateUrl: './eintraege-page.component.html',
    styleUrls: ['./eintraege-page.component.scss']
})
export class EintraegePageComponent implements OnInit
{
    public letzteEintraege: Array<LetzteEintraegeModel>;

    constructor(private eintragAPI: EintragApiService)
    {
        this.eintragAPI.readLetzteEintraege(1000).subscribe((letzteEintraege) => this.letzteEintraege = letzteEintraege);
    }
    ngOnInit(): void { }

    public toggleEintraege(item): void
    {
        item.hidden = !item.hidden;
    }
}
