import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
    selector: 'oag-user-page',
    templateUrl: './user-page.component.html',
    styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent implements OnInit
{
    constructor(public auth: AuthService) { }
    public ngOnInit() { } // NOSONAR
}
