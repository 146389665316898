import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-ereignis',
    templateUrl: './ereignis.component.html',
    styleUrls: ['./ereignis.component.scss']
})
export class EreignisComponent implements OnInit
{
    @Input() public ereignis = null;

    public showEintraege = false;
    public showPersonen = false;
    public showQuellen = false;

    constructor() { }
    public ngOnInit() { }

    public toggleEintraege()
    {
        this.showEintraege = !this.showEintraege;

        this.showPersonen = false;
        this.showQuellen = false;
    }

    public togglePersonen()
    {
        this.showPersonen = !this.showPersonen;

        this.showEintraege = false;
        this.showQuellen = false;
    }

    public toggleQuellen()
    {
        this.showQuellen = !this.showQuellen;

        this.showEintraege = false;
        this.showPersonen = false;
    }
}
