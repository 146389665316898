<oag-content-wrapper class="list">

    <header class="headline-button-container">
        <h1>Externe Besitzer</h1>
        <button class="button button--medium" (click)="edit()">Neuer Externer Besitzer</button>
    </header>
    <table summary="Externe Besitzer">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Type</th>
                <th scope="col">Name (Vorname / Nachname)</th>
                <th scope="col">Adresse</th>
                <th scope="col">E-Mail</th>
                <th scope="col">Telefon</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let externerBesitzer of externeBesitzer">
                <td>{{ externerBesitzer.id }}</td>
                <td>{{ externerBesitzer.type }}</td>
                <td>
                    <span *ngIf="externerBesitzer.name">{{ externerBesitzer.name }}<br></span>
                    {{ externerBesitzer.vorname }} {{ externerBesitzer.nachname }}
                </td>
                <td>
                    {{ externerBesitzer.adresse }}<br>
                    {{ externerBesitzer.plz }} {{ externerBesitzer.ort }}<br>
                    {{ externerBesitzer.land }}<br>
                </td>
                <td>{{ externerBesitzer.email }}</td>
                <td>{{ externerBesitzer.telefon }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="edit(externerBesitzer)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>

</oag-content-wrapper>

<div class="edit" *ngIf="externerBesitzerEdit">
    <header class="headline-button-container">
        <h3>{{ externerBesitzerEdit.name }}<span *ngIf="externerBesitzerEdit.name && externerBesitzerEdit.vorname"> / </span>{{ externerBesitzerEdit.vorname }} {{ externerBesitzerEdit.nachname }}</h3>
        <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
    </header>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Type" width="12">
            <select [(ngModel)]="externerBesitzerEdit.type">
                <option value="Institution">Institution</option>
                <option value="Privat">Privat</option>
            </select>
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Name" width="12">
            <input type="text" [(ngModel)]="externerBesitzerEdit.name">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Vorname" width="6">
            <input type="text" [(ngModel)]="externerBesitzerEdit.vorname">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Nachname" width="6">
            <input type="text" [(ngModel)]="externerBesitzerEdit.nachname">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Adresse" width="12">
            <input type="text" [(ngModel)]="externerBesitzerEdit.adresse">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="PLZ" width="3">
            <input type="text" [(ngModel)]="externerBesitzerEdit.plz">
        </oag-info-item-wrapper>
        <oag-info-item-wrapper titel="Ort" width="9">
            <input type="text" [(ngModel)]="externerBesitzerEdit.ort">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Land" width="12">
            <input type="text" [(ngModel)]="externerBesitzerEdit.land">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="E-Mail" width="12">
            <input type="text" [(ngModel)]="externerBesitzerEdit.email">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Telefon" width="12">
            <input type="text" [(ngModel)]="externerBesitzerEdit.telefon">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <div class="edit-button-container">
        <button class="button" *ngIf="externerBesitzerEdit.id" (click)="update()">Speichern</button>
        <button class="button" *ngIf="!externerBesitzerEdit.id" (click)="create()">Erstellen</button>
        <button class="button button--outline" *ngIf="externerBesitzerEdit.id" (click)="delete()">Löschen</button>
    </div>
</div>
