import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagModel } from '@ortsarchiv-gemeinlebarn/data';
import { TagApiService } from '../../services/api-endpoints/tag-api/tag-api.service';

@Component({
    selector: 'oag-multiple-select-tag',
    templateUrl: './multiple-select-tag.component.html',
    styleUrls: ['./multiple-select-tag.component.scss']
})
export class MultipleSelectTagComponent implements OnInit
{
    public opend = false;
    public searchTerm = '';
    public pool: Array<TagModel> = [];

    @Input() public choosen: Array<TagModel> = [];
    @Output() public choosenChange: EventEmitter<TagModel[]> = new EventEmitter<TagModel[]>();

    constructor(public tagAPI: TagApiService)
    {
        this.tagAPI.readAll().subscribe((tags) => this.pool = tags);
    }
    public ngOnInit() { }

    public getFiltredPool()
    {
        return this.pool.filter((item) => item.label.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1 || this.searchTerm === '') || [];
    }

    public isInChoosen(item)
    {
        return this.choosen.findIndex((obj) => obj.id === item.id) !== -1;
    }

    /* Open & Close */

    public openSelection()
    {
        this.opend = true;
    }

    public closeSelection()
    {
        setTimeout(() => this.opend = false, 200);
    }

    /* Add & Remove */

    public add(item)
    {
        if (!this.isInChoosen(item))
        {
            this.choosen.push(item);
        }
        this.searchTerm = '';
        this.choosenChange.emit(this.choosen);
    }

    public remove(item)
    {
        this.choosen = this.choosen.filter((obj) => obj.id !== item.id);
        this.choosenChange.emit(this.choosen);
    }

    /* Create new Tag */

    public create()
    {
        const tag = new TagModel();
        tag.label = this.searchTerm;

        this.tagAPI.create(tag).subscribe((createdTag) =>
        {
            this.pool.push(createdTag);
            this.choosen.push(createdTag);
            this.searchTerm = '';
        });
    }
}
