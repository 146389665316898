import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ChartDigitalisierungsgradComponent } from './components/chart-digitalisierungsgrad/chart-digitalisierungsgrad.component';
import { FastlaneEintragBeschlagwortungComponent } from './components/fastlane-eintrag-beschlagwortung/fastlane-eintrag-beschlagwortung.component';
import { UploadItemStepDividerComponent } from './components/upload-item-step-divider/upload-item-step-divider.component';
import { UploadItemStepComponent } from './components/upload-item-step/upload-item-step.component';
import { UploadItemComponent } from './components/upload-item/upload-item.component';
import { BehaeltnisZuweisungPageComponent } from './pages/behaeltnis-zuweisung-page/behaeltnis-zuweisung-page.component';
import { EtikettenPageComponent } from './pages/etiketten-page/etiketten-page.component';
import { ExportsPageComponent } from './pages/exports-page/exports-page.component';
import { FastlanePageComponent } from './pages/fastlane-page/fastlane-page.component';
import { StatistikenPageComponent } from './pages/statistiken-page/statistiken-page.component';
import { UploadsPageComponent } from './pages/uploads-page/uploads-page.component';
import { VerwaltungPageComponent } from './pages/verwaltung-page/verwaltung-page.component';
import { EintraegePageComponent } from './pages/eintraege-page/eintraege-page.component';
import { SchaufensterObjektePageComponent } from './pages/schaufenster-objekte-page/schaufenster-objekte-page.component';
import { SchaufensterObjektComponent } from './components/schaufenster-objekt/schaufenster-objekt.component';
import { IndizesPageComponent } from './pages/indizes-page/indizes-page.component';

@NgModule({
    declarations: [
        BehaeltnisZuweisungPageComponent,
        EtikettenPageComponent,
        StatistikenPageComponent,
        ExportsPageComponent,
        ChartDigitalisierungsgradComponent,
        VerwaltungPageComponent,
        UploadsPageComponent,
        UploadItemComponent,
        UploadItemStepComponent,
        UploadItemStepDividerComponent,
        FastlanePageComponent,
        FastlaneEintragBeschlagwortungComponent,
        EintraegePageComponent,
        SchaufensterObjektePageComponent,
        SchaufensterObjektComponent,
        IndizesPageComponent
    ],
    exports: [
        BehaeltnisZuweisungPageComponent,
        EtikettenPageComponent,
        StatistikenPageComponent,
        ExportsPageComponent,
        ChartDigitalisierungsgradComponent,
        VerwaltungPageComponent,
        UploadsPageComponent,
        UploadItemComponent,
        UploadItemStepComponent,
        UploadItemStepDividerComponent,
        FastlaneEintragBeschlagwortungComponent,
        EintraegePageComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        SharedModule,
        FormsModule,
        SharedModule
    ]
})
export class VerwaltungModule { }
