<div class="selection">
    <input class="selection__input" type="text" placeholder="Suchen" [(ngModel)]="searchTerm" (focus)="openSelection()" (blur)="closeSelection()">
    <div class="selection__pool" [ngClass]="{'selection__pool--opend': opend}">
        <div class="pool-item" *ngFor="let eintrag of getFiltredPool()" (click)="add(eintrag)">
            {{ eintrag.idFormattedPrefix }}
        </div>
        <div class="pool-item" *ngIf="getFiltredPool().length == 0">
            nichts gefunden
        </div>
    </div>
</div>
<div class="choosen">
    <oag-label-wrapper class="item" *ngFor="let eintrag of choosen" [titel]="eintrag.idFormattedPrefix" (remove)="remove(eintrag)"></oag-label-wrapper>
</div>
