<oag-content-wrapper class="list">

    <header class="headline-button-container">
        <h1>Tags</h1>
        <button class="button button--medium" (click)="edit()">Neuer Tag</button>
    </header>
    <table summary="Tags">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Label</th>
                <th scope="col" class="edit-button-col">Bearbeiten</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let tag of tags">
                <td>{{ tag.id }}</td>
                <td>{{ tag.label }}</td>
                <td>
                    <button class="button button--medium button--light" (click)="edit(tag)">bearbeiten</button>
                </td>
            </tr>
        </tbody>
    </table>

</oag-content-wrapper>

<div class="edit" *ngIf="tagEdit">
    <header class="headline-button-container">
        <h3>{{ tagEdit.label }}</h3>
        <button class="button button--small button--light" (click)="resetEdit()">Schließen</button>
    </header>
    <oag-info-item-row>
        <oag-info-item-wrapper titel="Name" width="12">
            <input type="text" [(ngModel)]="tagEdit.label">
        </oag-info-item-wrapper>
    </oag-info-item-row>
    <div class="edit-button-container">
        <button class="button" *ngIf="tagEdit.id" (click)="update()">Speichern</button>
        <button class="button" *ngIf="!tagEdit.id" (click)="create()">Erstellen</button>
        <button class="button button--outline" *ngIf="tagEdit.id" (click)="delete()">Löschen</button>
    </div>
</div>
