import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MediaListService } from 'src/app/shared/services/media-list/media-list.service';
import { SearchService } from '../../services/search/search.service';

@Component({
    selector: 'oag-search-result-item-eintrag',
    templateUrl: './search-result-item-eintrag.component.html',
    styleUrls: ['./search-result-item-eintrag.component.scss']
})
export class SearchResultItemEintragComponent implements OnInit
{
    @Input() public eintrag = null;
    public inMediaList = false;

    constructor(public mediaList: MediaListService, private search: SearchService, private router: Router) { }
    public ngOnInit() { } // NOSONAR

    public async addEintragItemsDigitalToMediaList(eintrag, $event)
    {
        $event.stopPropagation();

        if (eintrag.items.digital.length)
        {
            let digitalisate = [].concat.apply([], this.search.resultsEintraege?.hits.hits.map(eintrag => eintrag._source.items.digital));
            this.mediaList.openItemDigitalById(eintrag.items.digital[0].id, digitalisate);
        }
    }

    public openEintrag(eintrag)
    {
        this.router.navigate([`/eintrag/${eintrag.bestand.id}/${eintrag.id}/view/eintrag`]);
    }
}
