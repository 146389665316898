import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-expendable-information',
    templateUrl: './expendable-information.component.html',
    styleUrls: ['./expendable-information.component.scss']
})
export class ExpendableInformationComponent implements OnInit
{
    @Input() public titel = null;
    @Input() public type = null;
    @Input() public expended = false;

    constructor() { } // NOSONAR
    public ngOnInit() { } // NOSONAR

    public toggleExpend()
    {
        this.expended = !this.expended;
    }
}
