import { Component, Input, OnInit } from '@angular/core';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';
import { PersonModel } from '@ortsarchiv-gemeinlebarn/data';
import { TagModel } from '@ortsarchiv-gemeinlebarn/data';
import { PersonApiService } from 'src/app/shared/services/api-endpoints/person-api/person-api.service';
import { TagApiService } from 'src/app/shared/services/api-endpoints/tag-api/tag-api.service';

@Component({
    selector: 'oag-fastlane-eintrag-beschlagwortung',
    templateUrl: './fastlane-eintrag-beschlagwortung.component.html',
    styleUrls: ['./fastlane-eintrag-beschlagwortung.component.scss']
})
export class FastlaneEintragBeschlagwortungComponent implements OnInit
{
    @Input() public image: string;
    public tags: Array<TagModel> = [];
    public personen: Array<PersonModel> = [];

    constructor(public eintragEdit: EintragEditService, public tagAPI: TagApiService, public personAPI: PersonApiService)
    {
        this.tagAPI.readAll().subscribe((tags) => this.tags = tags);
        this.personAPI.readAll().subscribe((personen) => this.personen = personen);
    }

    public ngOnInit() { }
}
