import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService
{
    public auth0Client$ = (from(
        createAuth0Client({
            domain: environment.auth.domain,
            client_id: environment.auth.clientId,
            redirect_uri: `${window.location.origin}`,
            audience: environment.auth.audience
        })
    ) as Observable<Auth0Client>).pipe(
        shareReplay(1),
        catchError((err) => throwError(err))
    );

    public isAuthenticated$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.isAuthenticated())),
        tap((res) => this.loggedIn = res)
    );
    public handleRedirectCallback$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    );

    private userProfileSubject$ = new BehaviorSubject<any>(null);
    public userProfile$ = this.userProfileSubject$.asObservable();
    public loggedIn: boolean = null;

    constructor(private router: Router)
    {
        this.localAuthSetup();
        this.handleAuthCallback();
    }

    public getUser$(options?): Observable<any>
    {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getUser(options))),
            tap((user) => this.userProfileSubject$.next(user))
        );
    }

    public getTokenSilently$(options?): Observable<string>
    {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
        );
    }

    private localAuthSetup()
    {
        const checkAuth$ = this.isAuthenticated$.pipe(
            concatMap((loggedIn: boolean) =>
            {
                if (loggedIn)
                {
                    return this.getUser$();
                }
                return of(loggedIn);
            })
        );
        checkAuth$.subscribe();
    }

    public login(redirectPath: string = '/')
    {
        this.auth0Client$.subscribe((client: Auth0Client) =>
        {
            client.loginWithRedirect({
                redirect_uri: `${window.location.origin}`,
                appState: { target: redirectPath }
            });
        });
    }

    private handleAuthCallback()
    {
        const params = window.location.search;
        if (params.includes('code=') && params.includes('state='))
        {
            let targetRoute: string;
            const authComplete$ = this.handleRedirectCallback$.pipe(
                tap((cbRes) =>
                {
                    targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
                }),
                concatMap(() =>
                {
                    return combineLatest([
                        this.getUser$(),
                        this.isAuthenticated$
                    ]);
                })
            );
            authComplete$.subscribe(([user, loggedIn]) =>
            {
                this.router.navigate([targetRoute]);
            });
        }
    }

    public logout()
    {
        this.auth0Client$.subscribe((client: Auth0Client) =>
        {
            client.logout({
                client_id: environment.auth.clientId,
                returnTo: `${window.location.origin}`
            });
        });
    }
}
