<div class="datierung">
    <span *ngIf="datierung">{{ datierung }}</span>
</div>

<div class="line">
    <div class="line__mark--left"></div>
    <div class="line__line"></div>
    <div class="line__mark--right"></div>
</div>

<div class="wrapper">
    <ng-content></ng-content>
</div>
