<oag-content-wrapper *ngIf="eintragEdit.eintrag">

    <h2>3. Tags & Personen</h2>
    <p>Verknüpfte Schlagworte und Personen</p>

    <h3>3.1. Hauptobjekte</h3>
    <oag-multiple-select-tag [(choosen)]="eintragEdit.eintrag.tags.hauptobjekte"></oag-multiple-select-tag>

    <h3>3.2. Nebenobjekte</h3>
    <oag-multiple-select-tag [(choosen)]="eintragEdit.eintrag.tags.nebenobjekte"></oag-multiple-select-tag>

    <h3>3.3. Personen</h3>
    <oag-multiple-select-person [(choosen)]="eintragEdit.eintrag.personen"></oag-multiple-select-person>

</oag-content-wrapper>
