import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FrontendService
{
    constructor() { }

    public loadingIndicatorUsings = 0;
    public loadingIndicatorBackdropUsings = 0;

    public modal = {
        show: false,
        type: 'text',
        headline: '',
        content: null,
        hideButton: false
    };

    public isLoadingIndicatorShown = () => this.loadingIndicatorUsings > 0;
    public isLoadingIndicatorBackdropShown = () => this.loadingIndicatorBackdropUsings > 0;

    public startLoadingIndicator(backdrop: boolean = true)
    {
        this.loadingIndicatorUsings++;
        if (backdrop) this.loadingIndicatorBackdropUsings++;
    }

    public stopLoadingIndicator(backdrop: boolean = true)
    {
        this.loadingIndicatorUsings--;
        if (backdrop) this.loadingIndicatorBackdropUsings--;
    }

    public showModal(headline: string, content: any, autoClose?, type?: string)
    {
        this.modal.type = type ? type : 'text';
        this.modal.headline = headline;
        this.modal.content = content;
        this.modal.show = true;
        this.modal.hideButton = autoClose ? true : false;

        if (autoClose)
        {
            const time = isNaN(autoClose) ? autoClose : 1000;
            setTimeout((_) => this.modal.show = false, time);
        }
    }

    public closeModal()
    {
        this.modal.show = false;
    }

    public scrollToElement($element): void
    {
        $element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}
