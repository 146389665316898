import { Component, Input, OnInit } from '@angular/core';
import { PersonModel } from '@ortsarchiv-gemeinlebarn/data';
import { PersonApiService } from '../../services/api-endpoints/person-api/person-api.service';
import { QuickViewService } from '../../services/quick-view/quick-view.service';

@Component({
    selector: 'oag-quick-view-person-edit',
    templateUrl: './quick-view-person-edit.component.html',
    styleUrls: ['./quick-view-person-edit.component.scss']
})
export class QuickViewPersonEditComponent implements OnInit
{
    @Input() public person: PersonModel = new PersonModel();

    constructor(public quickView: QuickViewService, public personAPI: PersonApiService) { }
    public ngOnInit() { } // NOSONAR

    public save()
    {
        this.personAPI.update(this.person).subscribe((person) => this.person = person);
    }
}
