import { Component, OnInit } from '@angular/core';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';
import { ArbeitslisteItemEintragModel } from '@ortsarchiv-gemeinlebarn/data';
import { ArbeitslisteModel } from '@ortsarchiv-gemeinlebarn/data';
import { EintragModel } from '@ortsarchiv-gemeinlebarn/data';
import { ArbeitslisteApiService } from 'src/app/shared/services/api-endpoints/arbeitsliste-api/arbeitsliste-api.service';
import { EintragApiService } from 'src/app/shared/services/api-endpoints/eintrag-api/eintrag-api.service';
import { ItemDigitalApiService } from 'src/app/shared/services/api-endpoints/item-digital-api/item-digital-api.service';

@Component({
    selector: 'oag-fastlane-page',
    templateUrl: './fastlane-page.component.html',
    styleUrls: ['./fastlane-page.component.scss']
})
export class FastlanePageComponent implements OnInit
{
    public manuelleArbeitslisten: Array<ArbeitslisteModel> = [];

    public arbeitsliste: ArbeitslisteModel = null;
    public arbeitslisteItems: Array<ArbeitslisteItemEintragModel> = [];
    public arbeitslisteItem: ArbeitslisteItemEintragModel = null;
    public eintrag: EintragModel = null;
    public image: string = null;

    constructor(public arbeitslisteAPI: ArbeitslisteApiService, public eintragAPI: EintragApiService, public eintragEdit: EintragEditService, public itemDigitalAPI: ItemDigitalApiService)
    {
        this.arbeitslisteAPI.readAll().subscribe((arbeitslisten) =>
        {
            this.manuelleArbeitslisten = arbeitslisten;
            this.arbeitsliste = this.manuelleArbeitslisten[0];
            this.chooseArbeitsliste(this.manuelleArbeitslisten[0]);
        });
    }
    public ngOnInit() { }

    public chooseArbeitsliste(arbeitsliste)
    {
        this.arbeitsliste = arbeitsliste;
        this.arbeitslisteAPI.readAllItemsOfArbeitsliste(this.arbeitsliste.id).subscribe((items) =>
        {
            this.arbeitslisteItems = items;
            const firstUnfinishedItem = this.getItemsUnfinished()[0];
            if (firstUnfinishedItem)
            {
                this.chooseItem(firstUnfinishedItem);
            }
            else
            {
                this.arbeitslisteItem = null;
            }
        });
    }

    public chooseItem(arbeitslisteItem)
    {
        this.arbeitslisteItem = arbeitslisteItem;
        this.eintragAPI.getByBestandIdEintragId(this.arbeitslisteItem.eintrag.bestand.id, this.arbeitslisteItem.eintrag.id).subscribe((eintrag) =>
        {
            this.eintragEdit.eintrag = eintrag;

            if (this.eintragEdit.eintrag.items.digital.length)
            {
                // TODO: Deprecated
                // this.itemDigitalAPI.getFileSinglePage(this.eintragEdit.eintrag.items.digital[0].id, 'big', 1).subscribe((image) => this.image = image);
            }
        });
    }

    public getItemsFinished = () => this.arbeitslisteItems.filter((item) => item.finished === true);
    public getItemsUnfinished = () => this.arbeitslisteItems.filter((item) => item.finished === false);

    public erledigtUndNaechstesItem()
    {
        const index = this.arbeitslisteItems.indexOf(this.arbeitslisteItem);

        this.arbeitslisteAPI.finishItemOfArbeitsliste(this.arbeitsliste.id, this.arbeitslisteItem.id).subscribe((items) =>
        {
            this.arbeitslisteItems = items;
            const nextItem = this.arbeitslisteItems.find((itemOfList, i) => itemOfList.finished === false && i > index);

            if (nextItem)
            {
                this.chooseItem(nextItem);
            }
            else if (this.getItemsUnfinished().length)
            {
                this.chooseItem(this.getItemsUnfinished()[0]);
            }
        });
    }
}
