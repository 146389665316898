import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oag-timeline-century',
    templateUrl: './timeline-century.component.html',
    styleUrls: ['./timeline-century.component.scss']
})
export class TimelineCenturyComponent implements OnInit
{
    @Input() public century = null;

    constructor() { }
    public ngOnInit() { }
}
