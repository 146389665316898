import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { catchError, retry, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { FrontendService } from 'src/app/shared/services/frontend/frontend.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'oag-profile-page',
    templateUrl: './profile-page.component.html',
    styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit
{
    constructor(public auth: AuthService, private http: HttpClient, public frontend: FrontendService, private api: ApiService) { }
    public ngOnInit() { } // NOSONAR

    public passwordResetLink(email)
    {
        const body = { client_id: environment.auth.clientId, email, connection: 'Username-Password-Authentication' };

        this.frontend.startLoadingIndicator();
        this.http
            .post(`https://${environment.auth.domain}/dbconnections/change_password`, body, { headers: this.api.headers, responseType: 'text' })
            .pipe(
                retry(1),
                tap(() => this.frontend.stopLoadingIndicator()),
                catchError((error) => this.api.errorHandler(error))
            )
            .subscribe((message) => this.frontend.showModal('Passwort ändern', message));
    }
}
