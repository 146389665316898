import { Component, Input, OnInit } from '@angular/core';
import { EintragEditService } from 'src/app/eintrag/services/eintrag-edit/eintrag-edit.service';
import { ItemInternModel } from '@ortsarchiv-gemeinlebarn/data';

@Component({
    selector: 'oag-item-intern-edit',
    templateUrl: './item-intern-edit.component.html',
    styleUrls: ['./item-intern-edit.component.scss']
})
export class ItemInternEditComponent implements OnInit
{
    @Input() public item: ItemInternModel = new ItemInternModel();
    public behaeltnisOrFach: 'behaeltnis' | 'fach' = 'behaeltnis';

    constructor(public eintragEdit: EintragEditService) { }
    public ngOnInit() { } // NOSONAR

    public behaeltnisOrFachChanged()
    {
        if (this.behaeltnisOrFach === 'behaeltnis')
        {
            this.item.behaeltnis = this.eintragEdit.selects.behaeltnisse[0];
            this.item.aufstellungsFach = null;
        }
        else
        {
            this.item.behaeltnis = null;
            this.item.aufstellungsFach = this.eintragEdit.selects.faecher[0];
        }
    }
}
